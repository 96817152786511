export const environment = {
  production: true,

  // icons
  ic_email: "/assets/icons/ic_email.svg",
  ic_phone: "/assets/icons/ic_phone.svg",
  ic_password: "/assets/icons/ic_password.svg",
  ic_dark_email: "/assets/icons/ic_dark_email.svg",
  ic_dark_phone: "/assets/icons/ic_dark_phone.svg",
  ic_dark_password: "/assets/icons/ic_dark_password.svg",
  ic_add_accountant: "/assets/icons/ic_add_accountant.svg",
  il_accountants: "/assets/illustrations/il_accountants.svg",
  il_incoming_invitations: "/assets/illustrations/il_incoming_invitations.svg",

  quicko_logo: 'https://cdn.quicko.com/assets/images/logo.svg',
  quicko_logo_dark: '/assets/icons/dark_logo.svg',
  ic_gst_logo: '/assets/icons/ic_gst_logo.svg',
  ic_tds_logo: '/assets/icons/ic_tds_logo.svg',
  ic_meet_logo: '/assets/icons/ic_meet_logo.svg',
  ic_glyde_logo: '/assets/icons/ic_glyde_logo.svg',
  ic_quicko_logo_circle: '/assets/icons/ic_quicko_logo.svg',
  ic_quicko_word_logo: '/assets/icons/ic_quicko_word_logo.svg',
  ic_dark_quicko_logo_circle: '/assets/icons/ic_dark_quicko_logo_circle.svg',

  // images
  dr_glyde_bg: '/assets/images/dr_glyde_bg.svg',

  // endpoints
  auth_services_endpoint: 'https://6ajirmb8y9.execute-api.ap-south-1.amazonaws.com/dev',
  myaccount_api_endpoint: 'https://7mprj30160.execute-api.ap-south-1.amazonaws.com/dev',
  authentication_host: 'https://dev-accounts.quicko.com/',
  cms_org_quicko_endpoint: "https://d2l1ae64bvxp7e.cloudfront.net",
  cms_it_endpoint: "https://dohnb1uxtyq5y.cloudfront.net",
  connect_endpoint: 'https://28yq9t6oe2.execute-api.ap-south-1.amazonaws.com/dev',

  // urls
  accounts_app_host: 'https://dev-accounts.quicko.com/',
  myaccount_app_host: 'https://dev-myaccount.quicko.com/',
  income_tax_url: "https://dev-it.quicko.com",
  tools_url: "https://dev-tools.quicko.com",
  meet_url: "https://dev-meet.quicko.com/",
  tds_url: "https://dev-app.quicko.com/tds",
  gst_url: "https://dev-app.quicko.com/gst",
  help_url: "https://help.quicko.com/support/home",
  glyde_url: "https://go.joinglyde.app/com-quicko-apps-drawer"

  // common icons

  // common images

};
