<section id="verify-details" class="p-6">
  <div class="flex flex-row justify-between">
    <div class="flex flex-col">
      <div *ngIf="data.email; else mobile">
        <h2 class="text-xl font-semibold">Verify New Email Address</h2>
        <p class="text-base font-medium on-surface-variant mt-1">Please verify your new email by entering OTP sent
          to
        </p>
        <p class="text-base font-semibold primary mb-6">{{data.email}}</p>
      </div>
      <ng-template #mobile>
        <h2 class="text-xl font-semibold">Verify New Phone Number</h2>
        <p class="text-base font-medium on-surface-variant mt-1">Please verify your new phone number by entering
          OTP
          sent to
        </p>
        <p class="text-base font-semibold primary mb-6">+{{data.countryCode}} {{data.mobile}}</p>
      </ng-template>
      <ng-otp-input (onInputChange)="onOtpChange($event)" (keydown.enter)="verify()" [config]="{length:6, allowNumbersOnly: true, inputStyles: {
      'font-size': '20px',
      'font-weight': '500',
      'width': '46px',
      'height': '56px',
      'background-color': 'var(--sys-background)'
      }}">
      </ng-otp-input>
      <div>
        <button (click)="resendOTP()" [disabled]="timeLeft != 30" [ngClass]="timeLeft == 30 ? 'primary' : 'on-surface'"
          class="font-medium text-base mt-4 grow-0">Resend
          OTP<span *ngIf="timeLeft != 30"> (Available in {{timeLeft}})</span></button>
      </div>
      <div class="flex flex-row mt-16">
        <ng-container *ngIf="!saveFormLoading; else loading">
          <button mat-flat-button class="text-base font-medium rounded w-36 h-12" (click)="verify()">Verify</button>
        </ng-container>
        <button mat-stroked-button class="text-base font-medium rounded w-36 h-12 ml-4"
          [mat-dialog-close]>Cancel</button>
      </div>

    </div>
    <mat-icon class="h-[28px] w-[28px] material-symbols-outlined cursor-pointer" [mat-dialog-close]>
      cancel
    </mat-icon>
  </div>
  <ng-template #loading>
    <button-loader [width]="'144'"></button-loader>
    <!-- <button class="text-base font-medium text-white rounded bg-blue-900 w-36 h-12 flex justify-center">
      <canvas riv="loading_bars_white" width="90" height="45">
        <riv-animation name="shrink" play></riv-animation>
      </canvas>
    </button> -->
  </ng-template>
</section>