import { createSelector } from '@ngrx/store';
import { ReferenceDataStore } from './reference-data-store.model';
import { RootSelectors } from '../app.selectors';

export const ReferenceDataSelector = createSelector(
    RootSelectors.REFERENCE_DATA_STORE,
    (referenceData: ReferenceDataStore) => referenceData
);


export const getCountries = () =>
    createSelector(
        ReferenceDataSelector,
        (referenceData: ReferenceDataStore) => referenceData.countries
    );

export const getStateDetails = () =>
    createSelector(
        ReferenceDataSelector,
        (referenceData: ReferenceDataStore) => referenceData.states
    );
