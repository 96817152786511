import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { User } from '../beans';
import { UserActions, UserSelectors } from '../store';

export const userResolver: ResolveFn<User> = (route, state) => {

    const store = inject(Store);
    const actions$ = inject(Actions)

    store.dispatch(UserActions.GET_USER());
    return actions$.pipe(
        ofType(UserActions.GET_USER_SUCCESS),
        switchMap(() => store.select(UserSelectors.USER))
    )
}