import { Component, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { InvitationRow, IncomingInvitationsActions, getIncomingInvitations, SnackbarService } from 'ngx-com-quicko-myaccount';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { InvitationRowComponent } from './invitation-row/invitation-row.component';
import { MatButtonModule } from '@angular/material/button';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-incoming-invitations',
  standalone: true,
  imports: [MatIconModule, NgxSkeletonLoaderModule, MatProgressSpinnerModule, CommonModule, InvitationRowComponent, MatButtonModule],
  templateUrl: './incoming-invitations.component.html',
  styleUrls: ['./incoming-invitations.component.css']
})
export class IncomingInvitationsComponent implements OnInit {

  incomingInvitationRows$: Observable<Array<InvitationRow>> = this.store.select(getIncomingInvitations());
  incomingInvitationRows: Array<InvitationRow> | null = null;
  destroy$: Subject<boolean> = new Subject<boolean>()
  loadingIncomingInvitations = false;

  constructor(
    private actions$: Actions,
    private store: Store,
    public snackbarService: SnackbarService) {
    this.incomingInvitationRows$.subscribe((incomingInvitationRows) => {
      if (incomingInvitationRows != undefined && incomingInvitationRows.length > 0) {
        this.incomingInvitationRows = [];
        this.incomingInvitationRows = incomingInvitationRows;
      }
    })
  }

  ngOnInit() {
    if (this.incomingInvitationRows != null) {
      this.getIncomingInvitationsFromStore();
    }
    else {
      this.loadingIncomingInvitations = true
    }
    this.getIncomingInvitations();


    this.actions$.pipe(
      ofType(IncomingInvitationsActions.GET_INVITATIONS_ERROR),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.loadingIncomingInvitations = false;
    });
  }


  getIncomingInvitations() {
    this.store.dispatch(IncomingInvitationsActions.GET_INVITATIONS({}));
    this.actions$.pipe(
      ofType(IncomingInvitationsActions.GET_INVITATIONS_SUCCESS),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.incomingInvitationRows$.pipe(takeUntil(this.destroy$)).subscribe((incomingInvitationRows) => {
        this.loadingIncomingInvitations = false;
        this.incomingInvitationRows = incomingInvitationRows;
      })
    })
  }

  getIncomingInvitationsFromStore() {
    this.incomingInvitationRows$.pipe(take(1)).subscribe((incomingInvitationRows) => {
      this.incomingInvitationRows = incomingInvitationRows;
    })
    this.loadingIncomingInvitations = false;
  }

  acceptInvitation($event: InvitationRow) {
    const invitationRow: InvitationRow = $event;
    console.log("accept")
    console.log(invitationRow)
    this.store.dispatch(IncomingInvitationsActions.ACCEPT_INVITATION({ accountantId: invitationRow.getId() }));
    this.actions$.pipe(
      ofType(IncomingInvitationsActions.ACCEPT_INVITATION_SUCCESS),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.snackbarService.openSnackBar('Invitation accepted successfully', undefined)
      this.getIncomingInvitations();
    })

    this.actions$.pipe(
      ofType(IncomingInvitationsActions.ACCEPT_INVITATION_ERROR),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.snackbarService.openSnackBar('Failed to accept invitation', undefined)
      this.getIncomingInvitations();
    })
  }

  rejectInvitation($event: InvitationRow) {
    const invitationRow: InvitationRow = $event;
    console.log("reject")
    console.log(invitationRow)
    this.store.dispatch(IncomingInvitationsActions.REJECT_INVITATION({ accountantId: invitationRow.getId() }));
    this.actions$.pipe(
      ofType(IncomingInvitationsActions.REJECT_INVITATION_SUCCESS),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.snackbarService.openSnackBar('Invitation rejected successfully', undefined)
      this.getIncomingInvitations();
    })

    this.actions$.pipe(
      ofType(IncomingInvitationsActions.REJECT_INVITATION_ERROR),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.snackbarService.openSnackBar('Failed to reject invitation', undefined)
      this.getIncomingInvitations();
    })
  }

}
