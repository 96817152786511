<section *ngIf="selectedCountry; else loadingMobile" id="add-mobile-number">

  <div class="flex flex-col w-[642px]">

    <a routerLink="../../">
      <mat-icon class="material-symbols-outlined text-text-primary w-8 h-8 text-[32px]">arrow_circle_left</mat-icon></a>
    <h1 class="text-xl font-semibold text-text-primary mt-1">Add Phone Number</h1>
    <h3 class="text-lg font-medium text-text-secondary mt-1">Manage how you can access your account, receive mobile
      notifications etc.
    </h3>

    <form class="flex flex-row mt-7" [formGroup]="addMobileForm" (keydown.enter)="save()">
      <mat-form-field appearance="outline" class="w-[309px]">
        <img matPrefix src="{{selectedCountry.flag}}" class="h-5 w-7 ml-4 mr-3" />
        <mat-select [(value)]="selectedCountry">
          <mat-option *ngFor="let c of countryList" [value]="c">
            <div class="flex flex-row">
              <img src="{{c.flag}}" class="h-5 w-7 mr-3" />
              <p>{{c.name}}</p>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-[309px] ml-6" floatLabel="always">
        <mat-label>New Mobile Number</mat-label>
        <p matTextPrefix class="font-medium text-base mx-1" *ngIf="selectedCountry">
          +{{selectedCountry.numeric_code}}</p>
        <input matInput formControlName="mobile" class="font-medium text-base" type="number">
        <mat-hint *ngIf="isUser" class="font-medium text-xs text-alert-error-900">Mobile already linked to another
          account</mat-hint>
        <mat-error
          *ngIf="addMobileForm.controls['mobile'].errors && (addMobileForm.controls['mobile'].dirty || addMobileForm.controls['mobile'].touched)">
          <mat-hint class="mat-caption font-medium"
            *ngIf="addMobileForm.controls['mobile'].errors['required']">Required</mat-hint>
          <mat-hint class="mat-caption font-medium" *ngIf="addMobileForm.controls['mobile'].errors['pattern']">Invalid
            Mobile
            Number</mat-hint>
        </mat-error>

      </mat-form-field>
    </form>

    <div class="flex flex-row mt-5">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button mat-flat-button class="text-base font-medium rounded w-36 h-12" (click)="save()">Save</button>
      </ng-container>
      <button mat-stroked-button class="text-base rounded font-medium w-36 h-12 ml-6"
        routerLink="../../">Cancel</button>
    </div>

  </div>

  <ng-template #loading>
    <button-loader [width]="'144'"></button-loader>
    <!-- <button class="text-base font-medium text-white rounded bg-blue-900 w-36 h-12 flex justify-center">
      <canvas riv="loading_bars_white" width="90" height="45">
        <riv-animation name="shrink" play></riv-animation>
      </canvas>
    </button> -->
  </ng-template>

</section>

<ng-template #loadingMobile>
  <div class="flex justify-center items-center w-[628px] mt-40">
    <mat-spinner [diameter]="40"></mat-spinner>
    <!-- <canvas riv="general-loading" width="50" height="50">
      <riv-animation name="animation" play></riv-animation>
    </canvas> -->
  </div>
</ng-template>