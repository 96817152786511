import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User, UserSelectors } from 'ngx-com-quicko-myaccount';
import { environment } from '../../../../../environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-password',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, RouterModule, CommonModule, MatCardModule],
  templateUrl: './password.component.html',
})
export class PasswordComponent {
  userDetails: Observable<User> = this.store.select(UserSelectors.USER);

  constructor(private store: Store) { }

  ngOnInit() { }

  navigateToSetPassword() {
    this.userDetails.subscribe((userDetails) => {
      window.location.href =
        environment.authentication_host +
        '/set-password?redirect=' + window.btoa(window.location.href);
    });
  }
}
