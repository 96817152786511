import { createReducer, on } from "@ngrx/store";
import { TaxDetails } from "../../../beans/TaxDetails";
import { TaxDetailsActions } from "./tax-details.actions";
import { TaxDetailsModel } from "./tax-details.model";

export const onGetTaxDetailsSuccess = on(TaxDetailsActions.GET_TAX_DETAILS_SUCCESS, (state: TaxDetailsModel, { taxDetails }) => {
    return {
        ...state,
        tax_details: taxDetails
    };
});

export const onPostTaxDetailsSuccess = on(TaxDetailsActions.POST_TAX_DETAILS_SUCCESS, (state: TaxDetailsModel, { taxDetails }) => {
    return {
        ...state,
        tax_details: taxDetails
    };
});

export const onEditTaxDetailsSuccess = on(TaxDetailsActions.PATCH_TAX_DETAILS_SUCCESS, (state: TaxDetailsModel, { taxDetails }) => {
    return {
        ...state,
        tax_details: taxDetails
    };
});


export const taxDetailsReducer = createReducer(
    { tax_details: null },
    onGetTaxDetailsSuccess,
    onPostTaxDetailsSuccess,
    onEditTaxDetailsSuccess
);