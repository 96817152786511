<!-- <canvas riv="loading_bars_white" width={{btnWidth}} height={{btnHeight}}>
        <riv-player name="loading-1" play></riv-player>
    </canvas> -->
<ng-container *ngIf="type === 'primary'; else secondary">
  <div [ngStyle]="btnColor ? {
      width: btnWidth + 'px',
      height: btnHeight + 'px',
      backgroundColor: btnColor
    } : {width: btnWidth + 'px',
      height: btnHeight + 'px'}" class="flex items-center justify-center mat-rounded-5 rounded-md gap-1">
    <div class="dot dot_loader w-[10px] h-[10px]" style="color: var(--sys-background)"></div>
    <div class="dot dot_loader w-[10px] h-[10px]" style="color: var(--sys-background)"></div>
    <div class="dot dot_loader w-[10px] h-[10px]" style="color: var(--sys-background)"></div>
  </div>
</ng-container>
<ng-template #secondary>
  <div [ngStyle]="{
          width: btnWidth + 'px',
          height: btnHeight + 'px',
          backgroundColor: '#ffffff',
        }" class="border border-stroke flex items-center justify-center mat-rounded-5 rounded-md gap-1">
    <div class="dot dot_loader w-[10px] h-[10px]" [ngStyle]="{ color: btnColor }"></div>
    <div class="dot dot_loader w-[10px] h-[10px]" [ngStyle]="{ color: btnColor }"></div>
    <div class="dot dot_loader w-[10px] h-[10px]" [ngStyle]="{ color: btnColor }"></div>
  </div>
</ng-template>