import { JSONArray } from '../org-quicko-core/beans/JSONObject'
import { AccountantRow } from './AccountantRow'
import { Table } from '../org-quicko-sheet/beans/Table'
import 'reflect-metadata'

@Reflect.metadata('name', 'com.quicko.accountant.accountant_table')
export class AccountantTable extends Table {
    private static header: JSONArray = Array.from([
        'id',
        'email_id',
        'first_name',
        'last_name',
        'expiry_at',
        'last_accessed_at',
        'created_at',
        'updated_at',
    ])

    constructor(name: string, metadata: any, header: Array<any>, rows: Array<any>) {
        super(name, metadata, header, rows)
        super.setEntity('table')
        super.setName('com.quicko.accountant.accountant_table')
        super.setHeader(AccountantTable.header)
    }

    getAccountantRows() {
        return super.getRows().map((row) => new AccountantRow(row as JSONArray))
    }
}