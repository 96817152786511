import { Routes } from '@angular/router';
import { loggedIn, userResolver } from 'ngx-com-quicko-myaccount';
import { RootComponent } from './components/root/root.component';
import { MyaccountComponent } from './components/myaccount/myaccount.component';
import { EditPersonalDetailsComponent } from './components/myaccount/edit-personal-details/edit-personal-details.component';
import { EditEmailAddressComponent } from './components/myaccount/email-address/edit-email-address/edit-email-address.component';
import { EditMobileNumberComponent } from './components/myaccount/mobile-number/edit-mobile-number/edit-mobile-number.component';
import { AddMobileNumberComponent } from './components/myaccount/mobile-number/add-mobile-number/add-mobile-number.component';
import { AccountantsComponent } from './components/accountants/accountants.component';
import { MyAccountantsComponent } from './components/accountants/my-accountants/my-accountants.component';
import { IncomingInvitationsComponent } from './components/accountants/incoming-invitations/incoming-invitations.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AppsContainerComponent } from './components/settings/data-and-privacy/app-container/app-container.component';
import { ThirdPartyAppsComponent } from './components/settings/data-and-privacy/app-container/third-party-apps/third-party-apps.component';
import { AppDetailComponent } from './components/settings/data-and-privacy/app-container/app-detail/app-detail.component';
import { EditPasswordComponent } from './components/settings/security/edit-password/edit-password.component';
import { EditBillingDetailsComponent } from './components/myaccount/edit-billing-details/edit-billing-details.component';

export const routes: Routes = [
    {
        path: '',
        component: RootComponent,
        canActivate: [loggedIn],
        resolve: { user: userResolver },
        children: [
            {
                path: "", pathMatch: "full", redirectTo: 'profile',
            },
            {
                path: "profile", children: [ //profile
                    { path: "", component: MyaccountComponent },
                    { path: "personal-details/edit", component: EditPersonalDetailsComponent },
                    { path: "email-address/edit", component: EditEmailAddressComponent },
                    { path: "billing-details/edit", component: EditBillingDetailsComponent },
                    {
                        path: "mobile-number", children: [
                            { path: "edit", component: EditMobileNumberComponent },
                            { path: "add", component: AddMobileNumberComponent }
                        ]
                    }
                ]
            },
            {
                path: "accountants", component: AccountantsComponent, children: [
                    { path: '', component: MyAccountantsComponent },
                    { path: 'invitations', component: IncomingInvitationsComponent }
                ]
            },
            {
                path: "settings", children: [
                    { path: "", component: SettingsComponent },
                    {
                        path: "apps", component: AppsContainerComponent,
                        children: [
                            { path: '', redirectTo: 'list', pathMatch: 'full' },
                            { path: "list", component: ThirdPartyAppsComponent },
                            { path: ":app_key", component: AppDetailComponent }
                        ]
                    },
                    {
                        path: "security", children: [
                            { path: "password/edit", component: EditPasswordComponent }
                        ]
                    },
                ]
            }
        ]
    }
];
