<ng-container *ngIf="addressForm">
  <form [formGroup]="addressForm">
    <div class="flex flex-col">
      <mat-form-field appearance="outline" class="w-[642px] mb-[8px]">
        <mat-label class="mat-body-1">Street</mat-label>
        <input matInput class="form-control" formControlName="street" />
        <mat-error>
          <ng-container *ngIf="
              addressForm.controls['street'].errors &&
              (addressForm.controls['street'].dirty ||
                addressForm.controls['street'].touched)
            ">
            <ng-container *ngFor="
                let error of addressForm.controls['street'].errors | keyvalue
              ">
              <ng-container *ngIf="error.key">
                <form-field-error [errorMessage]="error.value['message']"></form-field-error>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-error>
      </mat-form-field>

      <div class="flex flex-row justify-between items-center gap-[24px] mb-[8px]">
        <mat-form-field appearance="outline" class="w-[309px]">
          <mat-label class="mat-body-1">Area</mat-label>
          <input matInput class="form-control" formControlName="area" />
          <mat-error>
            <ng-container *ngIf="
                addressForm.controls['area'].errors &&
                (addressForm.controls['area'].dirty ||
                  addressForm.controls['area'].touched)
              ">
              <ng-container *ngFor="
                  let error of addressForm.controls['area'].errors | keyvalue
                ">
                <ng-container *ngIf="error.key">
                  <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-[309px]">
          <mat-label class="mat-body-1">City</mat-label>
          <input matInput class="form-control" formControlName="city" />
          <mat-error>
            <ng-container *ngIf="
                addressForm.controls['city'].errors &&
                (addressForm.controls['city'].dirty ||
                  addressForm.controls['city'].touched)
              ">
              <ng-container *ngFor="
                  let error of addressForm.controls['city'].errors | keyvalue
                ">
                <ng-container *ngIf="error.key">
                  <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-row justify-between items-center gap-[24px] mb-[8px]">
        <mat-form-field appearance="outline" class="w-[309px]">
          <mat-label class="mat-body-1">State</mat-label>
          <input matInput class="form-control" formControlName="state" />
          <mat-error>
            <ng-container *ngIf="
                addressForm.controls['state'].errors &&
                (addressForm.controls['state'].dirty ||
                  addressForm.controls['state'].touched)
              ">
              <ng-container *ngFor="
                  let error of addressForm.controls['state'].errors | keyvalue
                ">
                <ng-container *ngIf="error.key">
                  <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-[309px]">
          <mat-label class="mat-body-1">ZIP Code</mat-label>
          <input matInput type="text" class="form-control" formControlName="postal_code" />
          <mat-error>
            <ng-container *ngIf="
                addressForm.controls['postal_code'].errors &&
                (addressForm.controls['postal_code'].dirty ||
                  addressForm.controls['postal_code'].touched)
              ">
              <ng-container *ngFor="
                  let error of addressForm.controls['postal_code'].errors
                    | keyvalue
                ">
                <ng-container *ngIf="error.key">
                  <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-container>