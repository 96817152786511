import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { AvatarModule } from 'ngx-avatars';
import { AuthService, EntitlementsService, SnackbarService, User, UserSelectors } from 'ngx-com-quicko-myaccount';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppsMenuComponent } from './apps-menu/apps-menu.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

@Component({
	selector: 'app-web-header',
	standalone: true,
	imports: [AppsMenuComponent, MatCardModule, CommonModule, MatIconModule, NgxSkeletonLoaderModule, AvatarModule, MatButtonModule, MatMenuModule, MatDividerModule],
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class WebHeaderComponent implements OnInit {

	hasName: boolean = true;
	userDetails: Observable<User> = this.store.select(UserSelectors.USER);
	theme: string = 'light';

	constructor(private store: Store, private router: Router, private route: ActivatedRoute, private entitlementsService: EntitlementsService, private authService: AuthService, private snackbarService: SnackbarService) {
	}

	ngOnInit() {
		const cookies = document.cookie.split(';');
		for (let cookie of cookies) {
			if (cookie.split('=')[0].trim() == 'theme') {
				const themeCookie = cookie.split('=')[1].trim();
				const decodedThemeObject = JSON.parse(atob(decodeURIComponent(themeCookie).trim()));
				this.theme = decodedThemeObject.mode.toLowerCase();
				break;
			}
		}

		this.userDetails.subscribe(user => {
			if (user.first_name || user.last_name) {
				this.hasName = true;
			} else {
				this.hasName = false;
			}
		})
	}

	logout() {
		window.location.href = environment.accounts_app_host + 'logout';
	}

	navigateToHelp() {
		window.open(environment.help_url, '_blank');
	}

	navigateToIT() {
		window.open(environment.income_tax_url, '_blank');
	}

	navigateToMyAccountAppProfile() {
		this.router.navigate(['profile'], {
			relativeTo: this.route.root
		})
	}

	navigateToMyAccountAppSettings() {
		this.router.navigate(['settings'], {
			relativeTo: this.route.root
		})
	}

}
