import { createAction, props } from "@ngrx/store";
import { AccountantRow, Invitation, InvitationRow } from "../../../beans";
import { Accountant } from "../../../beans/Accountant";
import { v4 as uuidv4 } from "uuid";

export const OutgoingInvitationsActions = {

    GET_INVITATIONS: createAction('[Outgoing Invitations] GET Outgoing Invitations ' + uuidv4(), props<{ lastEvaluatedKey?: string, limit?: number, sort?: string }>()),
    GET_INVITATIONS_SUCCESS: createAction('[Outgoing Invitations] GET Outgoing Invitations Success ' + uuidv4(), props<{ invitationRows: InvitationRow[], lastEvaluatedKey?: string }>()),
    GET_INVITATIONS_ERROR: createAction('[Outgoing Invitations] GET Outgoing Invitations Error ' + uuidv4(), props<{ err: any }>()),

    POST_INVITATION: createAction('[Outgoing Invitations] Post Invitation ' + uuidv4(), props<{ invitation: Invitation }>()),
    POST_INVITATION_SUCCESS: createAction('[Outgoing Invitations] Post Invitation Success ' + uuidv4(), props<{ invitation: Invitation }>()),
    POST_INVITATION_ERROR: createAction('[Outgoing Invitations] Post Invitation Error ' + uuidv4(), props<{ err: any }>()),

    UPDATE_INVITATION: createAction('[Outgoing Invitations] Update Invitation ' + uuidv4(), props<{ invitation: Invitation }>()),
    UPDATE_INVITATION_SUCCESS: createAction('[Outgoing Invitations] Update Invitation Success ' + uuidv4(), props<{ invitation: Invitation }>()),
    UPDATE_INVITATION_ERROR: createAction('[Outgoing Invitations] Update Invitation Error ' + uuidv4(), props<{ err: any }>()),

    DELETE_INVITATION: createAction('[Outgoing Invitations] Delete Invitation ' + uuidv4(), props<{ accountantId: string }>()),
    DELETE_INVITATION_SUCCESS: createAction('[Outgoing Invitations] Delete Invitation Success ' + uuidv4(), props<{ accountantId: string }>()),
    DELETE_INVITATION_ERROR: createAction('[Outgoing Invitations] Delete Invitation Error ' + uuidv4(), props<{ err: any }>()),
}
