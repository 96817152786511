import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { ConnectedAppsModel, initialState } from "./app.model";

@Injectable()
export class ConnectedAppsStore extends ComponentStore<ConnectedAppsModel> {

    constructor() {
        super(initialState);
    }
}