import { combineReducers } from "@ngrx/store";
import { AccountantStore } from "./accountant-store.model";
import { myAccountantsReducer } from "./my-accountants/my-accountants-store.reducers";
import { outgoingInvitationsReducer } from "./outgoing-invitations/outgoing-invitations-store.reducers";
import { incomingInvitationsReducer } from "./incoming-invitations/incoming-invitations-store.reducers";

export const AccountantStoreReducer = combineReducers<AccountantStore>({
    'my_accountants_store': myAccountantsReducer,
    'incoming_invitations_store': incomingInvitationsReducer,
    'outgoing_invitations_store': outgoingInvitationsReducer
})  
