<section id="edit-email-address">

  <div class="flex flex-col w-[642px]">

    <a routerLink="../../">
      <mat-icon class="material-symbols-outlined text-text-primary w-8 h-8 text-[32px]">arrow_circle_left</mat-icon></a>
    <h1 class="text-xl font-semibold text-text-primary mt-1">Edit Email Address</h1>
    <h3 class="text-lg font-medium text-text-secondary mt-1">Manage how you can access your account, receive emails
      etc.
    </h3>

    <form class="flex flex-col mt-7" [formGroup]="emailForm" (keyup.enter)="update()">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>New Email Address</mat-label>
        <input matInput formControlName="email">
        <mat-hint *ngIf="isUser" class="font-medium text-xs text-alert-error-900">Email already linked to another
          account</mat-hint>
        <mat-error
          *ngIf="emailForm.controls['email'].errors && (emailForm.controls['email'].dirty || emailForm.controls['email'].touched)"
          class="font-medium text-xs text-alert-error-900">
          <mat-hint *ngIf="emailForm.controls['email'].errors['required']">Required</mat-hint>
          <mat-hint *ngIf="emailForm.controls['email'].errors['email']">Invalid Email</mat-hint>
        </mat-error>
      </mat-form-field>
    </form>

    <div class="flex flex-row mt-5">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button mat-flat-button class="text-base font-medium rounded w-36 h-12" (click)="update()">Update</button>
      </ng-container>
      <button mat-stroked-button class="text-base font-medium rounded w-36 h-12 ml-6"
        routerLink="../../">Cancel</button>
    </div>

  </div>
  <ng-template #loading>
    <button-loader [width]="'144'"></button-loader>
    <!-- <button class="text-base font-medium text-white rounded bg-blue-900 w-36 h-12 flex justify-center">
      <canvas riv="loading_bars_white" width="90" height="45">
        <riv-animation name="shrink" play></riv-animation>
      </canvas>
    </button> -->
  </ng-template>

</section>