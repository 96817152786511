import { createAction, props } from '@ngrx/store';
import { User } from '../../../beans/User';

export const UserActions = {
    GET_USER: createAction('[User] GET User'),
    GET_USER_SUCCESS: createAction('[User] GET User Success', props<{ user: User }>()),
    GET_USER_ERROR: createAction('[User] GET User Error', props<{ err: any }>()),

    POST_USER: createAction('[User] POST User', props<{ user: User }>()),
    POST_USER_SUCCESS: createAction('[User] POST User Success', props<{ user: User }>()),
    POST_USER_ERROR: createAction('[User] POST User Error', props<{ err: any }>())
}