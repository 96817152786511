<div class="border border-outline-variant border-stroke rounded-md">
  <div class="flex flex-col">
    <div class="flex flex-row items-center px-6 py-5">
      <div class="w-[48px] mr-2">
        <div class="blue-bg-50  rounded-full flex flex-row items-center justify-center w-[48px] h-[48px]">
          <ng-container *ngIf="hasName == true else noName">
            <span class="mat-subtitle-2 blue-grey-400 m-0" style="font-weight: 600">{{
              (invitationRow?.getFirstName() ?
              invitationRow?.getFirstName().trim()[0].toUpperCase() : '') +
              (invitationRow?.getLastName() ?
              invitationRow?.getLastName().trim()[0].toUpperCase() : '') }}
            </span>
          </ng-container>
          <ng-template #noName>
            <span class="mat-subtitle-2 blue-grey-400 m-0" style="font-weight: 600">{{
              invitationRow.getEmail() ?
              invitationRow?.getEmail().trim()[0].toUpperCase() : ''}}
            </span>
          </ng-template>
        </div>

        <!-- <ngx-avatars fgColor="#78909C" bgColor="#E0E8FF" size="48"
          name="{{invitationRow?.getFirstName() }} {{invitationRow?.getLastName() }}"></ngx-avatars> -->
      </div>


      <div class="flex flex-col w-[450px] mr-4">
        <ng-container *ngIf="hasName == true else showEmail">
          <span class="mat-body-1  text-left overflow-hidden truncate mb-[0px]">
            {{invitationRow?.getFirstName() }} {{invitationRow?.getLastName() }}
          </span>
        </ng-container>
        <ng-template #showEmail>
          <span class="mat-body-1  text-left overflow-hidden truncate mb-[0px]">
            {{invitationRow?.getEmail() ? invitationRow?.getEmail().split('@')[0] : '' }}
          </span>
        </ng-template>
        <div class="mat-body-2 on-surface-variant overflow-hidden truncate">{{ invitationRow?.getEmail()}}</div>
      </div>

      <div class="mr-2">
        <button mat-flat-button class="mat-body-1 px-7 py-5 m-0 rounded-md" (click)="acceptInvitation()">
          <p class="mat-body-1 mb-0 whitespace-nowrap">Accept</p>
        </button>
      </div>

      <div>
        <button mat-stroked-button class="mat-body-1 px-7 py-5 m-0 rounded-md" (click)="rejectInvitation()">
          <p class="mat-body-1 mb-0 whitespace-nowrap">Decline</p>
        </button>
      </div>
    </div>

    <div class="bg-surface-container-high flex flex-row gap-x-2 px-4 py-3">
      <mat-icon class="size-[16px] material-symbols-outlined" style="font-size:16px;">
        info
      </mat-icon>
      <span class="mat-caption" style="font-weight: 500;">
        Invite expires on {{ invitationExpiry | date:"dd/MM/yyyy, hh:mm a"}}
      </span>
    </div>
  </div>
</div>