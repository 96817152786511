import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User, UserSelectors, AuthService, EntitlementsService, SnackbarService } from 'ngx-com-quicko-myaccount';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { ButtonLoaderComponent } from 'projects/web-myaccount/src/app/common/components/button-loader/button-loader.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-edit-password',
  standalone: true,
  providers: [RxFormBuilder],
  imports: [CommonModule, ButtonLoaderComponent, MatButtonModule, RouterModule, MatIconModule, MatInputModule, MatNativeDateModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, RiveModule, RxReactiveFormsModule],
  templateUrl: './edit-password.component.html'
})
export class EditPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  userDetails: Observable<User> = this.store.select(UserSelectors.USER);
  validations: any;
  username!: string;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  showOldPassword: boolean = false;
  saveFormLoading: boolean = false;
  incorrectPassword: boolean = false;

  constructor(
    private router: Router,
    private store: Store,
    private entitlementsService: EntitlementsService,
    private authService: AuthService,
    private snackbarService: SnackbarService
  ) {
    this.passwordForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    });

    this.validations = {
      samePassword: {
        bool: false,
        message: 'Passwords do not match',
      },
      minLength: {
        bool: false,
        message: 'Must be at least 8 characters long',
      },
      hasLowercase: {
        bool: false,
        message: 'Must include at least one lowercase character',
      },
      hasUppercase: {
        bool: false,
        message: 'Must include at least one uppercase character',
      },
      hasNumber: {
        bool: false,
        message: 'Must include at least one number',
      },
      hasSpecialChar: {
        bool: false,
        message: 'Must include at least one special character (!@#$%^&*)',
      },
    };
  }

  ngOnInit() {
    this.userDetails.subscribe((user) => {
      this.username = user.username!;
    });

    this.passwordForm.valueChanges.subscribe(() => {
      this.checkValidations();
    });

    this.passwordForm.controls['oldPassword'].valueChanges.subscribe(() => {
      this.incorrectPassword = false;
    });
  }

  checkValidations() {
    const newPassword = this.passwordForm.controls['newPassword'].value;
    // check all validations
    let allValidations: boolean = true;

    // match passwords
    if (
      this.passwordForm.controls['newPassword'].value ===
      this.passwordForm.controls['confirmPassword'].value
    ) {
      this.validations['samePassword'].bool = true;
    } else {
      this.validations['samePassword'].bool = false;
      allValidations = false;
    }
    // check length
    if (newPassword.length >= 8) {
      this.validations['minLength'].bool = true;
    } else {
      this.validations['minLength'].bool = false;
      allValidations = false;
    }

    // check lowercase
    if (/[a-z]/.test(newPassword)) {
      this.validations['hasLowercase'].bool = true;
    } else {
      this.validations['hasLowercase'].bool = false;
      allValidations = false;
    }

    // check uppercase
    if (/[A-Z]/.test(newPassword)) {
      this.validations['hasUppercase'].bool = true;
    } else {
      this.validations['hasUppercase'].bool = false;
      allValidations = false;
    }

    // check number
    if (/\d/.test(newPassword)) {
      this.validations['hasNumber'].bool = true;
    } else {
      this.validations['hasNumber'].bool = false;
      allValidations = false;
    }

    // check special character
    if (/[!@#$%^&*]/.test(newPassword)) {
      this.validations['hasSpecialChar'].bool = true;
    } else {
      this.validations['hasSpecialChar'].bool = false;
      allValidations = false;
    }
    return allValidations;
  }

  update() {
    if (this.passwordForm.valid && this.checkValidations()) {
      this.saveFormLoading = true;
      this.entitlementsService
        .updatePassword(
          this.username,
          this.passwordForm.controls['oldPassword'].value,
          this.passwordForm.controls['newPassword'].value
        )
        .subscribe({
          next: (response: any) => {
            this.authService.setToken(response['access_token']);
            this.snackbarService.openSnackBar(
              'Password changed successfully',
              undefined
            );
            this.router.navigate(['profile']);
            this.passwordForm.reset();
            this.saveFormLoading = false;
          },
          error: (err) => {
            if (err.error.message) {
              if (err.error.message.includes('Bad credentials')) {
                this.incorrectPassword = true;
              }
              else {
                this.snackbarService.openSnackBar(err.error.message, undefined);
              }
            };
            this.saveFormLoading = false;
          },
        });
    } else {
      this.passwordForm.controls['oldPassword'].markAllAsTouched();
      this.passwordForm.controls['newPassword'].markAllAsTouched();
      this.passwordForm.controls['confirmPassword'].markAllAsTouched();
    }
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }
}
