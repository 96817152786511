import { maxLength, notEmpty, prop, required } from "@rxweb/reactive-form-validators";


export class User {

	@prop()
	"@entity"?: string = 'org.quicko.auth.user.user_details'

	@prop()
	@required()
	username?: string;

	@prop()
	@required()
	id?: string;

	@prop()
	@notEmpty({ message: 'First name cannot be empty' })
	@maxLength({ value: 25, message: 'Maximum length exceeded' })
	first_name?: string;

	@prop()
	@notEmpty({ message: 'Last name cannot be empty' })
	@maxLength({ value: 75, message: 'Maximum length exceeded' })
	last_name?: string;

	@prop()
	mobile?: string;

	@prop()
	country_code?: string;

	@prop()
	gender?: string;

	@prop()
	date_of_birth?: number;

	@prop()
	@required()
	credential_status?: string;

	constructor() {
	}

}