import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { FormFieldErrorComponent } from '../../form-field-error/form-field-error.component';

@Component({
  selector: 'app-foreign-address-view',
  standalone: true,
  providers: [RxFormBuilder],
  imports: [CommonModule, FormFieldErrorComponent, RouterModule, MatFormFieldModule, RiveModule, MatInputModule, ReactiveFormsModule, MatIconModule, RxReactiveFormsModule, MatSelectModule, RiveModule],
  templateUrl: './foreign-address-view.component.html',
  styleUrls: ['./foreign-address-view.component.css']
})
export class ForeignAddressViewComponent implements OnInit {

  @Input() addressForm?: FormGroup;
  @Input() disableArea?: boolean

  constructor() { }

  ngOnInit() { }

}
