<section id="edit-presonal-details">

    <div class="flex flex-col w-[642px] mb-20">

        <a routerLink="../../">
            <mat-icon
                class="material-symbols-outlined text-text-primary w-8 h-8 text-[32px]">arrow_circle_left</mat-icon></a>
        <h1 class="mat-headline-6 font-semibold text-text-primary m-0">Billing Details</h1>
        <h3 class="mat-subtitle-2 font-medium text-text-secondary m-0 mt-1">Manage your billing details across all
            Quicko
            Products
            for accurate tax compliance.</h3>

        <form class="flex flex-col mt-7" [formGroup]="taxDetailsForm" (keyup.enter)="validate() ? save() : showError()">

            <h2 class="mat-subtitle-2 font-semibold text-text-primary">Place of Supply</h2>
            <h3 class="mat-body-2 font-medium text-text-secondary mt-1">
                To ensure accurate tax compliance, provide your current state of residence.
            </h3>

            <mat-form-field appearance="outline" class="w-[309px] mt-6">
                <mat-label class="mat-body-1">Select State</mat-label>
                <mat-select [required]="gstRegistationStatus=='registered'" formControlName="place_of_supply"
                    class="form-control">
                    <mat-select-trigger>
                        <div class="flex flex-row">
                            <p class="mat-body-1">{{ taxDetailsForm['controls']['place_of_supply'].value }}
                            </p>
                        </div>
                    </mat-select-trigger>
                    <mat-option class="w-full" *ngFor="let state of stateList" [value]="state.state_name">
                        <div class="text-text-primary m-0 p-4 flex flex-row w-full">
                            <p class="mat-body-1 ml-[8px]">{{ state.state_name }}</p>
                        </div>
                    </mat-option>
                </mat-select>
                <mat-error>
                    <ng-container
                        *ngIf="taxDetailsForm['controls']['place_of_supply'].errors && (taxDetailsForm['controls']['place_of_supply'].dirty || taxDetailsForm['controls']['place_of_supply'].touched)">
                        <!-- <ng-container
                            *ngFor="let error of taxDetailsForm['controls']['place_of_supply'].errors | keyvalue; let i = index">
                            <ng-container *ngIf="i===0">
                                <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                            </ng-container>
                        </ng-container> -->
                        <ng-container *ngIf="gstRegistationStatus=='registered'">
                            <form-field-error [errorMessage]="'Place of supply is required'"></form-field-error>
                        </ng-container>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <h2 class="mat-subtitle-2 font-semibold text-text-primary mt-6">GST Details</h2>
            <h3 class="mat-body-2 font-medium text-text-secondary mt-1">
                Provide your GST registration to receive a GST invoice for any purchases made across Quicko.
            </h3>

            <div class="flex flex-col mt-6">

                <div class="flex flex-row gap-x-6">

                    <mat-form-field appearance="outline" class="w-[309px]">
                        <mat-label class="mat-body-1">GST Registration Status</mat-label>
                        <mat-select [(value)]="gstRegistationStatus" name="gstRegistrationStatus" class="form-control">
                            <mat-option class="mat-body-1 text-text-primary m-0 p-4" value="not_registered">Not
                                Registered</mat-option>
                            <mat-divider></mat-divider>
                            <mat-option class="mat-body-1 text-text-primary m-0 p-4"
                                value="registered">Registered</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="gstRegistationStatus=='registered'; else notRegisteredGSTIN">
                        <mat-form-field appearance="outline" class="w-[309px]">
                            <mat-label class="mat-body-1">GSTIN</mat-label>
                            <input matInput required formControlName="gstin" class="form-control uppercase">
                            <mat-error>
                                <ng-container
                                    *ngIf="taxDetailsForm['controls']['gstin'].errors && (taxDetailsForm['controls']['gstin'].dirty || taxDetailsForm['controls']['gstin'].touched)">

                                    <ng-container
                                        *ngFor="let error of taxDetailsForm['controls']['gstin'].errors | keyvalue; let i = index">
                                        <ng-container *ngIf="i===0">
                                            <form-field-error
                                                [errorMessage]="error.value['message']"></form-field-error>
                                        </ng-container>
                                    </ng-container>

                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </ng-container>

                    <ng-template #notRegisteredGSTIN>
                        <mat-form-field appearance="outline" class="w-[309px]">
                            <mat-label class="mat-body-1 font-medium">GSTIN</mat-label>
                            <input [disabled]="true" matInput />
                        </mat-form-field>
                    </ng-template>

                </div>

                <ng-container *ngIf="gstRegistationStatus=='registered'; else notRegisteredBusinessName">
                    <mat-form-field appearance="outline" class="w-[642px] mt-2">
                        <mat-label class="mat-body-1">Business Name</mat-label>
                        <input matInput required formControlName="legal_name" class="form-control">
                        <mat-spinner *ngIf="prefillLoading==true" matSuffix diameter="24"
                            style="display: inline-flex; margin-right: 24px"></mat-spinner>
                        <mat-error>
                            <ng-container
                                *ngIf="taxDetailsForm['controls']['legal_name'].errors && (taxDetailsForm['controls']['legal_name'].dirty || taxDetailsForm['controls']['legal_name'].touched)">

                                <ng-container
                                    *ngFor="let error of taxDetailsForm['controls']['legal_name'].errors | keyvalue; let i = index">
                                    <ng-container *ngIf="i===0">
                                        <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                                    </ng-container>
                                </ng-container>

                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </ng-container>

                <ng-template #notRegisteredBusinessName>
                    <mat-form-field appearance="outline" class="w-[642px] mt-2">
                        <mat-label class="mat-body-1 font-medium">Business Name</mat-label>
                        <input [disabled]="true" matInput />
                    </mat-form-field>
                </ng-template>

            </div>


            <h2 class="mat-subtitle-2 font-semibold text-text-primary mt-6">Billing Address</h2>
            <h3 class="mat-body-2 font-medium text-text-secondary mt-1">
                Provide details of your business address
            </h3>

            <div class="flex flex-col w-[642px] mt-6">
                <app-address [addressForm]="addressForm" (keyup.enter)="validate() ? save() : showError()">
                </app-address>
            </div>


        </form>

        <div class="flex flex-row mt-5">
            <ng-container *ngIf="!savingForm; else loading">
                <button mat-flat-button class="text-base font-medium rounded w-36 h-12"
                    (click)="validate() ? save() : showError()">Save</button>
            </ng-container>
            <button mat-stroked-button class="text-base font-medium rounded w-36 h-12 ml-6"
                routerLink="../../">Cancel</button>
        </div>

    </div>
    <ng-template #loading>
        <button-loader [width]="'144'"></button-loader>
        <!-- <button class="text-base font-medium text-white rounded bg-blue-900 w-36 h-12 flex justify-center">
            <canvas riv="loading_bars_white" width="90" height="45">
                <riv-animation name="shrink" play></riv-animation>
            </canvas>
        </button> -->
    </ng-template>

</section>