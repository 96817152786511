<ng-container *ngIf="addressForm">
  <form [formGroup]="addressForm">
    <div class="flex flex-col justify-start items-start">

      <!-- <mat-form-field appearance="outline" class="w-[309px] mb-[8px]">
          <mat-label class="mat-body-1">Country</mat-label>
          <mat-select formControlName="country" class="form-control" required>
            <mat-select-trigger>
              <div class="flex flex-row">
                <ng-container *ngFor="let country of countries$ | async">
                  <ng-container *ngIf="country.name === addressForm.controls['country'].value">
                    <img class="w-[26px] h-[20px]" src="{{ country.flag }}" />
                  </ng-container>
                </ng-container>
                <p class="mat-body-2 ml-[8px]">{{ addressForm.controls["country"].value }}</p>
              </div>
            </mat-select-trigger>
            <mat-option *ngFor="let country of countries$ | async" [value]="country.name">
              <div class="flex flex-row">
                <img class="w-[26px] h-[20px]" src="{{ country.flag }}" />
                <p class="mat-body-2 ml-[8px]">{{ country.name }}</p>
              </div>
            </mat-option>
          </mat-select>
          <mat-error>
            <ng-container *ngIf="addressForm.controls['country'].errors && (addressForm.controls['country'].dirty || addressForm.controls['country'].touched)">
              <ng-container *ngFor="let error of addressForm.controls['country'].errors | keyvalue">
                <ng-container *ngIf="error.key">
                  <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field> -->


      <mat-form-field appearance="outline" class="w-[309px] mb-[8px]">
        <mat-label class="mat-body-1">Country</mat-label>
        <mat-select formControlName="country" class="form-control" required>
          <mat-select-trigger>
            <div class="flex flex-row">
              <ng-container *ngFor="let country of countries$ | async">
                <ng-container *ngIf="country.name === addressForm.controls['country'].value">
                  <img class="w-[26px] h-[20px]" src="{{ country.flag }}" />
                </ng-container>
              </ng-container>
              <p class="mat-body-1 ml-[8px]">{{ addressForm.controls["country"].value }}</p>
            </div>
          </mat-select-trigger>
          <mat-option *ngFor="let country of countries$ | async" [value]="country.name">
            <div class="flex flex-row">
              <img class="w-[26px] h-[20px]" src="{{ country.flag }}" />
              <p class="mat-body-2 ml-[8px]">{{ country.name }}</p>
            </div>
          </mat-option>
        </mat-select>
        <mat-error>
          <ng-container
            *ngIf="addressForm.controls['country'].errors && (addressForm.controls['country'].dirty || addressForm.controls['country'].touched)">
            <ng-container *ngFor="let error of addressForm.controls['country'].errors | keyvalue">
              <ng-container *ngIf="error.key">
                <form-field-error [errorMessage]="error.value['message']"></form-field-error>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-error>
      </mat-form-field>
      <!-- 
      <mat-form-field appearance="outline" class="w-[309px] mb-[8px]">
        <mat-label class="mat-body-1">Country</mat-label>

        <img matPrefix src="{{ getFlag(selectedCountry) }}" class="h-5 w-7 ml-4 mr-3" />

        <mat-select [(value)]="selectedCountry">
          <mat-option *ngFor="let c of countries" [value]="c">
            <div class="flex flex-row">
              <img src="{{c?.flag}}" class="h-5 w-7 mr-3" />
              <p>{{c.name}}</p>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <mat-select [value]="selectedCountry">
            <mat-select-trigger>
              <div class="flex flex-row">
                <ng-container *ngFor="let country of (countries$ | async)">
                  <ng-container *ngIf="country.name === 'India' ">
                    <img class="w-[26px] h-[20px]" src="{{country.flag}}" />
                  </ng-container>
                </ng-container>
                <p class="mat-body-2 ml-[8px]">India</p>
                <div class="flex flex-row">
                  <img class="w-[26px] h-[20px]" src="{{country.flag}}" />
                  <p class="mat-body-2 ml-[8px]">{{country.name}}</p>
                </div>
              </div>
            </mat-select-trigger>
            <mat-option *ngFor="let country of (countries$ | async)" [value]="country.name">
              <div class="flex flex-row">
                <img class="w-[26px] h-[20px]" src="{{country.flag}}" />
                <p class="mat-body-2 ml-[8px]">{{country.name}}</p>
              </div>
            </mat-option>
          </mat-select> -->

      <ng-container *ngIf="addressForm.controls['country'].value == 'India'">
        <app-domestic-address-view [addressForm]="addressForm">
        </app-domestic-address-view>
      </ng-container>

      <ng-container *ngIf="addressForm.controls['country'].value != 'India'">
        <app-foreign-address-view [addressForm]="addressForm">
        </app-foreign-address-view>
      </ng-container>
    </div>
  </form>
</ng-container>