import { createReducer, on } from '@ngrx/store';
import { ReferenceDataActions } from './reference-data-store.actions';
import { ReferenceDataStore } from './reference-data-store.model';

const onCountriesSuccess = on(
    ReferenceDataActions.GET_COUNTRIES_SUCCESS,
    (refDataState: ReferenceDataStore, { countries }) => {
        return {
            ...refDataState,
            countries: countries,
        };
    }
);


const onStatesSuccess = on(
    ReferenceDataActions.GET_STATES_SUCCESS,
    (refDataState: ReferenceDataStore, { states }) => {
        return {
            ...refDataState,
            states: states,
        };
    }
);


export const referenceDataReducer = createReducer(
    {
        countries: null,
        states: null
    },
    onCountriesSuccess,
    onStatesSuccess
);
