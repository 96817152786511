import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'form-field-error',
  standalone: true,
  templateUrl: './form-field-error.component.html',
})

export class FormFieldErrorComponent implements OnInit {

  @Input() errorMessage: string | unknown;

  constructor() { }

  ngOnInit() { }

}