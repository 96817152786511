import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AccountantRow, Invitation, MyAccountantsActions, getAccountants, SnackbarService } from 'ngx-com-quicko-myaccount';
import { MatButtonModule } from '@angular/material/button';
import { ButtonLoaderComponent } from 'projects/web-myaccount/src/app/common/components/button-loader/button-loader.component';

@Component({
  selector: 'app-update-accountant-dialog',
  standalone: true,
  imports: [CommonModule, ButtonLoaderComponent, MatButtonModule, RouterModule, MatIconModule, MatInputModule, MatNativeDateModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, RiveModule, RxReactiveFormsModule],
  templateUrl: './update-accountant-dialog.component.html',
  styleUrls: ['./update-accountant-dialog.component.css']
})
export class UpdateAccountantDialogComponent implements OnInit {

  invitationForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  savingForm: boolean = false;
  accountantRows$: Observable<Array<AccountantRow>> = this.store.select(getAccountants());
  options = [{
    access: (new Date().setDate(new Date().getDate() + 1)), expiry: '24 hours (1 day)'
  },
  {
    access: (new Date().setDate(new Date().getDate() + 7)), expiry: '7 days'
  },
  {
    access: (new Date().setDate(new Date().getDate() + 14)), expiry: '14 days'
  },
  {
    access: (new Date().setDate(new Date().getDate() + 30)), expiry: '30 days'
  },
  {
    access: -1, expiry: 'Never expires'
  }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { accountantRow: AccountantRow },
    public snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<UpdateAccountantDialogComponent>,
    private formBuilder: RxFormBuilder,
    private store: Store,
    private actions$: Actions,
    private route: ActivatedRoute,
  ) {
    this.invitationForm = this.formBuilder.formGroup(new Invitation());
  }


  ngOnInit() {
    const invitation: Invitation = new Invitation();

    invitation.inviteeEmail = this.data.accountantRow.getEmail();
    invitation.inviteeFirstName = this.data.accountantRow.getFirstName();
    invitation.inviteeLastName = this.data.accountantRow.getLastName();

    this.invitationForm.patchValue(invitation);

    if (this.options.length > 0) {
      this.invitationForm.get('accessValidity')?.setValue(this.options[0].access);
    }

  }


  showError() {
    this.invitationForm.controls['inviteeFirstName'].markAsTouched();
    this.invitationForm.controls['inviteeLastName'].markAsTouched();
    this.invitationForm.controls['inviteeEmail'].markAsTouched();
    this.invitationForm.controls['accessValidity'].markAsTouched();
  }

  validate() {
    console.log(this.invitationForm)
    return this.invitationForm.valid;
  }

  save(): void {
    this.savingForm = true;

    if (this.invitationForm.get('accessValidity')?.value == -1) {
      this.invitationForm.get('accessValidity')?.setValue(null)
    }

    this.store.dispatch(MyAccountantsActions.UPDATE_ACCOUNTANT({ accountantId: this.data.accountantRow.getId()!, expiryAt: this.invitationForm.get('accessValidity')?.value }))

    this.actions$.pipe(ofType(MyAccountantsActions.UPDATE_ACCOUNTANT_SUCCESS), takeUntil(this.destroy$)).subscribe((action) => {
      this.snackbarService.openSnackBar('Accountant access updated succesfully', undefined)
    }
    )

    this.actions$.pipe(ofType(MyAccountantsActions.UPDATE_ACCOUNTANT_ERROR)).subscribe(() => {
      this.snackbarService.openSnackBar('Failed to update accountant access', undefined)
    })


    this.dialogRef.close();

  }

  close() {
    this.dialogRef.close();
  }


}
