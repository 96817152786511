import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
// import { AccountantsActions } from '../../store/accountants/accountants.actions';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { IncomingInvitationsActions } from 'ngx-com-quicko-myaccount';
import { Subject, takeUntil } from 'rxjs';
import { InviteAccountantDialogComponent } from './my-accountants/invite-accountant-dialog/invite-accountant-dialog.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-accountants',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTabsModule, RouterModule, MatButtonModule],
  templateUrl: './accountants.component.html',
  styleUrls: ['./accountants.component.css']
})
export class AccountantsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>()
  incomingInvitationsExist: boolean = false;
  clickedOnce: boolean = false;

  constructor(private router: Router, private store: Store, private dialog: MatDialog, private actions$: Actions) { }

  ngOnInit() {
    if (this.router.url.includes('invitations')) {
      this.clickedOnce = true;
    }

    this.store.dispatch(IncomingInvitationsActions.GET_INVITATIONS_COUNT());
    this.actions$.pipe(
      ofType(IncomingInvitationsActions.GET_INVITATIONS_COUNT_SUCCESS),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      if (response.invitationCount > 0) {
        this.incomingInvitationsExist = true;
      } else {
        this.incomingInvitationsExist = false;
      }
    })
  }

  updateClickedOnce() {
    console.log("updated")
    this.clickedOnce = true;
  }

  inviteAccountant(): void {
    this.dialog.open(InviteAccountantDialogComponent);
  }

  isTabActive(route: string): boolean {
    // Extract the base route from the current URL
    const currentRoute = this.router.url.split('?')[0];
    return currentRoute === route;
  }

}
