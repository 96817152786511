import { email, notEmpty, maxLength, pattern, prop, required } from "@rxweb/reactive-form-validators";
import { BaseObject } from "./BaseObject";

export class Contact extends BaseObject {

    @prop()
    "@entity"?: string = 'org.quicko.contact'

    @notEmpty({ message: 'Email cannot be empty' })
    @required({ message: 'Email cannot be empty' })
    @maxLength({ value: 125, message: 'Max Length 125' })
    @email({ message: 'Email is incorrect' })
    email?: string

    @notEmpty({ message: 'ISD code cannot be empty' })
    @required()
    @pattern({ expression: { 'isd_pattern': /^[0-9]{1,5}$/ } })
    country_code?: string

    @notEmpty({ message: 'Mobile Number cannot be empty' })
    @pattern({ expression: { 'mobile_pattern': /^[0-9]{5,10}$/ }, message: 'Invalid Mobile Number' })
    mobile?: string

}