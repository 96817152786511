import { maxLength, notEmpty, pattern, prop } from '@rxweb/reactive-form-validators';
import { BaseObject } from './BaseObject';

export class Address extends BaseObject {
  @prop()
  '@entity'?: string = 'org.quicko.address';

  @prop()
  type?: string;

  @prop()
  is_primary?: boolean;

  @notEmpty({ message: 'Street cannot be empty' })
  @maxLength({ value: 50, message: 'Maximum length exceeded' })
  street?: string;

  @notEmpty({ message: 'Area cannot be empty' })
  @maxLength({ value: 50, message: 'Maximum length exceeded' })
  area?: string;

  @notEmpty({ message: 'City cannot be empty' })
  @maxLength({ value: 50, message: 'Maximum length exceeded' })
  city?: string;

  @notEmpty({ message: 'State cannot be empty' })
  state?: string;

  @prop()
  district?: string;

  @prop()
  @notEmpty({ message: 'Country cannot be empty' })
  country?: string;

  @notEmpty({ message: 'Pin Code cannot be empty' })
  @pattern({ conditionalExpression: (x: Address) => x.country == 'India', expression: { postal_code_pattern: /^[1-9]{1}[0-9]{5}$/ }, message: 'Invalid Pin Code' })
  @maxLength({ conditionalExpression: (x: Address) => x.country != 'India', value: 8, message: 'Max Length 8' })
  postal_code?: string;


  static init(): Address {
    const address = new Address();
    return address;
  }

  getfullAddress?(): string | undefined {

    const parts: string[] = [];
    if (this.street != null) {
      parts.push(this.street)
    }
    if (this.area != null) {
      parts.push(this.area)
    }
    if (this.city != null) {
      parts.push(this.city)
    }
    if (this.country != null) {
      parts.push(this.country)
    }
    if (this.postal_code != null) {
      parts.push(this.postal_code)
    }

    return parts.join(', ');
  }
}
