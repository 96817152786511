<section id="delete-account" class="p-6">

  <div class="flex flex-row justify-between">
    <h2 class="text-xl font-semibold text-text-primary">Delete Account</h2>
    <mat-icon class="h-[28px] w-[28px] text-text-primary material-symbols-outlined cursor-pointer" [mat-dialog-close]>
      cancel
    </mat-icon>
    <!-- <mat-icon class="material-symbols-outlined cursor-pointer w-8 h-8 text-[32px] text-blue-grey-100">cancel</mat-icon> -->
  </div>

  <div *ngIf="initialScreen; else elseBlock" class="flex flex-col justify-between">

    <div class="flex flex-col">

      <p class="text-base font-medium on-surface-variant mt-2">We're sorry to see you go. Please let us know what
        went
        wrong. This will help us improve our service to taxpayers like you.</p>

      <mat-form-field appearance="outline" class="h-14 w-full mt-4"
        [ngClass]="selectedOption == 'Other' ? 'mb-1' : 'mb-[120px]'" (keyup.enter)="proceed()">
        <mat-label>Reason of account deletion</mat-label>
        <mat-select [formControl]="options" [(value)]="selectedOption">
          <mat-option *ngFor="let option of optionsList" [value]="option.value">
            {{option.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="options.errors && (options.dirty || options.touched)" class="font-medium text-xs error">
          <mat-hint *ngIf="options.errors['required']">Required</mat-hint>
        </mat-error>
      </mat-form-field>

      <div *ngIf="selectedOption == 'Other'">
        <mat-form-field appearance="outline" class="w-full mt-4" (keyup.enter)="proceed()">
          <textarea matInput [formControl]="otherReason" placeholder="Please Specify"></textarea>
          <mat-error *ngIf="otherReason.errors && (otherReason.dirty || otherReason.touched)"
            class="font-medium text-xs error">
            <mat-hint *ngIf="otherReason.errors['required']">Required</mat-hint>
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <div class="flex flex-row mt-6">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button mat-flat-button color="warn" class="text-base font-medium rounded w-36 h-12"
          (click)="proceed()">Proceed</button>
      </ng-container>
      <button mat-button color="warn" class="border-error text-base font-medium error rounded w-36 h-12 ml-4"
        [mat-dialog-close]>Cancel</button>

      <ng-template #loading>
        <button-loader [width]="'144'" [color]="'var(--sys-error)'"></button-loader>
      </ng-template>
    </div>

  </div>
  <ng-template #elseBlock class="flex flex-col justify-between">

    <div class="text-base font-medium on-surface-variant">
      <p class="mt-2">By deleting this account linked with {{(userDetails |
        async)?.username}},</p>

      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <mat-icon class="h-2 w-2 text-[8px] mx-1">circle</mat-icon>
          <p>you will no longer be able to access details & data created so far</p>
        </div>
        <div class="flex flex-row items-center">
          <mat-icon class="h-2 w-2 text-[8px] mx-1">circle</mat-icon>
          <p>all your subscriptions across Quicko will be removed</p>
        </div>
        <div class="flex flex-row items-center">
          <mat-icon class="h-2 w-2 text-[8px] mx-1">circle</mat-icon>
          <p>Quicko will delete all links to your data stored on our servers</p>
        </div>
        <div class="flex flex-row items-center">
          <mat-icon class="h-2 w-2 text-[8px] mx-1">circle</mat-icon>
          <p>you can still access any data reported to the tax departments via their websites</p>
        </div>
      </div>
      <p class="mt-4 mb-6">Please confirm this action by entering OTP sent to <span class="font-semibold">{{(userDetails
          | async)?.username}}</span></p>

      <ng-otp-input (onInputChange)="onOtpChange($event)" (keyup.enter)="delete()" [config]="{length:6, allowNumbersOnly: true, inputStyles: {
        'font-size': '20px',
        'font-weight': '500',
        'width': '46px',
        'height': '56px',
        'background-color': 'var(--sys-background)'
        }}">
      </ng-otp-input>
      <div>
        <button (click)="resendOTP()" [disabled]="timeLeft != 30"
          [ngClass]="timeLeft == 30 ? 'primary' : 'on-surface-variant'" class="font-medium text-base mt-4 grow-0">Resend
          OTP<span *ngIf="timeLeft != 30"> (Available in {{timeLeft}})</span></button>
      </div>

    </div>
    <div class="flex flex-row mt-6">
      <ng-container *ngIf="!deleteForm; else loading">
        <button mat-flat-button color="warn" class="text-base font-medium rounded w-36 h-12"
          (click)="delete()">Delete</button>
      </ng-container>
      <button mat-button color="warn" class="border-error rounded text-base font-medium error w-36 h-12 ml-4"
        [mat-dialog-close]>Cancel</button>
    </div>

    <ng-template #loading>
      <button-loader [width]="'144'" [color]="'var(--sys-error)'"></button-loader>
    </ng-template>
  </ng-template>

</section>