import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ApiResponse } from '../beans/org-quicko-core/interfaces/ApiResponse'
import { Invitation } from '../beans';
import { instanceToPlain } from 'class-transformer';
import { ENV_CONFIG } from '../config/token';

@Injectable({
    providedIn: 'root',
})
export class InvitationService {
    private endpoint: string;

    constructor(
        @Inject(ENV_CONFIG) private environment: any,
        private httpClient: HttpClient,
    ) {
        this.endpoint = this.environment.myaccount_api_endpoint;
    }

    postInvitation(invitation: any) {
        const url = this.endpoint + '/accountants/invitations';
        return this.httpClient.post<ApiResponse<Invitation>>(url, invitation);
    }

    updateInvitation(invitation: any) {
        const url = this.endpoint + '/accountants/invitations';
        return this.httpClient.patch<ApiResponse<Invitation>>(url, invitation);
    }

    deleteInvitation(accountantId: string) {
        const url = this.endpoint + '/accountants/invitations';
        let params = new HttpParams();
        params = params.set('accountant_id', accountantId)
        return this.httpClient.delete<ApiResponse<any>>(url, { params: params });
    }

    getIncomingInvitations(lastEvaluatedKey?: string, limit?: number, sort?: string) {
        const url = this.endpoint + '/accountants/invitations/incoming';
        let params = new HttpParams();
        if (lastEvaluatedKey) {
            params = params.set('last_evaluated_key', lastEvaluatedKey);
        }
        if (limit) {
            params = params.set('limit', limit);
        }
        if (sort) {
            params = params.set('sort', sort);
        }
        return this.httpClient.get<ApiResponse<any>>(url, { params: params });
    }

    getIncomingInvitationsCount() {
        const url = this.endpoint + '/accountants/invitations/incoming/count';
        return this.httpClient.get<ApiResponse<any>>(url);
    }

    acceptInvitation(accountantId: string) {
        const url = this.endpoint + '/accountants/invitations/incoming/accept';
        let params = new HttpParams();
        params = params.set('accountant_id', accountantId)
        return this.httpClient.post<ApiResponse<any>>(url, null, { params: params });
    }

    rejectInvitation(accountantId: string) {
        const url = this.endpoint + '/accountants/invitations/incoming/reject';
        let params = new HttpParams();
        params = params.set('accountant_id', accountantId)
        return this.httpClient.delete<ApiResponse<any>>(url, { params: params });
    }

    getOutgoingInvitations(lastEvaluatedKey?: string, limit?: number, sort?: string) {
        const url = this.endpoint + '/accountants/invitations/outgoing';
        let params = new HttpParams();
        if (lastEvaluatedKey) {
            params = params.set('last_evaluated_key', lastEvaluatedKey);
        }
        if (limit) {
            params = params.set('limit', limit);
        }
        if (sort) {
            params = params.set('sort', sort);
        }
        return this.httpClient.get<ApiResponse<any>>(url, { params: params });
    }

    getOutgoingInvitationsCount() {
        const url = this.endpoint + '/accountants/invitations/outgoing/count';
        return this.httpClient.get<ApiResponse<any>>(url);
    }
}
