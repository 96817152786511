import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { AvatarModule } from 'ngx-avatars';
import { Store } from '@ngrx/store';
import { User, UserSelectors } from 'ngx-com-quicko-myaccount';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-apps-menu',
  standalone: true,
  imports: [CommonModule, MatButtonModule, AvatarModule, MatDividerModule, MatMenuModule, MatIconModule],
  templateUrl: './apps-menu.component.html',
  styleUrl: './apps-menu.component.css'
})
export class AppsMenuComponent {

  @Input() userDetails: User;
  constructor(private route: ActivatedRoute, private router: Router) {

  }

  navigateToSave() {
    window.open(environment.income_tax_url + '/tax-payers/' + this.userDetails.id + '/save', '_blank')
  }

  navigateToPay() {
    window.open(environment.income_tax_url + '/tax-payers/' + this.userDetails.id + '/pay', '_blank')
  }

  navigateToFile() {
    window.open(environment.income_tax_url + '/tax-payers/' + this.userDetails.id + '/file', '_blank')
  }

  navigateToInvestments() {
    window.open(environment.income_tax_url + '/tax-payers/' + this.userDetails.id + '/track/investments', '_blank')
  }

  navigateToTools() {
    window.open(environment.tools_url)
  }

  navigateToMyAccountAppProfile() {
    this.router.navigate(['profile'], {
      relativeTo: this.route.root
    })
  }
}
