import { createReducer, on } from '@ngrx/store';
import { IncomingInvitationsActions } from './incoming-invitations-store.actions';
import { IncomingInvitationsModel } from './incoming-invitations-store.model';
import { InvitationRow } from '../../../beans';

const onGetIncomingInvitations = on(

    IncomingInvitationsActions.GET_INVITATIONS_SUCCESS,
    (state: IncomingInvitationsModel, { invitationRows, lastEvaluatedKey }) => {
        return {
            ...state,
            incoming_invitations: invitationRows,
            last_evaluted_key: lastEvaluatedKey
        } as IncomingInvitationsModel
    },
);

const onAcceptInvitation = on(
    IncomingInvitationsActions.ACCEPT_INVITATION_SUCCESS,
    (state: IncomingInvitationsModel, { accountantId }) => {
        const existingInvitations = state.incoming_invitations;
        const filteredInvitations = existingInvitations.filter(
            (invitationRow: InvitationRow) => invitationRow.getId() != accountantId)
        return {
            ...state,
            incoming_invitations: filteredInvitations
        } as IncomingInvitationsModel
    }
)

const onRejectInvitation = on(
    IncomingInvitationsActions.REJECT_INVITATION_SUCCESS,
    (state: IncomingInvitationsModel, { accountantId }) => {
        const existingInvitations = state.incoming_invitations;
        const filteredInvitations = existingInvitations.filter(
            (invitationRow: InvitationRow) => invitationRow.getId() != accountantId)
        return {
            ...state,
            incoming_invitations: filteredInvitations
        } as IncomingInvitationsModel
    }
)

export const incomingInvitationsReducer = createReducer(
    {
        incoming_invitations: [],
        last_evaluated_key: undefined
    },
    onGetIncomingInvitations,
    onAcceptInvitation,
    onRejectInvitation
);
