import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User, UserSelectors, AuthService, EntitlementsService, SnackbarService } from 'ngx-com-quicko-myaccount';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatIconModule } from '@angular/material/icon';
import { RiveModule } from 'ng-rive';
import { CommonModule } from '@angular/common';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ButtonLoaderComponent } from '../../../common/components/button-loader/button-loader.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-verify-details',
  standalone: true,
  providers: [RxFormBuilder],
  imports: [CommonModule, MatButtonModule, ButtonLoaderComponent, RouterModule, MatInputModule, ReactiveFormsModule, NgOtpInputModule, MatIconModule, MatDialogModule, RiveModule],
  templateUrl: './verify-details.component.html',
  styleUrl: './verify-details.component.css'
})
export class VerifyDetailsComponent implements OnInit {
  userDetails: Observable<User> = this.store.select(UserSelectors.USER);
  user: any;
  otp: string | undefined;
  timeLeft = 30;
  interval: any;
  saveFormLoading: boolean = false;

  constructor(
    private router: Router,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private entitlementsService: EntitlementsService,
    private store: Store,
    public dialogRef: MatDialogRef<VerifyDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { email: string; mobile: string; countryCode: string }
  ) { }

  ngOnInit() {
    this.userDetails.subscribe((userDetails) => {
      this.user = userDetails;
    })
  }

  verify() {
    if (this.otp && this.data.email) {
      this.saveFormLoading = true;
      this.entitlementsService
        .verifyNewEmailOTP(this.data.email, this.otp!)
        .subscribe({
          next: (response: any) => {
            this.dialogRef.close(response['access_token']);
            this.saveFormLoading = false;
          },
          error: (err) => {
            if (
              err.error.message.includes('Too many verification attempts')
            ) {
              this.authService.removeToken();
              window.location.reload();
            } else {
              this.snackbarService.openSnackBar(err.error.message, undefined);
            };
            this.saveFormLoading = false;
          },
        });
    } else if (this.otp && this.data.mobile && this.data.countryCode) {
      this.saveFormLoading = true;
      this.entitlementsService
        .verifyNewMobileOTP(
          this.user.username!,
          this.data.mobile,
          this.data.countryCode,
          this.otp!
        )
        .subscribe({
          next: () => {
            this.dialogRef.close(true);
            this.saveFormLoading = false;
          },
          error: (err) => {
            if (
              err.error.message.includes('Too many verification attempts')
            ) {
              this.authService.removeToken();
              window.location.reload();
            } else if (err.error.message) {
              this.snackbarService.openSnackBar(err.error.message, undefined);
            };
            this.saveFormLoading = false;
          },
        });
    }
  }

  generateOtp() {
    if (this.data.email) {
      this.entitlementsService
        .getOTPByNewEamil(
          this.data.email
        )
        .subscribe({
          next: () => {
            this.snackbarService.openSnackBar('OTP Sent Successfully', undefined);
          }
        });
    }
    else if (this.data.mobile && this.data.countryCode) {
      this.entitlementsService
        .getOTPByNewMobile(
          this.user.username!,
          this.data.mobile,
          this.data.countryCode
        )
        .subscribe({
          next: () => {
            this.snackbarService.openSnackBar('OTP Sent Successfully', undefined);
          }
        });
    }
  }

  onOtpChange(otp: string) {
    if (otp.length === 6) {
      this.otp = otp;
    }
  }

  resendOTP() {
    this.generateOtp();
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.timeLeft = 30;
      }
    }, 1000);
  }
}
