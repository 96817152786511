import { CommonModule } from '@angular/common';
import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, NgModel, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { plainToInstance } from 'class-transformer';
import { RiveModule } from 'ng-rive';
import { Address, ReferenceDataActions, ReferenceDataService, SnackbarService, TaxDetails, TaxDetailsActions, TaxDetailsSelectors, getStateDetails } from 'ngx-com-quicko-myaccount';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { AddressComponent } from '../../../common/components/address/address.component';
import { DomesticAddressViewComponent } from '../../../common/components/address/domestic-address-view/domestic-address-view.component';
import { ForeignAddressViewComponent } from '../../../common/components/address/foreign-address-view/foreign-address-view.component';
import { ButtonLoaderComponent } from '../../../common/components/button-loader/button-loader.component';
import { FormFieldErrorComponent } from '../../../common/components/form-field-error/form-field-error.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-edit-billing-details',
  standalone: true,
  providers: [RxFormBuilder],
  imports: [CommonModule, MatButtonModule, ButtonLoaderComponent, MatProgressSpinnerModule, AddressComponent, ForeignAddressViewComponent, DomesticAddressViewComponent, FormFieldErrorComponent, RouterModule, MatFormFieldModule, MatDividerModule, RiveModule, MatInputModule, ReactiveFormsModule, MatIconModule, RxReactiveFormsModule, MatSelectModule, RiveModule],
  templateUrl: './edit-billing-details.component.html',
  styleUrl: './edit-billing-details.component.css'
})
export class EditBillingDetailsComponent {

  taxDetailsForm: FormGroup;
  addressForm: FormGroup;
  savingForm = false;
  taxDetails$: Observable<TaxDetails>;
  stateList: any;
  selectedState: string;
  taxDetails: TaxDetails;
  destroy$ = new Subject<void>()
  isUpdate: boolean = false;
  gstRegistationStatus: string = 'not_registered'; //default value
  states$: Observable<Array<any>>;
  prefillLoading: boolean = false;

  constructor(
    private formBuilder: RxFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private referenceDataService: ReferenceDataService,
    private snackBarService: SnackbarService
  ) {
    this.taxDetails$ = this.store.select(TaxDetailsSelectors.TAX_DETAILS);
    this.taxDetailsForm = this.formBuilder.formGroup(new TaxDetails());
    this.addressForm = this.formBuilder.formGroup(new Address());
    this.states$ = this.store.select(getStateDetails());

  }

  ngOnInit() {

    this.states$.subscribe((states: any) => {

      if (states == undefined) {
        this.store.dispatch(ReferenceDataActions.GET_STATES())
      } else {
        this.stateList = states;
      }
    })

    this.actions$.pipe(ofType(ReferenceDataActions.GET_STATES_SUCCESS), takeUntil(this.destroy$)).subscribe((res) => {
      this.states$.subscribe((states: any) => {
        this.stateList = states;
      })
    })

    console.log(this.taxDetailsForm)
    this.taxDetails$.pipe(takeUntil(this.destroy$)).subscribe((taxDetails: TaxDetails) => {
      if (taxDetails == undefined) {
        this.store.dispatch(TaxDetailsActions.GET_TAX_DETAILS())
      } else {
        this.isUpdate = true;
        this.taxDetails = taxDetails;
        this.taxDetailsForm.patchValue(this.taxDetails);
        this.addressForm.patchValue(this.taxDetails.address);
        this.checkGstRegistrationStatus();
      }
    })


    this.actions$.pipe(ofType(TaxDetailsActions.GET_TAX_DETAILS_SUCCESS), takeUntil(this.destroy$)).subscribe(() => {
      this.taxDetails$.pipe(take(1)).subscribe((taxDetails: TaxDetails) => {
        this.isUpdate = true;
        this.taxDetails = taxDetails;
        this.taxDetailsForm.patchValue(this.taxDetails);
        this.addressForm.patchValue(this.taxDetails.address);
        this.checkGstRegistrationStatus();
      })
    })




    if (this.gstRegistationStatus == 'not_registered') {
      this.taxDetailsForm['controls']['gstin'].setValue(undefined);
      this.taxDetailsForm.controls['place_of_supply'].markAsUntouched();
      this.taxDetailsForm.controls['place_of_supply'].markAsPristine();
    }


    //prefill logic
    this.taxDetailsForm.controls['gstin'].valueChanges.subscribe((value) => {
      if (this.taxDetailsForm.controls['gstin'].valid) {

        if (this.validateNameAndAddress() == false) {
          this.prefillLoading = true;
          this.store.dispatch(
            TaxDetailsActions.PREFILL_TAX_DETAILS({
              gstin: value,
            })
          );
        }
      }
    });

    this.actions$
      .pipe(ofType(TaxDetailsActions.PREFILL_TAX_DETAILS_SUCCESS))
      .subscribe(({ taxDetails }) => {
        this.prefillLoading = false;
        this.taxDetailsForm.controls['legal_name'].patchValue(taxDetails.legal_name);
        this.addressForm.patchValue(taxDetails.address);
      });

    this.actions$
      .pipe(ofType(TaxDetailsActions.PREFILL_TAX_DETAILS_ERROR))
      .subscribe(() => {
        this.prefillLoading = false;
      });

  }


  validateNameAndAddress(): boolean {
    return (
      this.taxDetailsForm['controls']['legal_name'].valid &&
      this.taxDetailsForm['controls']['gstin'].valid &&
      this.addressForm.valid
    );
  }

  checkGstRegistrationStatus() {
    if (this.taxDetails?.gstin != undefined) {
      this.gstRegistationStatus = 'registered';
    } else {
      this.gstRegistationStatus = 'not_registered';
    }
  }


  showError() {
    this.taxDetailsForm.markAsTouched();
    this.taxDetailsForm.controls['gstin'].markAsTouched();
    this.taxDetailsForm.controls['legal_name'].markAsTouched();
    this.taxDetailsForm.controls['place_of_supply'].markAsTouched();
    this.addressForm.markAllAsTouched();
  }

  validate() {
    if (this.gstRegistationStatus == 'not_registered') {
      return (
        this.addressForm.valid
      );
    } else {
      return (
        this.taxDetailsForm['controls']['legal_name'].valid &&
        this.taxDetailsForm['controls']['gstin'].valid &&
        this.taxDetailsForm['controls']['place_of_supply'].valid &&
        this.addressForm.valid
      );
    }
  }

  save(): void {
    console.log(this.taxDetailsForm)
    this.savingForm = true;

    if (this.gstRegistationStatus == 'not_registered') {
      this.taxDetailsForm!.controls['legal_name'].setValue(undefined);
      this.taxDetailsForm!.controls['gstin'].setValue(undefined);
    }

    this.taxDetailsForm!.controls['address'].setValue(this.addressForm.value);
    const taxDetailsInstance = plainToInstance(TaxDetails, this.taxDetailsForm.value)
    taxDetailsInstance.gstin = taxDetailsInstance?.gstin?.toUpperCase();
    taxDetailsInstance['@entity'] = "com.quicko.myaccount.tax_details"

    console.log(taxDetailsInstance)
    if (this.isUpdate == true) {

      this.store.dispatch(TaxDetailsActions.PATCH_TAX_DETAILS({ taxDetails: taxDetailsInstance }))
      this.actions$.pipe(
        ofType(TaxDetailsActions.PATCH_TAX_DETAILS_SUCCESS),
      ).subscribe(() => {
        this.snackBarService.openSnackBar('Billing details updated successfully', undefined);
        this.savingForm = false;
        this.router.navigate(["../../"], {
          relativeTo: this.route
        });
      }
      )
    } else {
      this.store.dispatch(TaxDetailsActions.POST_TAX_DETAILS({ taxDetails: taxDetailsInstance }))
      this.actions$.pipe(
        ofType(TaxDetailsActions.POST_TAX_DETAILS_SUCCESS),
      ).subscribe(() => {
        this.snackBarService.openSnackBar('Billing details updated successfully', undefined);
        this.savingForm = false;
        this.router.navigate(["../../"], {
          relativeTo: this.route
        });
      }
      )
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
