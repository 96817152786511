import { createSelector } from "@ngrx/store";
import { RootSelectors } from "../app.selectors";
import { AccountantStore } from "./accountant-store.model";


// Selector to return the accountants array from the AccountantStore
export const MyAccountantsSelector = createSelector(
    RootSelectors.ACCOUNTANT_STORE,
    (accountantStore: AccountantStore) => {
        return accountantStore?.my_accountants_store
    }
);


export const IncomingInvitationsSelector = createSelector(
    RootSelectors.ACCOUNTANT_STORE,
    (accountantStore: AccountantStore) => {
        return accountantStore?.incoming_invitations_store
    }
);


export const OutgoingInvitationsSelector = createSelector(
    RootSelectors.ACCOUNTANT_STORE,
    (accountantStore: AccountantStore) => {
        return accountantStore?.outgoing_invitations_store
    }
);
