import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from 'ngx-avatars';
import { InvitationRow } from 'ngx-com-quicko-myaccount';

@Component({
  selector: 'app-invitation-row',
  standalone: true,
  imports: [AvatarModule, MatIconModule, CommonModule, MatButtonModule],
  templateUrl: './invitation-row.component.html',
  styleUrls: ['./invitation-row.component.css']
})
export class InvitationRowComponent implements OnInit {

  invitationExpiry: Date = new Date();
  hasName: boolean = true;
  @Input() invitationRow: InvitationRow | undefined;
  @Output() reject: EventEmitter<InvitationRow> = new EventEmitter<InvitationRow>()
  @Output() accept: EventEmitter<InvitationRow> = new EventEmitter<InvitationRow>()
  ngOnInit() {
    this.invitationExpiry = new Date(this.invitationRow?.getInvitationExpiry()! * 1000);
    if (this.invitationRow.getFirstName() == undefined && this.invitationRow.getLastName() == undefined) {
      // console.log(this.invitationRow);
      this.hasName = false;
    }
    console.log(this.hasName)
    console.log(this.invitationRow.getEmail())
    console.log(this.invitationRow?.getEmail().trim()[0].toUpperCase())
  }

  rejectInvitation() {
    // this.showRive = true
    this.reject.emit(this.invitationRow);
  }

  acceptInvitation() {
    // this.showRive = true
    this.accept.emit(this.invitationRow);
  }

}
