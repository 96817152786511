import { prop } from "@rxweb/reactive-form-validators";
import { BaseObject } from "./org-quicko-core/beans/BaseObject";

export class App extends BaseObject {

    @prop()
    logo?: string;

    @prop()
    policyUri?: string;

    @prop()
    redirectUri?: string;

    @prop()
    appName?: string;

    @prop()
    app_key?: string;

    @prop()
    status?: string;

    @prop()
    postbackUri?: string;
}