<a routerLink="../list">
  <mat-icon class="material-symbols-outlined text-text-primary w-8 h-8 text-[32px]">arrow_circle_left</mat-icon></a>
<div *ngIf="!isLoading" class="container flex flex-col gap-8">
  <div class="upper-conatiner flex flex-col gap-8">
    <div class="flex flex-row justify-between h-[48px]">
      <div class="flex flex-row gap-4">
        <div class="flex flex-col items-center justify-center">
          <img [src]="app.logo" class="w-[44px] h-[44px]" alt="Logo" />
        </div>
        <div class="flex flex-col">
          <div class="text-lg font-semibold leading-7 text-left">{{app.app_name}}</div>
          <div class="text-xs font-medium leading-4 text-left">
            {{app.business_name}}
          </div>
        </div>
      </div>
      <button mat-stroked-button class="text-base font-medium text-nowrap rounded px-6 py-6" (click)="deleteApp()">
        Remove Access
      </button>
    </div>
    <div class="flex flex-col gap-8">
      <div class="flex flex-row gap-6">
        <p class="text-base font-medium leading-5 text-left">Access given on:</p>
        <p class="text-base font-medium leading-5 text-left">{{date}}</p>
      </div>
      <div class="flex flex-row gap-6">
        <p class="text-base font-medium leading-5 text-left">Access given to:</p>
        <div class="flex flex-col">
          <div *ngFor="let uri of app.uri" class="text-base font-medium leading-5 mt-1 text-left flex flex-col">
            {{uri | host}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lower-conatiner flex flex-col gap-4">
    <div class="text-base font-semibold leading-6 text-left">
      Access that you have given to {{app.app_name}}
    </div>
    <div class="flex flex-col border border-outline-variant rounded max-w-[802px]">
      <div class="flex flex-col gap-2 p-4 border border-outline-variant " routerLink="personal-details/edit">
        <p class="text-sm font-medium leading-5 text-left text-text-secondary">
          Personal details
        </p>
        <p class="text-sm font-medium leading-5 text-left text-text-primary">
          View your basic profile information such as name, email, etc.
        </p>
      </div>
      <div class="flex flex-col gap-2 p-4 border border-outline-variant" routerLink="personal-details/edit">
        <p class="text-sm font-medium leading-5 text-text-secondary">
          Tax Profile
        </p>
        <p class="text-sm font-medium leading-5 text-text-primary">
          View your tax profile details such as taxpayer category, PAN, etc.
        </p>
      </div>
      <div class="flex flex-col gap-2 p-4 border border-outline-variant" routerLink="personal-details/edit">
        <p class="text-sm font-medium leading-5 text-text-secondary">
          Income Tax Returns
        </p>
        <p class="text-sm font-medium leading-5 text-text-primary">
          View your tax filing information such as income situations, tax computation, status etc.
        </p>
      </div>
    </div>
    <p class="text-xs font-medium leading-[18px] text-left">
      To learn more about how {{app.app_name}} uses your information, view their
      <a [href]="app.policy_uri" class="primary">Privacy Policy</a>
    </p>
  </div>
</div>
<div *ngIf="isLoading">
  <div class="container flex flex-col gap-8">
    <div class="upper-conatiner flex flex-col gap-8">
      <div class="flex flex-row justify-between h-[48px]">
        <div class="flex flex-row gap-4">
          <div class="flex flex-col items-center justify-center">
            <img [src]="app.logo" class="w-[44px] h-[44px]" alt="Logo" />
          </div>
          <div class="flex flex-col">
            <div class="text-lg font-semibold leading-7 text-left">{{app.app_name}}</div>
            <div class="text-xs font-medium leading-4 text-left">
              {{app.business_name}}
            </div>
          </div>
        </div>
        <button class="text-base font-medium primary border border-stroke rounded grey-300 w-36 h-12"
          (click)="deleteApp()">
          Remove Access
        </button>
      </div>
      <div class="flex flex-row gap-8">
        <div class="flex flex-col">
          <p class="text-xs font-medium leading-5 text-left">Access given on</p>
          <p class="text-base font-medium leading-6 text-left">{{date}}</p>
        </div>
        <div class="flex flex-col">
          <p class="text-xs font-medium leading-5 text-left">Web address</p>
          <a [href]="app.redirect_uri" class="text-base font-medium leading-6 text-left">
            {{app.uri}}
          </a>
        </div>
      </div>
    </div>
    <div class="lower-conatiner flex flex-col gap-4">
      <div class="text-base font-semibold leading-6 text-left">
        Access that you have given to {{app.app_name}}
      </div>
      <div class="flex flex-col border border-stroke rounded grey max-w-[802px]">
        <div class="flex flex-col gap-2 p-4 " routerLink="personal-details/edit">
          <p class="text-sm font-medium leading-5 text-left text-text-secondary">
            Personal details
          </p>
          <p class="text-sm font-medium leading-5 text-left text-text-primary">
            View your basic profile information such as name, email, etc.
          </p>
        </div>
        <div class="flex flex-col gap-2 p-4 " routerLink="personal-details/edit">
          <p class="text-sm font-medium leading-5 text-text-secondary">
            Tax Profile
          </p>
          <p class="text-sm font-medium leading-5 text-text-primary">
            View your tax profile details such as taxpayer category, PAN, etc.
          </p>
        </div>
        <div class="flex flex-col gap-2 p-4 " routerLink="personal-details/edit">
          <p class="text-sm font-medium leading-5 text-text-secondary">
            Income Tax Returns
          </p>
          <p class="text-sm font-medium leading-5 text-text-primary">
            View your tax filing information such as status, deadlines, documents
            etc.
          </p>
        </div>
        <div class="flex flex-col gap-2 p-4 " routerLink="personal-details/edit">
          <p class="text-sm font-medium leading-5 text-text-secondary">
            Plan details
          </p>
          <p class="text-sm font-medium leading-5 text-text-primary">
            View your current subscription details
          </p>
        </div>
      </div>
      <p class="text-xs font-medium leading-[18px] text-left">
        To learn more about how {{app.app_name}} uses your information, view their
        <a [href]="app.policy_uri" class="primary">Privacy Policy</a>
      </p>
    </div>
  </div>
</div>