<ng-container *ngIf="!updateInvitation; else editInvitation">
  <div class="flex flex-row p-6 min-w-[690px] min-h-[468px]">

    <div class="flex flex-col w-full">

      <div class="flex flex-row justify-between">
        <div class="flex flex-col gap-x-1">
          <p class="mat-subtitle-2" style="font-weight:600;">Invite Accountant</p>
          <p class="mat-body-1 mt-1 mb-4 text-text-secondary">Grant access control of your profile to your accountant
          </p>
        </div>
        <mat-icon class="h-[28px] w-[28px] material-symbols-outlined cursor-pointer" (click)="close()">
          cancel
        </mat-icon>
      </div>
      <div [formGroup]="invitationForm" class="flex flex-col gap-y-2 mt-4">
        <div class="flex flex-row gap-x-4 w-full">
          <mat-form-field appearance="outline" class="form-control flex-1">
            <mat-label class="mat-body-1 fw-500">First Name</mat-label>
            <input type="text" matInput formControlName="inviteeFirstName" placeholder="Eg: John" required>
            <mat-error>
              <ng-container
                *ngIf="invitationForm['controls']['inviteeFirstName'].errors && (invitationForm['controls']['inviteeFirstName'].dirty || invitationForm['controls']['inviteeFirstName'].touched)">
                <ng-container *ngFor="let error of invitationForm['controls']['inviteeFirstName'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-control flex-1">
            <mat-label class="mat-body-1 fw-500">Last Name</mat-label>
            <input type="text" matInput formControlName="inviteeLastName" placeholder="Eg: Doe" required>
            <mat-error>
              <ng-container
                *ngIf="invitationForm['controls']['inviteeLastName'].errors && (invitationForm['controls']['inviteeLastName'].dirty || invitationForm['controls']['inviteeLastName'].touched)">
                <ng-container *ngFor="let error of invitationForm['controls']['inviteeLastName'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="form-control w-full">
          <mat-label class="mat-body-1 fw-500">Email Address</mat-label>
          <input type="email" matInput formControlName="inviteeEmail" placeholder="Eg: ashok@gmail.com" required>
          <mat-error>
            <ng-container
              *ngIf="invitationForm['controls']['inviteeEmail'].errors && (invitationForm['controls']['inviteeEmail'].dirty || invitationForm['controls']['inviteeEmail'].touched)">
              <ng-container *ngFor="let error of invitationForm['controls']['inviteeEmail'].errors | keyvalue">
                <ng-container *ngIf="error.key">
                  <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>

        <div class="flex flex-row">

          <mat-form-field appearance="outline" class="form-control w-full">
            <mat-label class="mat-body-1 fw-500">Access</mat-label>

            <mat-select formControlName="accessValidity" class="form-control">
              <mat-option class="mat-caption fw-500" *ngFor="let option of options" class="fw-500" [value]="option">{{
                option.expiry }}</mat-option>
            </mat-select>

          </mat-form-field>
        </div>
      </div>
      <div class="mat-body-2 text-text-secondary mt-[-8px]">
        <ng-container *ngIf="this.invitationForm.get('accessValidity')?.value.access == -1; else showExpiryTime">
          <p>They would have access to your profile till you revoke it manually</p>
        </ng-container>
        <ng-template #showExpiryTime>
          <p>They would have access to your profile for
            {{this.invitationForm.get('accessValidity')?.value.expiry}}</p>
        </ng-template>
      </div>

      <div>
        <ng-container *ngIf="!savingForm; else loading">
          <button mat-flat-button class="rounded mat-body-1 mt-4 px-6 py-6 mb-[0px]"
            (click)="validate()? save(): showError()">
            Send Invite
          </button>

        </ng-container>
        <ng-template #loading>
          <!-- <button-loader></button-loader> -->
        </ng-template>
      </div>

    </div>


  </div>
</ng-container>

<ng-template #editInvitation>
  <ng-container>
    <div class="flex flex-row p-6 w-[690px] h-[468px]">

      <div class="flex flex-col w-full">

        <div class="flex flex-row justify-between">
          <div class="flex flex-col gap-x-1">
            <p class="mat-subtitle-2" style="font-weight:600;">Edit Invitation</p>
            <p class="mat-body-1 mt-1 mb-4 text-text-secondary">Edit access control of your profile to your accountant
            </p>
          </div>
          <mat-icon class="h-[28px] w-[28px] material-symbols-outlined cursor-pointer" (click)="close()">
            cancel
          </mat-icon>
        </div>

        <div [formGroup]="invitationForm" class="flex flex-col gap-y-2 mt-4">


          <div class="flex flex-row gap-x-4 w-full">
            <mat-form-field appearance="outline" class="form-control flex-1">
              <mat-label class="mat-body-1 fw-500">First Name</mat-label>
              <input matInput disabled="true" [value]="invitationForm.controls['inviteeFirstName'].value" required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-control flex-1">
              <mat-label class="mat-body-1 fw-500">Last Name</mat-label>
              <input matInput disabled="true" [value]="invitationForm.controls['inviteeLastName'].value" required>
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" class="form-control w-full">
            <mat-label class="mat-body-1 fw-500">Email Address</mat-label>
            <input type="email" matInput disabled="true" [value]="invitationForm.controls['inviteeEmail'].value">
          </mat-form-field>


          <div class="flex flex-row">

            <mat-form-field appearance="outline" class="form-control w-full">
              <mat-label class="mat-body-1 fw-500">Access</mat-label>

              <mat-select formControlName="accessValidity" class="form-control">
                <mat-option class="mat-caption fw-500" *ngFor="let option of options" class="fw-500" [value]="option">{{
                  option.expiry }}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>
        </div>
        <div class="mat-body-2 text-text-secondary mt-[-8px]">
          <ng-container *ngIf="this.invitationForm.get('accessValidity')?.value.access == -1; else showExpiryTime">
            <p>They would have access to your profile till you revoke it manually</p>
          </ng-container>
          <ng-template #showExpiryTime>
            <p>They would have access to your profile for
              {{this.invitationForm.get('accessValidity')?.value.expiry}}</p>
          </ng-template>
        </div>

        <div>
          <ng-container *ngIf="!savingForm; else loading">
            <button mat-flat-button class="rounded mat-body-1 mt-4 px-6 py-6 mb-[0px]"
              (click)="validate()? save(): showError()">
              Update Invite
            </button>

          </ng-container>
          <ng-template #loading>
            <!-- <button-loader></button-loader> -->
          </ng-template>
        </div>


      </div>
    </div>
  </ng-container>

</ng-template>