<section id="delete-account" class="p-6">
  <div class="flex flex-row justify-between">
    <h2 class="text-xl font-semibold text-text-primary">Delete App</h2>
    <mat-icon class="material-symbols-outlined cursor-pointer w-8 h-8 text-[32px] text-blue-grey-100"
      [mat-dialog-close]>cancel</mat-icon>
  </div>

  <div class="flex flex-col">
    <p class="text-base font-medium text-text-secondary mt-2">
      Are you sure you want to remove this App?
    </p>
  </div>

  <div class="flex flex-row mt-6 gap-4">
    <ng-container *ngIf="!saveFormLoading else loading">
      <button mat-flat-button color="warn" class="text-base font-medium rounded w-36 h-12" (click)="proceed()">
        Proceed
      </button>
    </ng-container>
    <ng-template #loading>
      <button-loader [width]="'144'" [color]="'var(--sys-error)'"></button-loader>
    </ng-template>
    <button mat-stroked-button color="warn" class="text-base rounded font-medium w-36 h-12" [mat-dialog-close]>
      Cancel
    </button>
  </div>
</section>