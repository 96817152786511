import { createSelector } from "@ngrx/store";
import { IncomingInvitationsSelector } from "../accountant-store.selectors";
import { IncomingInvitationsModel } from "./incoming-invitations-store.model";


// Selector to return the incoming invitations array from the IncomingInvitationsModel
export const getIncomingInvitations = () => createSelector(
    IncomingInvitationsSelector,
    (incomingInvitationsStore: IncomingInvitationsModel) => incomingInvitationsStore?.incoming_invitations
);

// Selector to return the last evaluated key value from the IncomingInvitationsModel
export const getIncomingInvitationsLastEvaluatedKey = () => createSelector(
    IncomingInvitationsSelector,
    (incomingInvitationsStore: IncomingInvitationsModel) => incomingInvitationsStore?.last_evaluated_key
);