import { JSONArray } from '../org-quicko-core/beans/JSONObject'

enum AccountantRowHeaders {
    ID = 0,
    EMAIL_ID = 1,
    FIRST_NAME = 2,
    LAST_NAME = 3,
    EXPIRY_AT = 4,
    LAST_ACCESSED_AT = 5,
    CREATED_AT = 6,
    UPDATED_AT = 7
}

export class AccountantRow extends JSONArray {
    constructor(row: JSONArray) {
        super(row)
    }

    getId(): string {
        return String(this[AccountantRowHeaders.ID])
    }
    getEmail(): string {
        return String(this[AccountantRowHeaders.EMAIL_ID])
    }
    getFirstName(): string {
        return this[AccountantRowHeaders.FIRST_NAME] ? String(this[AccountantRowHeaders.FIRST_NAME]) : null;
    }
    getLastName(): string {
        return this[AccountantRowHeaders.LAST_NAME] ? String(this[AccountantRowHeaders.LAST_NAME]) : null;
    }
    getExpiryAt(): number {
        return Number(this[AccountantRowHeaders.EXPIRY_AT])
    }
    getLastAccessedAt(): number {
        return Number(this[AccountantRowHeaders.LAST_ACCESSED_AT])
    }
    getCreatedAt(): number {
        return Number(this[AccountantRowHeaders.CREATED_AT])
    }
    getUpdatedAt(): number {
        return Number(this[AccountantRowHeaders.UPDATED_AT])
    }
}
