import { createAction, props } from "@ngrx/store";
import { AccountantRow } from "../../../beans";
import { Accountant } from "../../../beans/Accountant";
import { v4 as uuidv4 } from "uuid";

export const MyAccountantsActions = {

    GET_ACCOUNTANT: createAction('[My Accountants] GET Accountant ' + uuidv4(), props<{ accountantId: string }>()),
    GET_ACCOUNTANT_SUCCESS: createAction('[My Accountants] GET Accountant Success ' + uuidv4(), props<{ accountant: Accountant }>()),
    GET_ACCOUNTANT_ERROR: createAction('[My Accountants] GET Accountant Error ' + uuidv4(), props<{ err: any }>()),

    GET_ACCOUNTANTS: createAction('[My Accountants] GET Accountants ' + uuidv4(), props<{ min?: number, max?: number }>()),
    GET_ACCOUNTANTS_SUCCESS: createAction('[My Accountants] GET Accountants Success ' + uuidv4(), props<{ accountantRows: AccountantRow[], min?: number, max?: number }>()),
    GET_ACCOUNTANTS_ERROR: createAction('[My Accountants] GET Accountants Error ' + uuidv4(), props<{ err: any }>()),

    UPDATE_ACCOUNTANT: createAction('[My Accountants] UPDATE Accountant ' + uuidv4(), props<{ accountantId: string, expiryAt: number }>()),
    UPDATE_ACCOUNTANT_SUCCESS: createAction('[My Accountants] UPDATE Accountant Success ' + uuidv4()),
    UPDATE_ACCOUNTANT_ERROR: createAction('[My Accountants] UPDATE Accountant Error ' + uuidv4(), props<{ err: any }>()),

    DELETE_ACCOUNTANT: createAction('[My Accountants] DELETE Accountant ' + uuidv4(), props<{ accountantId: string }>()),
    DELETE_ACCOUNTANT_SUCCESS: createAction('[My Accountants] DELETE Accountant Success ' + uuidv4(), props<{ accountantId: string }>()),
    DELETE_ACCOUNTANT_ERROR: createAction('[My Accountants] DELETE Accountant Error ' + uuidv4(), props<{ err: any }>()),
}
