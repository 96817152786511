import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-delete-dialog',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteDialogComponent>) {
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit() {
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onConfirm() {
    this.dialogRef.close({ delete: true })
  }

  onCancel() {
    this.dialogRef.close({ delete: false })
  }

}
