import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { plainToClass } from 'class-transformer';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccountantRow, AccountantTable } from '../../../beans';
import { JSONObject } from '../../../beans/org-quicko-core/beans/JSONObject';
import { Sheet } from '../../../beans/org-quicko-sheet/beans/Sheet';
import { Workbook } from '../../../beans/org-quicko-sheet/beans/Workbook';
import { SnackbarService } from '../../../services';
import { AccountantService } from '../../../services/accountant.service';
import { MyAccountantsActions } from './my-accountants-store.actions';

@Injectable()
export class MyAccountantsEffects {

    constructor(
        private actions$: Actions,
        private accountantService: AccountantService,
        private snackBarService: SnackbarService,
        private store: Store
    ) { }

    getAccountants$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyAccountantsActions.GET_ACCOUNTANTS),
            switchMap((action) =>
                this.accountantService.getAccountants(action.max, action.min).pipe(
                    map(
                        (response) => {
                            const workbookData = response?.data as JSONObject;
                            const accountantWorkbook: Workbook = plainToClass(Workbook, workbookData)
                            const accountantSheet: Sheet = accountantWorkbook.getSheet('com.quicko.accountant.accountant_sheet');
                            const accountantTable: AccountantTable = plainToClass(AccountantTable, accountantSheet.getBlock('com.quicko.accountant.accountant_table'));
                            const accountantRows: AccountantRow[] = accountantTable.getAccountantRows();

                            const metadata = accountantSheet.getMetadata();
                            const min: number = metadata['min'];
                            const max: number = metadata['max'];
                            return MyAccountantsActions.GET_ACCOUNTANTS_SUCCESS({ accountantRows, min, max });
                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to get accounants', undefined);
                        this.store.dispatch(
                            MyAccountantsActions.GET_ACCOUNTANTS_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                ),
            )
        )
    )

    updateAccountant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyAccountantsActions.UPDATE_ACCOUNTANT),
            switchMap((action) =>
                this.accountantService.patchAccountant(action.accountantId, action.expiryAt).pipe(
                    map(
                        (response) => {
                            this.snackBarService.openSnackBar("Accountant updated successfully", undefined);
                            return MyAccountantsActions.UPDATE_ACCOUNTANT_SUCCESS();
                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to update accountant', undefined);
                        this.store.dispatch(
                            MyAccountantsActions.UPDATE_ACCOUNTANT_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                )

            )
        )
    )

    deleteAccountant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyAccountantsActions.DELETE_ACCOUNTANT),
            switchMap((action) =>
                this.accountantService.deleteAccountant(action.accountantId).pipe(
                    map(
                        (response) => {
                            this.snackBarService.openSnackBar("Accountant deleted successfully", undefined);
                            return MyAccountantsActions.DELETE_ACCOUNTANT_SUCCESS({ accountantId: action.accountantId })
                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to delete accountant', undefined);
                        this.store.dispatch(
                            MyAccountantsActions.DELETE_ACCOUNTANT_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                )
            )
        )
    )

}