
import { JSONArray } from "../../org-quicko-core/beans/JSONObject"
import { Block } from "./Block";

export class List extends Block {

    "@entity" = 'list'

    items: Array<any>


    constructor(name: string, metadata: any, items: Array<any>) {
        super(name, metadata)

        this.items = items
    }

    getItems(): JSONArray {
        if (this.items == null) {
            this.items = new JSONArray();
        }

        return this.items;
    }

    getItem(index: number): JSONArray | null {
        if (index < this.getItems().length) {
            return new JSONArray(this.getItems()[index] as JSONArray);
        }
        return null
    }

    getParticulars(): string | null {
        const item = this.getItem(0);
        if (item && typeof item[0] === 'string') {
            return item[0];
        }
        return null;
    }

    getValue(): number | null {
        const item = this.getItem(1);
        if (item && typeof item[1] === 'number') {
            return item[1];
        }
        return null;
    }


}