import { JSONArray } from '../org-quicko-core/beans/JSONObject'
import { InvitationRow } from './InvitationRow'
import { Table } from '../org-quicko-sheet/beans/Table'
import 'reflect-metadata'

@Reflect.metadata('name', 'com.quicko.accountant.invitation_table')
export class InvitationTable extends Table {
    private static header: JSONArray = Array.from([
        'id',
        'email',
        'first_name',
        'last_name',
        'invited_at',
        'invitation_expiry',
    ])

    constructor(name: string, metadata: any, header: Array<any>, rows: Array<any>) {
        super(name, metadata, header, rows)
        super.setEntity('table')
        super.setName('com.quicko.accountant.invitation_table')
        super.setHeader(InvitationTable.header)
    }

    getInvitationRows() {
        return super.getRows().map((row) => new InvitationRow(row as JSONArray))
    }
}