
import { JSONArray } from "../../org-quicko-core/beans/JSONObject"
import { Block } from "./Block"
import { Column } from "./Column"

export class Table extends Block {

    "@entity"?: string = "table"

    override header: Array<any>

    override rows: Array<any>

    constructor(name: string, metadata: any, header: Array<any>, rows?: Array<any>) {
        super(name, metadata)

        this.header = header
        this.rows = rows !== undefined ? rows : new JSONArray();

    }

    getHeader(): JSONArray | undefined {
        return this.header;
    }

    setHeader(header: JSONArray) {
        this.header = header;
    }

    getRows(): JSONArray {
        if (this.rows == null) {
            this.rows = new JSONArray();
        }

        return this.rows;
    }

    setRows(rows: JSONArray) {
        this.rows = rows;
    }

    getRow(index: number): JSONArray | null {
        if (index < this.getRows().length) {
            return new JSONArray(this.getRows()[index] as JSONArray);
        }
        return null
    }

    addRow(row: JSONArray) {
        this.getRows().push(row);
    }

    replaceRow(index: number, row: JSONArray) {
        this.getRows().splice(index, 0, row);
    }

    removeRow(index: number) {

        for (let i = 0; i < this.getRows().length; i++) {
            if (index == i) {
                this.getRows().splice(i, 1);
            }
        }

    }

    length() {
        return this.getRows().length;
    }

}