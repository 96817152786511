import { JSONArray } from '../org-quicko-core/beans/JSONObject'

enum InvitationRowHeaders {
    ID = 0,
    EMAIL_ID = 1,
    FIRST_NAME = 2,
    LAST_NAME = 3,
    INVITED_AT = 4,
    INVITATION_EXPIRY = 5
}

export class InvitationRow extends JSONArray {
    constructor(row: JSONArray) {
        super(row)
    }

    getId(): string {
        return String(this[InvitationRowHeaders.ID])
    }
    getEmail(): string {
        return String(this[InvitationRowHeaders.EMAIL_ID])
    }
    getFirstName(): string {
        return this[InvitationRowHeaders.FIRST_NAME] ? String(this[InvitationRowHeaders.FIRST_NAME]) : null;
    }
    getLastName(): string {
        return this[InvitationRowHeaders.LAST_NAME] ? String(this[InvitationRowHeaders.LAST_NAME]) : null;
    }
    getInvitedAt(): number {
        return Number(this[InvitationRowHeaders.INVITED_AT])
    }
    getInvitationExpiry(): number {
        return Number(this[InvitationRowHeaders.INVITATION_EXPIRY])
    }
}