import { ValidationErrors, AbstractControl, Validator, ValidatorFn } from '@angular/forms';

export class AutocompleteValidator implements Validator {

    validate(control: AbstractControl): ValidationErrors {
        return {};
    }

    static requireMatch(array: any[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: string } | null => {

            let selectedValue = control.value;

            if (selectedValue == undefined || selectedValue == null || selectedValue == '') { return null; }

            let matches = array.filter(item => item.name.toUpperCase() == selectedValue.toUpperCase());

            if (matches.length > 0) {
                return null;
            }
            else {
                return { 'incorrectMatch': 'Please select a valid option' };
            }
        }
    }
}