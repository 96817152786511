<div class="flex flex-col gap-[16px]">

  <div class="flex flex-row justify-between items-center">
    <div class="flex flex-col w-[659px]">
      <p class="mat-headline-6 text-text-primary pb-[6px] m-0">My Accountants</p>
      <p class="mat-subtitle-2 fw-500 text-text-secondary on-surface-variant">Manage accountants who have access to your
        profile
      </p>
    </div>

    <div>
      <button mat-stroked-button class="border border-stroke mat-body-1 px-6 py-6 m-0 rounded-md"
        (click)="inviteAccountant()">
        <mat-icon class="w-[20px] h-[20px] material-symbols-outlined">person_add</mat-icon>
        <p class="mat-body-1 mb-0 whitespace-nowrap">Invite Accountant</p>
      </button>
    </div>

  </div>

  <div class="flex flex-row justify-between items-center border-b-[1px] border-outline-variant">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a mat-tab-link [active]="isTabActive('/accountants')" [routerLink]="['/accountants']" routerLinkActive=""
        #myAccountants="routerLinkActive" class="items-center px-[32px] py-[24px] h-[72px] ">
        <p class="mat-body-1 mr-2"
          [ngClass]="{'primary': isTabActive('/accountants'), 'on-surface': !isTabActive('/accountants') }">
          Accountants
        </p>
      </a>

      <a (click)="updateClickedOnce()" mat-tab-link [routerLink]="['/accountants/invitations']"
        [active]="isTabActive('/accountants/invitations')" routerLinkActive="" #invitations="routerLinkActive"
        class="items-center px-[32px] py-[24px] h-[72px]">
        <p class="mat-body-1 mr-2"
          [ngClass]="{'primary': isTabActive('/accountants/invitations'), 'on-surface': !isTabActive('/accountants/invitations') }">
          Incoming Invitations
        </p>
        <div *ngIf="incomingInvitationsExist==true && clickedOnce==false">
          <div class="ml-2 rounded-full w-[6px] h-[6px] bg-primary"></div>
        </div>
      </a>
    </nav>
  </div>

  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>

</div>