import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { instanceToPlain, plainToClass } from 'class-transformer';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Invitation, InvitationRow, InvitationTable } from '../../../beans';
import { JSONObject } from '../../../beans/org-quicko-core/beans/JSONObject';
import { Sheet } from '../../../beans/org-quicko-sheet/beans/Sheet';
import { Workbook } from '../../../beans/org-quicko-sheet/beans/Workbook';
import { SnackbarService } from '../../../services';
import { InvitationService } from '../../../services/invitation.service';
import { OutgoingInvitationsActions } from './outgoing-invitations-store.actions';

@Injectable()
export class OutgoingInvitationsEffects {

    constructor(
        private actions$: Actions,
        private invitationService: InvitationService,
        private snackBarService: SnackbarService,
        private store: Store
    ) { }

    getOutgoingInvitations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OutgoingInvitationsActions.GET_INVITATIONS),
            switchMap((action) =>
                this.invitationService.getOutgoingInvitations(action.lastEvaluatedKey, action.limit, action.sort).pipe(
                    map(
                        (response) => {
                            const workbookData = response?.data as JSONObject;
                            const outgoingInvitationWorkbook: Workbook = plainToClass(Workbook, workbookData)
                            const outgoingInvitationSheet: Sheet = outgoingInvitationWorkbook.getSheet('com.quicko.accountant.invitation_to_accountant_sheet');
                            const invitationTable: InvitationTable = plainToClass(InvitationTable, outgoingInvitationSheet.getBlock('com.quicko.accountant.invitation_table'));
                            const invitationRows: InvitationRow[] = invitationTable.getInvitationRows();

                            const metadata = outgoingInvitationSheet.getMetadata();
                            const lastEvaluatedKey: string = metadata['last_evaluated_key'];
                            return OutgoingInvitationsActions.GET_INVITATIONS_SUCCESS({ invitationRows, lastEvaluatedKey });
                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to get invitations sent', undefined);
                        this.store.dispatch(
                            OutgoingInvitationsActions.GET_INVITATIONS_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                ),
            )
        )
    )

    sendInvitation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OutgoingInvitationsActions.POST_INVITATION),
            switchMap((action) =>
                this.invitationService.postInvitation(instanceToPlain(action.invitation)).pipe(
                    map(
                        (response) => {
                            const invitation: Invitation = response?.data!;
                            return OutgoingInvitationsActions.POST_INVITATION_SUCCESS({ invitation });

                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to send invitation', undefined);
                        this.store.dispatch(
                            OutgoingInvitationsActions.POST_INVITATION_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                ),
            )
        )
    )


    updateInvitation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OutgoingInvitationsActions.UPDATE_INVITATION),
            switchMap((action) =>
                this.invitationService.updateInvitation(instanceToPlain(action.invitation)).pipe(
                    map(
                        (response) => {
                            const invitation: Invitation = response?.data!;
                            return OutgoingInvitationsActions.UPDATE_INVITATION_SUCCESS({ invitation });

                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to udpate invitation', undefined);
                        this.store.dispatch(
                            OutgoingInvitationsActions.UPDATE_INVITATION_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                ),
            )
        )
    )


    deleteInvitation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OutgoingInvitationsActions.DELETE_INVITATION),
            switchMap((action) =>
                this.invitationService.deleteInvitation(action.accountantId).pipe(
                    map(
                        (response) => {
                            this.snackBarService.openSnackBar('Invitation deleted successfully', undefined);
                            return OutgoingInvitationsActions.DELETE_INVITATION_SUCCESS({ accountantId: action.accountantId });
                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to delete invitation', undefined);
                        this.store.dispatch(
                            OutgoingInvitationsActions.DELETE_INVITATION_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                ),
            )
        )
    )

}