import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvitationRow } from 'ngx-com-quicko-myaccount';
import { AvatarModule } from 'ngx-avatars';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-outgoing-invitation-row',
  standalone: true,
  imports: [AvatarModule, MatButtonModule, MatIconModule, MatMenuModule, MatDividerModule],
  templateUrl: './outgoing-invitation-row.component.html',
  styleUrls: ['./outgoing-invitation-row.component.css']
})
export class OutgoingInvitationRowComponent implements OnInit {

  @Input() invitationRow: InvitationRow | undefined;
  @Output() edit: EventEmitter<InvitationRow> = new EventEmitter<InvitationRow>()
  @Output() delete: EventEmitter<InvitationRow> = new EventEmitter<InvitationRow>()
  ngOnInit() {
  }

  deleteInvitation() {
    // this.showRive = true
    this.delete.emit(this.invitationRow);
  }

  editInvitation() {
    this.edit.emit(this.invitationRow);
  }

}
