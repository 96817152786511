import { createAction, props } from '@ngrx/store';
import { TaxDetails } from '../../../beans';

export const TaxDetailsActions = {
    GET_TAX_DETAILS: createAction('[TaxDetails] GET Tax Details'),
    GET_TAX_DETAILS_SUCCESS: createAction('[TaxDetails] GET Tax Details Success', props<{ taxDetails: TaxDetails }>()),
    GET_TAX_DETAILS_ERROR: createAction('[TaxDetails] GET Tax Details Error', props<{ err: any }>()),

    POST_TAX_DETAILS: createAction('[TaxDetails] POST Tax Details', props<{ taxDetails: TaxDetails }>()),
    POST_TAX_DETAILS_SUCCESS: createAction('[TaxDetails] POST Tax Details Success', props<{ taxDetails: TaxDetails }>()),
    POST_TAX_DETAILS_ERROR: createAction('[TaxDetails] POST Tax Details Error', props<{ err: any }>()),

    PATCH_TAX_DETAILS: createAction('[TaxDetails] PATCH Tax Details', props<{ taxDetails: TaxDetails }>()),
    PATCH_TAX_DETAILS_SUCCESS: createAction('[TaxDetails] PATCH Tax Details Success', props<{ taxDetails: TaxDetails }>()),
    PATCH_TAX_DETAILS_ERROR: createAction('[TaxDetails] PATCH Tax Details Error', props<{ err: any }>()),

    PREFILL_TAX_DETAILS: createAction('[TaxDetails] PREFILL Tax Details', props<{ gstin: string }>()),
    PREFILL_TAX_DETAILS_SUCCESS: createAction('[TaxDetails] PREFILL Tax Details Success', props<{ taxDetails: TaxDetails }>()),
    PREFILL_TAX_DETAILS_ERROR: createAction('[TaxDetails] PREFILL Tax Details Error', props<{ err: any }>()),
}