<mat-divider />
<div class="hover:bg-grey-bg-100">
  <div class="h-[77px] flex flex-row gap-x-4 items-center px-6 py-4">
    <div class="flex flex-row items-center gap-x-4">
      <div class="w-[32px]">
        <ngx-avatars fgColor="#78909C" bgColor="#E0E8FF" size="32"
          name="{{invitationRow?.getFirstName() }} {{invitationRow?.getLastName() }}"></ngx-avatars>
      </div>
      <div class="flex flex-col w-[230px]">
        <span class="mat-body-1 text-text-primary  text-left overflow-hidden truncate mb-[0px]"
          style="font-weight: 600">
          {{invitationRow?.getFirstName() }} {{invitationRow?.getLastName() }}
        </span>
        <div class="mat-body-2 text-text-secondary overflow-hidden truncate">{{ invitationRow?.getEmail()}}</div>
      </div>
    </div>
    <div class="flex flex-row items-center justify-start mat-body-2 text-text-primary gap-x-1 w-[220px]">
      <span class="yellow-dot-background flex justify-center items-center my-auto">
        <span class="yellow-dot"></span>
      </span>
      <span>Pending</span>
    </div>
    <div class="my-auto w-[200px]">
    </div>
    <button class="w-[24px]">
      <!-- <mat-icon class="material-symbols-outlined h-[24px] w-[24px] m-[-6px] alert-error-900">delete</mat-icon> -->
      <mat-icon [mat-menu-trigger-for]="edit"
        class="mr-6 size-[24px] text-text-primary material-symbols-outlined cursor-pointer">
        more_vert
      </mat-icon>
      <mat-menu class="grey-border border-stroke delete mat-elevation-z0 mt-2" #edit="matMenu" xPosition="before">
        <div (click)="editInvitation()" mat-menu-item
          class="cursor-pointer flex flex-row items-center justify-start gap-[12px] my-auto">
          <mat-icon style="height: 24px; width:24px;" class="material-symbols-outlined">
            edit
          </mat-icon>
          <p class="text-text-primary mat-body-2 mb-0">Edit</p>
        </div>
        <mat-divider />
        <div (click)="deleteInvitation()" mat-menu-item
          class="cursor-pointer flex flex-row items-center justify-start gap-[12px] my-auto">
          <mat-icon style="color: #ef5350; height: 24px; width:24px;" class=" material-symbols-outlined">
            delete
          </mat-icon>
          <p class="alert-error-900 mat-body-2 mb-0">Delete</p>
        </div>
      </mat-menu>
    </button>
  </div>
</div>