import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { ReferenceDataService, getStateDetails } from 'ngx-com-quicko-myaccount';
import { FormFieldErrorComponent } from '../../form-field-error/form-field-error.component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-domestic-address-view',
  standalone: true,
  providers: [RxFormBuilder],
  imports: [CommonModule, FormFieldErrorComponent, RouterModule, MatFormFieldModule, RiveModule, MatInputModule, ReactiveFormsModule, MatIconModule, RxReactiveFormsModule, MatSelectModule, RiveModule],
  templateUrl: './domestic-address-view.component.html',
  styleUrls: ['./domestic-address-view.component.css'],
})
export class DomesticAddressViewComponent implements OnInit {
  @Input() addressForm?: FormGroup;
  @Input() disableArea?: boolean;

  states$: Observable<Array<any>>;
  states: Array<any>;

  constructor(private store: Store, private refDataService: ReferenceDataService) {
    this.states$ = this.store.select(getStateDetails())
  }

  ngOnInit() {
    if (this.addressForm.controls['country'].value == null) {
      this.addressForm.controls['country'].setValue('India');
    }

    this.states$.subscribe((res) => {
      this.states = res;
    })
  }
}
