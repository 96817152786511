<div mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger"
    class="cursor-pointer flex items-center justify-center z-[1050] h-full">
    <div class="flex flex-row items-center justify-center">
        <mat-icon class="w-6 h-6 material-symbols-outlined font-light">apps</mat-icon>
    </div>
</div>

<mat-menu #userMenu="matMenu" direction="down" yPosition="below" xPosition="before" [hasBackdrop]=true
    class="mt-[30px]">

    <div class="grid grid-cols-3 justify-center items-center px-2">
        <div mat-menu-item (click)="navigateToSave()"
            class="flex flex-col justify-center items-center gap-y-1 w-22 h-22 p-6">
            <mat-icon class="w-6 h-6 material-symbols-outlined font-light m-0">account_balance_wallet</mat-icon>
            <span class="mat-body-2 text-text-primary">Save</span>
        </div>
        <div mat-menu-item (click)="navigateToPay()"
            class="flex flex-col justify-center items-center gap-y-1 w-22 h-22 p-6">
            <mat-icon class="w-6 h-6 material-symbols-outlined font-light m-0">qr_code_scanner</mat-icon>
            <span class="mat-body-2 text-text-primary">Pay</span>
        </div>
        <div mat-menu-item (click)="navigateToFile()"
            class="flex flex-col justify-center items-center gap-y-1 w-22 h-22 p-6">
            <mat-icon class="w-6 h-6 material-symbols-outlined font-light m-0">lab_profile</mat-icon>
            <span class="mat-body-2 text-text-primary">File</span>
        </div>
        <div mat-menu-item (click)="navigateToInvestments()"
            class="flex flex-col justify-center items-center gap-y-1 w-22 h-22 p-6">
            <mat-icon class="w-6 h-6 material-symbols-outlined font-light m-0">leaderboard</mat-icon>
            <span class="mat-body-2 text-text-primary">Track</span>
        </div>
        <div mat-menu-item (click)="navigateToTools()"
            class="flex flex-col justify-center items-center gap-y-1 w-22 h-22 p-6">
            <mat-icon class="w-6 h-6 material-symbols-outlined font-light m-0">calculate</mat-icon>
            <span class="mat-body-2 text-text-primary">Tools</span>
        </div>
        <div mat-menu-item (click)="navigateToMyAccountAppProfile()"
            class="flex flex-col justify-center items-center gap-y-1 w-22 h-22 p-6">
            <mat-icon class="w-6 h-6 material-symbols-outlined font-light m-0">account_circle</mat-icon>
            <span class="mat-body-2 text-text-primary">Account</span>
        </div>
    </div>

</mat-menu>