import { BaseObject } from "./org-quicko-core/beans/BaseObject";
import { Expose } from "class-transformer";
import 'reflect-metadata'

@Reflect.metadata("@entity", "com.quicko.accountant.accountant")
export class Accountant extends BaseObject {

    @Expose({ name: "@entity" })
    entity = 'com.quicko.accountant.accountant';

    //Id in base object will be accountant id
    @Expose({ name: 'email_id' })
    emailId?: string;

    @Expose({ name: 'first_name' })
    firstName?: string;

    @Expose({ name: 'last_name' })
    lastName?: string;

    @Expose({ name: 'mobile' })
    mobile?: string;

    @Expose({ name: 'country_code' })
    countryCode?: string;
}



