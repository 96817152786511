import 'reflect-metadata';
import { Expose, Type } from "class-transformer";
import { Block } from "./Block"
import { SheetBaseObject } from "./SheetBaseObject"
import { Table } from "./Table";
import { List } from "./List";

export class Sheet extends SheetBaseObject {

    @Expose()
    "@entity" = 'sheet'

    @Expose()
    @Type(() =>
        Block, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: 'name',
            subTypes: [
                {
                    value: Table, name: 'table'
                },
                {
                    value: List, name: 'list'
                }
            ]
        }
    })
    blocks: Array<Block>

    constructor(name: string, blocks: Array<Block>, metadata: any) {
        super();
        this.name = name
        this.blocks = blocks
        this.metadata = metadata

    }

    getBlocks(): Array<Block> {
        if (this.blocks == null) {
            this.blocks = new Array<Block>();
        }
        return this.blocks;
    }

    setBlocks(blocks: Array<Block>) {
        this.blocks = blocks;
    }

    getBlock(...args: (string | number)[]): Block | null | undefined {

        const getByIndex = (index: number) => {
            return this.getBlocks()[index];
        };

        const getByKey = (name: string) => {
            for (let i = 0; i < this.getBlocks().length; i++) {
                const block: Block | null | undefined = this.getBlock(i);
                if (block && block.getName().toLowerCase() == (name).toLowerCase()) {
                    return block;
                }
            }

            return undefined;
        };

        if (args.length === 1 && typeof (args[0]) == "number") {
            return getByIndex(args[0]);
        } else if (args.length === 1 && typeof (args[0]) == "string") {
            return getByKey(args[0]);
        }
        return null
    }

    addBlock(block: Block) {
        this.getBlocks().push(block);
    }

    removeBlock(name: string) {
        for (let i = 0; i < this.getBlocks().length; i++) {
            const block: Block | null | undefined = this.getBlock(i);

            if (block && block.getName().toLowerCase() == (name).toLowerCase()) {
                this.getBlocks().splice(i, 1);

                return;
            }
        }
    }

    replaceBlock(block: Block) {

        const name: string = block.getName();

        const sourceBlock: Block | null | undefined = this.getBlock(name);

        if (sourceBlock != null) {
            this.removeBlock(name);
        }

        this.getBlocks().push(block);

    }

    length() {
        return this.getBlocks().length;
    }

}