import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'button-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.css'],
})
export class ButtonLoaderComponent implements OnInit {
  @Input() width?: string;
  @Input() height?: string;
  @Input() color?: string;
  @Input() type?: 'primary' | 'secondary';
  btnWidth;
  btnHeight;
  btnColor;

  constructor() { }

  ngOnInit(): void {
    this.btnWidth = this.width ?? '128';
    this.btnHeight = this.height ?? '48';
    this.btnColor = this.color ?? 'var(--sys-primary)';
    this.type = this.type ?? 'primary';
  }
}
