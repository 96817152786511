import { createSelector } from "@ngrx/store";
import { MyAccountantsSelector } from "../accountant-store.selectors";
import { MyAccountantsModel } from "./my-accountants-store.model";


// Selector to return the accountants array from the MyAccountantsModel
export const getAccountants = () => createSelector(
    MyAccountantsSelector,
    (myAccountantsStore: MyAccountantsModel) => {
        return myAccountantsStore?.my_accountants
    }
);

// Selector to return the min value from the MyAccountantsModel
export const getMin = () => createSelector(
    MyAccountantsSelector,
    (myAccountantsStore: MyAccountantsModel) => myAccountantsStore?.min
);

// Selector to return the max value from the MyAccountantsModel
export const getMax = () => createSelector(
    MyAccountantsSelector,
    (myAccountantsStore: MyAccountantsModel) => myAccountantsStore?.max
);