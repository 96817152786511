import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TaxDetails } from '../beans';
import { ApiResponse } from '../beans/org-quicko-core/interfaces/ApiResponse';
import { ENV_CONFIG } from '../config/token';

@Injectable({
    providedIn: 'root',
})
export class TaxDetailsService {
    private endpoint: string;

    constructor(
        @Inject(ENV_CONFIG) private environment: any,
        private httpClient: HttpClient,
    ) {
        this.endpoint = this.environment.myaccount_api_endpoint;
    }

    getTaxDetails() {
        const url = this.endpoint + '/tax-details/';
        return this.httpClient.get<ApiResponse<any>>(url, {});
    }

    prefillTaxDetails(gstin: string) {
        const url = this.endpoint + '/tax-details/prefill';
        let params = new HttpParams();
        params = params.set('gstin', gstin)
        return this.httpClient.get<ApiResponse<any>>(url, { params: params });
    }

    postTaxDetails(taxDetails: any) {
        const url = this.endpoint + '/tax-details/';
        return this.httpClient.post<ApiResponse<TaxDetails>>(url, taxDetails);
    }

    patchTaxDetails(taxDetails: any) {
        const url = this.endpoint + '/tax-details/';
        return this.httpClient.patch<ApiResponse<TaxDetails>>(url, taxDetails);
    }
}
