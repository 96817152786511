import { Injectable } from '@angular/core';
import { ConnectedAppsStore } from './app-store';

@Injectable()
export class AppSelectors {

    constructor(private appsStore: ConnectedAppsStore) {
    }

    readonly apps$ = this.appsStore.select((state) => {
        return state?.apps
    })

    getAppByKey(appKey: string) {
        return this.appsStore.select(state => state?.apps?.find(app => app.app_key === appKey));
    }
}
