import 'reflect-metadata';
import { Expose, Type } from "class-transformer"
import { Sheet } from "./Sheet"
import { SheetBaseObject } from "./SheetBaseObject"

export class Workbook extends SheetBaseObject {

    "@entity" = 'workbook'

    @Expose()
    @Type(() => Sheet)
    sheets: Array<Sheet>

    constructor(name: string, sheets: Array<Sheet>, metadata?: any) {
        super()
        this.name = name
        this.sheets = sheets
        this.metadata = metadata

    }

    getSheets(): Array<Sheet> {
        if (this.sheets == null) {
            this.sheets = new Array<Sheet>();
        }

        return this.sheets;
    }

    setSheets(sheets: Array<Sheet>) {
        this.sheets = sheets;
    }


    getSheet(...args: (string | number)[]) : any {

        const getByIndex = (index: number) => {
            if (index <= this.getSheets().length) {
                return this.getSheets()[index]
            }
            return null
        };

        const getByKey = (name: string) => {
            for (let i = 0; i < this.getSheets().length; i++) {
                const sheet: Sheet | undefined = this.getSheet(i);

                if (sheet && sheet.name.toLowerCase() == (name).toLowerCase()) {
                    return sheet;
                }
            }

            return null;
        };

        if (args.length === 1 && typeof (args[0]) == "number") {
            return getByIndex(args[0]);
        } else if (args.length === 1 && typeof (args[0]) == "string") {
            return getByKey(args[0]);
        }
        return null
    }

    addSheet(sheet: Sheet) {
        this.getSheets().push(sheet);

    }

    replaceSheet(sheet: Sheet) {
        const name: string = sheet.getName();

        const sourceSheet: Sheet | undefined = this.getSheet(name);

        if (sourceSheet != null) {
            this.removeSheet(name);
        }

        this.getSheets().push(sheet);

    }

    removeSheet(name: string) {
        for (let i = 0; i < this.getSheets().length; i++) {
            const sheet: Sheet | undefined = this.getSheet(i);

            if (sheet && sheet.getName().toLowerCase() == (name).toLowerCase()) {

                this.getSheets().splice(i, 1);

                return;
            }
        }
    }

    length() {
        return this.getSheets().length;
    }

}