import { SheetBaseObject } from "./SheetBaseObject"

export abstract class Block extends SheetBaseObject {

    //items: Array<Record<string, number>>
    header: Array<any> = [];

    rows: Array<any> = [];

    constructor(name: string, metadata: any) {
        super();
        this.name = name
        this.metadata = metadata
    }

}