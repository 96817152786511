import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { provideStore } from '@ngrx/store';
import { CustomSerializer, ENV_CONFIG, IncomingInvitationsEffects, MyAccountantsEffects, OutgoingInvitationsEffects, ReferenceDataEffects, RequestInterceptor, ResponseInterceptor, RootReducers, TaxDetailsEffects, UserEffects } from 'ngx-com-quicko-myaccount';
import { provideEffects } from '@ngrx/effects';
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideRouterStore } from '@ngrx/router-store';
import { environment } from '../environments/environment';
import { RIVE_FOLDER } from 'ng-rive';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withInMemoryScrolling({
      scrollPositionRestoration: 'top'
    })),
    provideAnimations(),
    provideStore(RootReducers),
    provideEffects(UserEffects, MyAccountantsEffects, TaxDetailsEffects, ReferenceDataEffects, IncomingInvitationsEffects, OutgoingInvitationsEffects),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {
      provide: RIVE_FOLDER,
      useValue: "/assets/animations"
    },
    provideStoreDevtools(),
    provideRouterStore({
      serializer: CustomSerializer
    }),
    { provide: ENV_CONFIG, useValue: environment },
    provideZoneChangeDetection({ eventCoalescing: true })
  ]
};


