import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ConnectedAppsStore, ConnectedAppsActions, AppEffects, ConnectedAppsReducers, AppSelectors, AppsService } from 'ngx-com-quicko-myaccount';

@Component({
  selector: 'app-app-container',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.css'],
  providers: [
    ConnectedAppsStore,
    AppSelectors,
    AppEffects,
    ConnectedAppsReducers
  ]
})
export class AppsContainerComponent implements OnInit {

  apps: any

  constructor(
    private store: Store,
    private appsService: AppsService,
    private appStore: ConnectedAppsStore,
    private appReducer: ConnectedAppsReducers,
    private appSelectors: AppSelectors,
    private appEffects: AppEffects,
    private actions$: Actions
  ) { }

  ngOnInit() {
    console.log('hello')
    this.store.dispatch(ConnectedAppsActions.GET_APPS());
  }

}
