import { createSelector } from "@ngrx/store";
import { RootSelectors } from "../app.selectors";
import { UserStore } from "./user-store.model";


export const UserStoreSelector = createSelector(
    RootSelectors.USER_STORE,
    (userStore: UserStore) => {
        return userStore?.user
    }
);


export const TaxDetailsStoreSelector = createSelector(
    RootSelectors.USER_STORE,
    (userStore: UserStore) => {
        return userStore?.tax_details
    }
);
