import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RxFormBuilder, RxReactiveFormsModule, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ReferenceDataActions, ReferenceDataService, getCountries, getStateDetails } from 'ngx-com-quicko-myaccount';
import { AutocompleteValidator } from '../../validators/autocomplete.validator';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { RiveModule } from 'ng-rive';
import { ForeignAddressViewComponent } from './foreign-address-view/foreign-address-view.component';
import { DomesticAddressViewComponent } from './domestic-address-view/domestic-address-view.component';
import { Actions, ofType } from '@ngrx/effects';
import { FormFieldErrorComponent } from '../form-field-error/form-field-error.component';

@Component({
  selector: 'app-address',
  standalone: true,
  providers: [RxFormBuilder],
  imports: [CommonModule, RouterModule, FormFieldErrorComponent, ForeignAddressViewComponent, DomesticAddressViewComponent, MatFormFieldModule, RiveModule, MatInputModule, ReactiveFormsModule, MatIconModule, RxReactiveFormsModule, MatSelectModule, RiveModule],
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  @Input() addressForm?: FormGroup;

  states;
  filteredStates;
  countries;

  selectedCountry: string;

  isIndia: boolean = false;
  countries$: Observable<Array<any>>;
  states$: Observable<Array<any>>;
  destroy$ = new Subject<void>();


  constructor(
    private refDataService: ReferenceDataService,
    private actions$: Actions,
    private store: Store
  ) {
    this.countries$ = this.store.select(getCountries());
    this.states$ = this.store.select(getStateDetails());
    this.selectedCountry = this.addressForm?.controls['country']?.value;
  }

  ngOnInit() {

    if (this.selectedCountry == undefined) {
      this.selectedCountry = 'India'
      this.addressForm.controls['country'].setValue('India')
    }

    this.countries$.subscribe((countries: any) => {

      if (countries == undefined) {
        this.store.dispatch(ReferenceDataActions.GET_COUNTRIES())
      } else {
        this.countries = countries;
      }
    })

    this.states$.subscribe((states: any) => {

      if (states == undefined) {
        this.store.dispatch(ReferenceDataActions.GET_STATES())
      } else {
        this.states = states;
        this.applyStateValidations();
      }
    })

    this.actions$.pipe(ofType(ReferenceDataActions.GET_COUNTRIES_SUCCESS), takeUntil(this.destroy$)).subscribe((res) => {
      this.countries$.subscribe((countries: any) => {
        this.countries = countries;
      })
      this.applyStateValidations();
    })

    this.actions$.pipe(ofType(ReferenceDataActions.GET_STATES_SUCCESS), takeUntil(this.destroy$)).subscribe((res) => {
      this.states$.subscribe((states: any) => {
        this.states = states;
      })
      this.applyStateValidations();
    })


    // default country India
    // this.addressForm.controls['country'].setValue('India');
  }

  applyStateValidations() {
    this.filteredStates = this.addressForm.controls['state'].valueChanges
      .pipe(
        startWith(''),
        map(state => {
          // PASSING FILTERED LIST TO VALIDATOR
          const filteredStates = state ? this.filterStates(state as string) : this.states.slice();

          this.addressForm.controls['state'].setValidators([RxwebValidators.notEmpty({ message: 'State cannot be empty' }), AutocompleteValidator.requireMatch(filteredStates)]);
          this.addressForm.controls['state'].updateValueAndValidity({ emitEvent: false });

          return filteredStates;
        }));
  }

  filterStates(value: string) {
    const filterValue = value.toLowerCase();
    return this.states.filter(state => state.name.toLowerCase().indexOf(filterValue) == 0);
  }

  filterCountries(value: string) {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country => country.name.toLowerCase().indexOf(filterValue) == 0);
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}