import { Injectable } from "@angular/core";
import { ConnectedAppsStore } from "./app-store";
import { App } from "../../beans/App";

@Injectable()
export class ConnectedAppsReducers {
    constructor(private appStore: ConnectedAppsStore) {
    }

    onGetCartSuccess = this.appStore.updater((state, apps: App[]) => ({
        ...state,
        apps: apps
    }))

}