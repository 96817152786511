import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ReferenceDataActions } from './reference-data-store.actions';
import { ReferenceDataService } from '../../services';

@Injectable()
export class ReferenceDataEffects {

    getCountries$ = createEffect(() => this.actions$.pipe(
        ofType(ReferenceDataActions.GET_COUNTRIES),
        switchMap(() => this.referenceDataService.getCountries().pipe(
            map(response => {
                response.data.forEach((countries) => {
                    countries.name = countries?.name ?? '';
                    countries.numeric_code = countries?.numeric_code ?? '';
                    countries.country_code = countries?.country_code ?? '';
                    countries.flag = countries?.flag ?? '';

                    delete countries.createdAt;
                    delete countries.updatedAt;
                    delete countries.publishedAt;
                });

                return ReferenceDataActions.GET_COUNTRIES_SUCCESS({ countries: response.data })
            }
            )
        ))
    ))

    getStates$ = createEffect(() => this.actions$.pipe(
        ofType(ReferenceDataActions.GET_STATES),
        switchMap(() => this.referenceDataService.getStateDetails().pipe(
            map(response => {
                response.data.forEach((states) => {
                    states.state_name = states?.state_name ?? '';
                    states.state_code = states?.state_code ?? '';

                    states.state_name = this.toTitleCase(states.state_name);

                    delete states.createdAt;
                    delete states.updatedAt;
                    delete states.publishedAt;
                });

                return ReferenceDataActions.GET_STATES_SUCCESS({ states: response.data })
            }
            )
        ))
    ))

    toTitleCase(str: string): string {
        return str.toLowerCase().split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    }

    constructor(
        private actions$: Actions,
        private referenceDataService: ReferenceDataService,
    ) { }
}
