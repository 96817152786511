import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { plainToClass } from 'class-transformer';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InvitationService } from '../../../services/invitation.service';
import { IncomingInvitationsActions } from './incoming-invitations-store.actions';
import { Invitation, InvitationRow, InvitationTable } from '../../../beans';
import { JSONObject } from '../../../beans/org-quicko-core/beans/JSONObject';
import { Sheet } from '../../../beans/org-quicko-sheet/beans/Sheet';
import { Workbook } from '../../../beans/org-quicko-sheet/beans/Workbook';
import { SnackbarService } from '../../../services';

@Injectable()
export class IncomingInvitationsEffects {

    constructor(
        private actions$: Actions,
        private invitationService: InvitationService,
        private snackBarService: SnackbarService,
        private store: Store
    ) { }

    getIncomingInvitations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IncomingInvitationsActions.GET_INVITATIONS),
            switchMap((action) =>
                this.invitationService.getIncomingInvitations(action.lastEvaluatedKey, action.limit, action.sort).pipe(
                    map(
                        (response) => {
                            const workbookData = response?.data as JSONObject;
                            const incomingInvitationWorkbook: Workbook = plainToClass(Workbook, workbookData)
                            const incomingInvitationSheet: Sheet = incomingInvitationWorkbook.getSheet('com.quicko.accountant.invitation_from_accountant_sheet');
                            const invitationTable: InvitationTable = plainToClass(InvitationTable, incomingInvitationSheet.getBlock('com.quicko.accountant.invitation_table'));
                            const invitationRows: InvitationRow[] = invitationTable.getInvitationRows();

                            const metadata = incomingInvitationSheet.getMetadata();
                            const lastEvaluatedKey: string = metadata['last_evaluated_key'];
                            return IncomingInvitationsActions.GET_INVITATIONS_SUCCESS({ invitationRows, lastEvaluatedKey });
                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to get incoming invitations', undefined);
                        this.store.dispatch(
                            IncomingInvitationsActions.GET_INVITATIONS_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                ),
            )
        )
    )


    getIncomingInvitationsCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IncomingInvitationsActions.GET_INVITATIONS_COUNT),
            switchMap((action) =>
                this.invitationService.getIncomingInvitationsCount().pipe(
                    map(
                        (response) => {
                            const invitationCount = response?.data as number;
                            return IncomingInvitationsActions.GET_INVITATIONS_COUNT_SUCCESS({ invitationCount });
                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to get incoming invitations count', undefined);
                        this.store.dispatch(
                            IncomingInvitationsActions.GET_INVITATIONS_COUNT_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                ),
            )
        )
    )

    acceptInvitation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IncomingInvitationsActions.ACCEPT_INVITATION),
            switchMap((action) =>
                this.invitationService.acceptInvitation(action.accountantId).pipe(
                    map(
                        (response) => {
                            return IncomingInvitationsActions.ACCEPT_INVITATION_SUCCESS({ accountantId: action.accountantId });
                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to accept invitation', undefined);
                        this.store.dispatch(
                            IncomingInvitationsActions.ACCEPT_INVITATION_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                ),
            )
        )
    )


    rejectInvitation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IncomingInvitationsActions.REJECT_INVITATION),
            switchMap((action) =>
                this.invitationService.rejectInvitation(action.accountantId).pipe(
                    map(
                        (response) => {
                            return IncomingInvitationsActions.REJECT_INVITATION_SUCCESS({ accountantId: action.accountantId });
                        }),
                    catchError((err, caught) => {
                        this.snackBarService.openSnackBar('Failed to reject invitation', undefined);
                        this.store.dispatch(
                            IncomingInvitationsActions.REJECT_INVITATION_ERROR({ err: err.message }),
                        )
                        throw err;
                    })
                ),
            )
        )
    )

}