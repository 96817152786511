import { createReducer, on } from "@ngrx/store";
import { User } from "../../../beans/User";
import { UserActions } from "./user.actions";
import { UserModel } from "./user.model";

export const onGetUserSuccess = on(UserActions.GET_USER_SUCCESS, (state: UserModel, { user }) => {
    return {
        ...state,
        user: user
    };
});

export const onEditUserSuccess = on(UserActions.POST_USER_SUCCESS, (state: UserModel, { user }) => {
    return {
        ...state,
        user: user
    };
});


export const userReducer = createReducer(
    { user: null },
    onGetUserSuccess,
    onEditUserSuccess
);