import { BaseObject } from './BaseObject';
import { JSONObject } from './JSONObject';
export class Event extends BaseObject {
  '@entity'?: string = 'org.quicko.event';
  type: string | null;
  source: string | null;
  time: string | null;
  subject: string | null;
  specVersion: string | null;
  dataContentType: string | null;
  data: JSONObject;
  constructor(data: JSONObject) {
    super();
    this.type = null;
    (this.source = null),
      (this.time = null),
      (this.subject = null),
      (this.specVersion = null),
      (this.dataContentType = null),
      (this.data = data);
  }
}
