import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ApiResponse } from '../beans/org-quicko-core/interfaces/ApiResponse'
import { ENV_CONFIG } from '../config/token';

@Injectable({
	providedIn: 'root',
})
export class AccountantService {
	private endpoint: string;

	constructor(
		@Inject(ENV_CONFIG) private environment: any,
		private httpClient: HttpClient,
	) {
		this.endpoint = this.environment.myaccount_api_endpoint;
	}

	getAccountant(accountantId: string) {
		const url = this.endpoint + '/accountants/' + accountantId;
		return this.httpClient.get<ApiResponse<any>>(url, {});
	}

	getAccountants(min?: number, max?: number) {
		const url = this.endpoint + '/accountants/';
		let params = new HttpParams();
		if (min) {
			params = params.set('min', min)
		}
		else {
			params = params.set('min', 1)
		}
		if (max) {
			params = params.set('max', max)
		}
		else {
			params = params.set('max', 10)
		}

		return this.httpClient.get<ApiResponse<any>>(url, { params: params });
	}

	patchAccountant(accountantId: string, expiryAt?: number) {
		const url = this.endpoint + '/accountants/' + accountantId;
		let params = new HttpParams();
		if (expiryAt) {
			params = params.set('expiry_at', expiryAt)
		}
		return this.httpClient.patch(url, null, { params: params });
	}

	deleteAccountant(accountantId: string) {
		const url = this.endpoint + '/accountants/' + accountantId;
		return this.httpClient.delete(url);
	}
}
