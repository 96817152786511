import { createReducer, on } from '@ngrx/store';
import { MyAccountantsActions } from './my-accountants-store.actions';
import { MyAccountantsModel } from './my-accountants-store.model';
import { AccountantRow } from '../../../beans';

const onGetAccountants = on(
    MyAccountantsActions.GET_ACCOUNTANTS_SUCCESS,
    (state: MyAccountantsModel, { accountantRows, min, max }) => {

        return {
            ...state,
            my_accountants: accountantRows,
            // accountants: state?.accountants != null ? [...state.accountants, ...accountantRows] : [...accountantRows],
            min: min,
            max: max
        } as MyAccountantsModel
    },
);

const onDeleteAccountant = on(
    MyAccountantsActions.DELETE_ACCOUNTANT_SUCCESS,
    (state: MyAccountantsModel, { accountantId }) => {
        const existingAccountants = state.my_accountants;
        const filteredAccountants = existingAccountants.filter(
            (accountantRow: AccountantRow) => accountantRow.getId() != accountantId)
        return {
            ...state,
            my_accountants: filteredAccountants
        } as MyAccountantsModel
    }
)

export const myAccountantsReducer = createReducer(
    {
        my_accountants: [],
        min: undefined,
        max: undefined
    },
    onGetAccountants,
    onDeleteAccountant
);
