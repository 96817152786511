<ng-container *ngIf="!loading else showLoading">
  <ng-container *ngIf="(accountantRows != null && accountantRows.length > 0) ||
      (outgoingInvitationRows != null && outgoingInvitationRows.length > 0); else emptyAccountantTemplate">
    <mat-card appearance="outlined" class="flex flex-col gap-[16px] mt-2 rounded">
      <div class=" border-stroke rounded-md">
        <div #table>
          <div class="flex flex-row mat-body-2 bg-surface-container-high h-[69px] px-6 py-6 mb-0" #header>
            <p class="basis-[40%]">Profiles</p>
            <p class="basis-[30%]">Status</p>
            <p class="basis-[30%]">Last Accessed</p>
          </div>
          <div *ngFor="let accountantRow of accountantRows">
            <app-accountant-row [accountantRow]="accountantRow" (delete)="deleteAccountant($event)"
              (edit)="editAccountant($event)"></app-accountant-row>
          </div>
          <div *ngFor="let invitationRow of outgoingInvitationRows">
            <app-outgoing-invitation-row [invitationRow]="invitationRow" (delete)="deleteInvitation($event)"
              (edit)="editInvitation($event)"></app-outgoing-invitation-row>
          </div>
        </div>
      </div>
      <!-- <div class="grey-border border-stroke rounded-md mt-5">
        <div class="flex flex-row items-center py-6 px-6 gap-x-6">
          <div class="flex flex-col gap-y-1 w-[598px]">
            <span class="mat-body-1 text-text-primary m-0" style="font-weight:600;">
              Hey expert, managing taxes for multiple people?
            </span>
            <span class="mat-body-2 text-text-secondary">
              We have just the thing for you. Manage multiple clients on Quicko & help them with their taxes using
              enhanced features.
            </span>
          </div>
          <div>
            <button mat-stroked-button class="grey-border border-stroke white my-auto bg-blue-900 mat-body-1 px-6 py-6"
              (click)="navigateToLearnMore()">
              <p class="mat-body-1 mb-0 white whitespace-nowrap">Learn More</p>
            </button>
          </div>
        </div>
      </div> -->
    </mat-card>
  </ng-container>
</ng-container>



<ng-template #emptyAccountantTemplate>
  <!-- TO DO : Add action for autofill -->
  <div class="mt-20 flex flex-col items-center">
    <div class="mx-10 flex flex-col items-center">
      <mat-icon svgIcon="il_accountants" alt="User Accounts" class="w-[400px] h-[240px]"></mat-icon>
      <div class="mt-6 mx-[80px] flex flex-col justify-center items-center">
        <p class="mat-body-1 text-text-primary" style="font-weight: 600;">Manage your accountants here</p>
        <p class="mat-body-2 text-text-secondary text-center mx-[20px] mt-1">Collaborate with your accountant to
          efficiently
          manage your
          tax
          responsibilities, ensuring effective handling of your financial obligations.</p>
        <!-- <div class="mt-4">
            <button mat-flat-button class="white blue-bg-a900 mat-body-1 mt-4 px-8 py-6" (click)="inviteAccountant()">
              <mat-icon svgIcon="tax-payer:ic_add_accountant" class="material-symbols-outlined">
              </mat-icon>
              <p class="mat-body-1">Invite Accountant</p>
            </button>
          </div> -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showLoading>
  <div class="border border-outline-variant rounded-md mt-2">
    <div class="flex flex-row mat-body-2 text-text-secondary h-[69px] px-6 py-6 mb-0" #header>
      <p class="basis-[40%]">Profiles</p>
      <p class="basis-[30%]">Status</p>
      <p class="basis-[30%]">Last Accessed</p>
    </div>
    <div *ngFor="let item of [0, 1, 2]">
      <div class="h-[72px] border-outline-variant border-b border-t flex flex-row items-center px-6 py-5">
        <div class="flex flex-row mr-2">
          <ngx-skeleton-loader
            [theme]="{ height: '32px', width: '32px', 'border border-stroke-radius': '50%', 'margin-bottom': '-4px', 'background-color': 'var(--sys-surface-container-high)' }" />
        </div>
        <div class="flex flex-row mr-9">
          <ngx-skeleton-loader
            [theme]="{ height: '24px', width: '225px', 'margin-bottom': '-4px', 'background-color': 'var(--sys-surface-container-high)' }" />
        </div>
        <div class="flex flex-row mr-[140px]">
          <ngx-skeleton-loader
            [theme]="{ height: '24px', width: '84px', 'margin-bottom': '-4px', 'background-color': 'var(--sys-surface-container-high)' }" />
        </div>
        <div class="flex flex-row">
          <ngx-skeleton-loader
            [theme]="{ height: '24px', width: '158px', 'margin-bottom': '-4px', 'background-color': 'var(--sys-surface-container-high)' }" />
        </div>
      </div>
    </div>
  </div>
</ng-template>