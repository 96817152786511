<ng-container *ngIf="!loadingIncomingInvitations else showLoading">
  <ng-container
    *ngIf="incomingInvitationRows != null && incomingInvitationRows.length > 0; else emptyIncomingInvitationsTemplate">
    <div class="flex flex-col gap-[16px] mt-2">
      <div *ngFor="let invitationRow of incomingInvitationRows">
        <app-invitation-row [invitationRow]="invitationRow" (accept)="acceptInvitation($event)"
          (reject)="rejectInvitation($event)"></app-invitation-row>
      </div>

    </div>
  </ng-container>
</ng-container>


<ng-template #emptyIncomingInvitationsTemplate>

  <div class="mt-20 flex flex-col items-center">
    <div class="mx-10 flex flex-col items-center">
      <mat-icon svgIcon="il_incoming_invitations" alt="User Accounts" class="w-[400px] h-[240px]"></mat-icon>
      <div class="mt-6 mx-[80px] flex flex-col justify-center items-center">
        <p class="mat-body-1 text-text-primary" style="font-weight: 600;">View all your incoming invites here</p>
        <p class="mat-body-2 text-text-secondary text-center mx-[20px] mt-1">You can view invitations sent by
          accountants to
          you here. Accepting their invitation gives them access to your account.
        </p>
        <!-- <div class="mt-4">
            <button mat-flat-button class="white blue-bg-a900 mat-body-1 mt-4 px-8 py-6" (click)="inviteAccountant()">
              <mat-icon svgIcon="tax-payer:ic_add_accountant" class="material-symbols-outlined">
              </mat-icon>
              <p class="mat-body-1">Invite Accountant</p>
            </button>
          </div> -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showLoading>
  <div *ngFor="let item of [0, 1]" class="mt-2">
    <div class="h-[72px] border border-outline-variant border-stroke rounded-md flex flex-row items-center px-6 py-5">
      <div class="flex flex-row mr-2">
        <ngx-skeleton-loader
          [theme]="{ height: '44px', width: '44px', 'border-stroke-radius': '50%', 'margin-bottom': '-4px', 'background-color': 'var(--sys-surface-container-high)'}" />
      </div>
      <div class="flex flex-row mr-[180px]">
        <ngx-skeleton-loader
          [theme]="{ height: '24px', width: '450px', 'margin-bottom': '-4px', 'background-color': 'var(--sys-surface-container-high)' }" />
      </div>
      <div class="flex flex-row">
        <ngx-skeleton-loader
          [theme]="{ height: '36px', width: '84px', 'margin-bottom': '-4px', 'background-color': 'var(--sys-surface-container-high)' }" />
      </div>
    </div>
  </div>
</ng-template>