import { Actions, concatLatestFrom, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ConnectedAppsStore } from "./app-store";
import { AppsService } from "../../services/apps.service";
import { ConnectedAppsActions } from "./app.actions";
import { catchError, map, switchMap } from "rxjs";
import { Injectable } from "@angular/core";
import { ConnectedAppsReducers } from "./app.reducers";
import { tapResponse } from "@ngrx/component-store";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class AppEffects {
    constructor(
        private actions$: Actions,
        private appStore: ConnectedAppsStore,
        private appsService: AppsService,
        private appReducers: ConnectedAppsReducers,
        private store: Store
    ) { }

    getApps$ = this.appStore.effect(() =>
        this.actions$.pipe(
            ofType(ConnectedAppsActions.GET_APPS),
            switchMap(() =>
                this.appsService.getApps().pipe(
                    tapResponse(
                        (response) => {
                            this.store.dispatch(ConnectedAppsActions.GET_APPS_SUCCESS({ apps: response.data.items }));
                            this.appReducers.onGetCartSuccess(response.data.items);
                        },
                        (error: HttpErrorResponse) => {
                            throw error;
                        }
                    )
                )
            )
        )
    )

    deleteApp$ = this.appStore.effect(() =>
        this.actions$.pipe(
            ofType(ConnectedAppsActions.REMOVE_APP),
            switchMap(((action) => this.appsService.deleteApps(action.appKey).pipe(
                map((response) => {
                   this.store.dispatch(ConnectedAppsActions.REMOVE_APP_SUCCESS())
                }),
                catchError((err, caught) => {
                    this.store.dispatch(
                        ConnectedAppsActions.REMOVE_APP_ERROR({ errorMsg: err.message }),
                    )
                    throw err;
                })
            )))
        )
    )
}