import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG } from '../config/token';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {

  private cmsItEndpoint: string;
  private cmsOrgQuickoEndpoint: string;

  constructor(@Inject(ENV_CONFIG) private environment: any,
    private httpClient: HttpClient) {
    this.cmsItEndpoint = this.environment.cms_it_endpoint;
    this.cmsOrgQuickoEndpoint = this.environment.cms_org_quicko_endpoint;
  }

  getCountries() {
    const url = this.cmsItEndpoint + '/api/com-quicko-it-ref-countries?populate=*'
    return this.httpClient.get<any>(url);
  }

  getStateDetails() {
    const url = this.cmsOrgQuickoEndpoint + '/api/org-quicko-gst-state-codes?sort=state_name';
    return this.httpClient.get<any>(url);
  }

}
