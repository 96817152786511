<section id="edit-presonal-details">

  <div class="flex flex-col w-[642px]">

    <a routerLink="../../">
      <mat-icon class="material-symbols-outlined text-text-primary w-8 h-8 text-[32px]">arrow_circle_left</mat-icon></a>
    <h1 class="text-xl font-semibold text-text-primary mt-1">Personal Details</h1>
    <h3 class="text-lg font-medium text-text-secondary mt-1">Manage your identity across Quicko.</h3>

    <form class="flex flex-col mt-7" [formGroup]="personalDetailsForm"
      (keyup.enter)="validate() ? save() : showError()">

      <h2 class="text-lg font-semibold text-text-primary">Name</h2>
      <h3 class="text-sm font-medium text-text-secondary mt-1">
        Changes to your name will be reflected across your Quicko Account. Anyone can see this info when they
        communicate with you.
      </h3>

      <div class="flex flex-row mt-4">
        <div class="flex flex-col">
          <mat-form-field appearance="outline" class="w-[309px]">
            <mat-label>First Name</mat-label>
            <input matInput required formControlName="first_name" placeholder="First Name">
            <mat-error>
              <ng-container
                *ngIf="personalDetailsForm['controls']['first_name']?.errors && (personalDetailsForm['controls']['first_name'].dirty || personalDetailsForm['controls']['first_name'].touched)">

                <ng-container
                  *ngFor="let error of personalDetailsForm['controls']['first_name']?.errors | keyvalue; let i = index">
                  <ng-container *ngIf="i===0">
                    <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                  </ng-container>
                </ng-container>

              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="flex flex-col">
          <mat-form-field appearance="outline" class="ml-6 w-[309px]">
            <mat-label>Last Name</mat-label>
            <input matInput required formControlName="last_name" placeholder="Last Name">
            <mat-error>
              <ng-container
                *ngIf="personalDetailsForm['controls']['last_name']?.errors && (personalDetailsForm['controls']['last_name'].dirty || personalDetailsForm['controls']['last_name'].touched)">
                <ng-container
                  *ngFor="let error of personalDetailsForm['controls']['last_name']?.errors | keyvalue; let i = index">
                  <ng-container *ngIf="i===0">
                    <form-field-error [errorMessage]="error.value['message']"></form-field-error>
                  </ng-container>
                </ng-container>

              </ng-container>
            </mat-error>
          </mat-form-field>

        </div>

      </div>

      <h2 class="text-lg font-semibold text-text-primary mt-5">Demographics</h2>
      <h3 class="text-sm font-medium text-text-secondary mt-1">
        Your birthday may be used for account security and personalization across Quicko services
      </h3>

      <div class="flex flex-row mt-4">
        <mat-form-field appearance="outline" class="h-20 w-full">
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender">
            <mat-option class="mat-body-1 text-text-primary m-0 p-4" value="M">Male</mat-option>
            <mat-divider></mat-divider>
            <mat-option class="mat-body-1 text-text-primary m-0 p-4" value="F">Female</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="ml-6 w-full">
          <mat-label>Date of Birth</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" formControlName="date_of_birth"
            [max]="maxDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker class="mat-datepicker-toggle" #picker></mat-datepicker>

          <mat-error>
            <ng-container *ngIf="dateError == true">
              <form-field-error [errorMessage]="'Invalid Date'"></form-field-error>
            </ng-container>

          </mat-error>
        </mat-form-field>

      </div>

    </form>

    <div class="flex flex-row mt-5">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button mat-flat-button class="text-base font-medium rounded w-36 h-12"
          (click)="validate() ? save() : showError()">Save</button>
      </ng-container>
      <button mat-stroked-button class="text-base rounded font-medium w-36 h-12 ml-6"
        routerLink="../../">Cancel</button>
    </div>

  </div>
  <ng-template #loading>
    <button-loader [width]="'144'"></button-loader>
    <!-- <button class="text-base font-medium text-white rounded bg-blue-900 w-36 h-12 flex justify-center">
      <canvas riv="loading_bars_white" width="90" height="45">
        <riv-animation name="shrink" play></riv-animation>
      </canvas>
    </button> -->
  </ng-template>

</section>