import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-web-footer',
  imports: [MatIconModule],
  standalone: true,
  templateUrl: './footer.component.html'
})
export class WebFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
