import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { ConnectedAppsStore, AppEffects, ConnectedAppsReducers, AppSelectors, AppsService, DateUtil } from 'ngx-com-quicko-myaccount';

import { MatDialog } from '@angular/material/dialog';
import { DeleteAppComponent } from '../delete-app/delete-app.component';
import { CommonModule, NgFor } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HostPipe } from 'ngx-com-quicko-myaccount/src/lib/pipes/URLPipe';

@Component({
  selector: 'app-app-detail',
  standalone: true,
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule, NgFor, HostPipe],
  templateUrl: './app-detail.component.html',
  styleUrls: ['./app-detail.component.css']
})
export class AppDetailComponent implements OnInit {

  appKey: string;
  app$?: any
  app?: any;
  private destroy$: Subject<void> = new Subject<void>();
  date: string | undefined;
  isLoading: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private appsService: AppsService,
    private appStore: ConnectedAppsStore,
    private appReducer: ConnectedAppsReducers,
    private appSelectors: AppSelectors,
    private appEffects: AppEffects,
    private actions$: Actions,
    public dialog: MatDialog) {
    this.appKey = this.route.snapshot.paramMap.get('app_key')!;
  }

  ngOnInit() {
    this.app$ = this.appSelectors.getAppByKey(this.appKey);
    this.app$.pipe(takeUntil(this.destroy$)).subscribe((app: any) => {
      if (app) {
        this.app = app;
        this.date = DateUtil.printDate(DateUtil.fromMillis(this.app.created_at));
        this.isLoading = false;
        console.log(app);
      }
    });
  }

  deleteApp() {
    const dialogRef = this.dialog.open(DeleteAppComponent, {
      width: '400px',
      data: { appKey: this.app.app_key }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.location.reload();
      }
    });
  }

}
