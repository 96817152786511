<section id="settings">

  <div class="flex flex-col gap-y-8">
    <span class="mat-headline-6 font-semibold text-text-primary m-0">Settings</span>

    <app-password></app-password>

    <div class="flex flex-col gap-y-4">
      <div class="flex flex-row gap-x-2 items-center">
        <mat-icon class="material-symbols-outlined m-0">shield</mat-icon>
        <span class="mat-subtitle-2 text-text-primary m-0 font-semibold">Data & Privacy</span>
      </div>
      <mat-card appearance="outlined" class="flex flex-col rounded p-6">
        <p class="mat-body-1  text-text-primary">Third Party App Services</p>
        <p class="mat-body-2 font-medium text-text-secondary mt-1">Control which apps and services have access to
          your
          Quicko data
        </p>
        <button mat-stroked-button class="text-base font-medium border border-stroke rounded w-36 h-12 mt-6"
          routerLink="apps">
          <p class="mat-body-1 mb-0 whitespace-nowrap">Manage</p>
        </button>
      </mat-card>
    </div>

    <div class="flex flex-col gap-y-4">
      <div class="flex flex-row gap-x-2 items-center">
        <mat-icon class="material-symbols-outlined m-0">warning</mat-icon>
        <span class="mat-subtitle-2 text-text-primary m-0 font-semibold">Danger Zone</span>
      </div>
      <mat-card appearance="outlined" class="flex flex-col  rounded  p-6">
        <p class="mat-body-1 text-text-primary">Delete Account</p>
        <p class="mat-body-2 font-medium text-text-secondary mt-1">This account will no longer be accessible and all
          your
          saved data will be deleted
        </p>
        <button mat-button color="warn" class="border-error text-base font-medium rounded w-36 h-12 mt-6"
          (click)="deleteAccount()">
          <p class="mat-body-1 mb-0 whitespace-nowrap">Delete</p>
        </button>
      </mat-card>
    </div>



  </div>

</section>