<div class="flex flex-col w-[246px] ">

  <mat-card appearance="outlined" class="flex flex-col py-8 px-4 justify-center items-center rounded-md">

    <ng-container *ngIf="hasName==true; else noName">
      <div class="blue-bg-50  rounded-full flex flex-row items-center justify-center w-[60px] h-[60px]">
        <span class="mat-headline-6 blue-grey-400 m-0" style="font-weight: 600">{{
          ((userDetails | async)?.first_name ?
          (userDetails | async)?.first_name.trim()[0].toUpperCase() : '') + ((userDetails |
          async)?.last_name ?
          (userDetails | async)?.last_name.trim()[0].toUpperCase() : '') }}
        </span>
      </div>

    </ng-container>
    <ng-template #noName>
      <div class="cursor-pointer blue-bg-50  rounded-full flex flex-row items-center justify-center w-[60px] h-[60px]">
        <span class="mat-headline-6 blue-grey-400 my-auto" style="font-weight: 600">{{
          ((userDetails | async)?.username ?
          (userDetails | async)?.username.trim()[0].toUpperCase() : '') }}
        </span>
      </div>
    </ng-template>

    <ng-container *ngIf="hasName==true else showEmail">
      <div class="flex flex-row items-center justify-center w-[200px]">
        <p class="text-base font-semibold text-text-primary pt-2 text-nowrap overflow-hidden truncate">
          {{(userDetails |
          async)?.first_name}} {{(userDetails |
          async)?.last_name}}</p>
      </div>
    </ng-container>
    <ng-template #showEmail>
      <div class="flex flex-row items-center justify-center w-[200px]">
        <p class="text-base font-semibold text-text-primary pt-2 text-nowrap overflow-hidden truncate">
          {{(userDetails |
          async)?.username ? (userDetails |
          async)?.username.split('@')[0] : '' }}</p>
      </div>
    </ng-template>
    <p class="text-sm font-medium text-text-secondary pt-1">{{(userDetails | async)?.username}}</p>
    <p *ngIf="(userDetails | async)?.mobile" class="text-sm font-medium text-text-secondary pt-1">+{{(userDetails |
      async)?.country_code}} {{(userDetails | async)?.mobile}}</p>

  </mat-card>

  <mat-card appearance="outlined" class="mt-4 rounded-md">
    <mat-list>
      <a class="flex flex-row p-6" routerLink="profile" routerLinkActive #myAccount="routerLinkActive"
        [ngClass]="{'primary border-r-4 border-primary': myAccount.isActive}">
        <mat-icon class="w-6 h-6 material-symbols-outlined font-light">account_circle</mat-icon>
        <p class="text-base font-medium ml-4" [ngClass]="myAccount.isActive ? 'primary' : 'on-surface'">
          My
          Profile</p>
      </a>

      <a class="flex flex-row p-6" routerLink="accountants" routerLinkActive #accountants="routerLinkActive"
        [ngClass]="{'primary border-r-4 border-primary': accountants.isActive}">
        <mat-icon class="w-6 h-6 material-symbols-outlined font-light">supervised_user_circle</mat-icon>
        <p class="text-base font-medium ml-4" [ngClass]="accountants.isActive ? 'primary' : 'on-surface'">
          My Accountants</p>
      </a>

      <a class="flex flex-row p-6" routerLink="settings" routerLinkActive #settings="routerLinkActive"
        [ngClass]="{'primary border-r-4 border-primary': settings.isActive}">
        <mat-icon class="w-6 h-6 material-symbols-outlined font-light">settings</mat-icon>
        <p class="text-base font-medium ml-4" [ngClass]="settings.isActive ? 'primary' : 'on-surface'">
          Settings</p>
      </a>
    </mat-list>
  </mat-card>

</div>