<footer class="pt-20">
  <div class="flex flex-row justify-center w-[100vw] bg-surface-container">
    <div class="w-[1080px]">
      <div class="flex flex-row py-10 justify-between items-center container">
        <p class="text-base font-medium text-blue-grey-300">© Quicko Infosoft Private Limited</p>
        <div class="flex flex-row gap-x-2 items-center">
          <p class="text-base font-medium text-blue-grey-400">Built with</p>
          <mat-icon class="material-symbols-solid blue-grey-400 w-[16px] h-[16px]"
            style="font-size:16px">favorite</mat-icon>
          <p class="text-base font-medium text-blue-grey-400 ">from India</p>
        </div>
      </div>
    </div>
  </div>
</footer>