<!-- <section *ngIf="user; else loading" class="flex flex-col justify-between min-h-screen">

    <div>

        <app-web-header></app-web-header>

        <mat-divider></mat-divider>

        <mat-sidenav-container class="container mt-8 bg-white min-h-[678px]">
            <mat-sidenav [mode]="'side'" [opened]="true" [disableClose]="true">
                <app-side-menu></app-side-menu>
            </mat-sidenav>
            <mat-sidenav-content class="pl-8">
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>

    </div>

    <app-web-footer></app-web-footer>

</section> -->

<ng-template #loading>
    <div class="flex justify-center items-center h-screen">
        <canvas riv="general-loading" width="50" height="50">
            <riv-animation name="animation" play></riv-animation>
        </canvas>
    </div>
</ng-template>


<div class="flex flex-col w-[100vw] min-h-[100vh] justify-between items-center overflow-x-hidden ">
    <app-web-header></app-web-header>

    <div class="flex flex-col h-full flex-1 justify-start w-[1080px] ">
        <mat-sidenav-container class="container mt-8 min-h-[678px] overflow-y-hidden ">
            <mat-sidenav [mode]="'side'" [opened]="true" [disableClose]="true" class="">
                <app-side-menu></app-side-menu>
            </mat-sidenav>
            <mat-sidenav-content>
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>

    </div>
    <app-web-footer></app-web-footer>
</div>