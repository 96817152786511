import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PasswordComponent } from './security/password/password.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [MatButtonModule, RouterModule, MatIconModule, PasswordComponent, MatCardModule],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent implements OnInit {
  constructor(private router: Router, public dialog: MatDialog) { }

  ngOnInit() { }

  deleteAccount() {
    const dialogRef = this.dialog.open(DeleteAccountComponent, {
      width: '695px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.location.reload();
      }
    });
  }
  // navigateToMyApps() {
  //   this.router.navigate(['apps']);
  // }
}
