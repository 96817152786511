import { createAction, props } from "@ngrx/store";
import { App } from "../../beans/App";

export const ConnectedAppsActions = {
    GET_APPS: createAction('[Apps] GET Apps'),
    GET_APPS_SUCCESS: createAction('[Apps] GET Apps Success', props<{ apps: Array<App> }>()),
    GET_APPS_ERROR: createAction('[Apps] GET Apps Error ', props<{ errorMsg: string }>()),

    REMOVE_APP: createAction('[App] REMOVE App', props<{ appKey: string }>()),
    REMOVE_APP_SUCCESS: createAction('[App] REMOVE App Success'),
    REMOVE_APP_ERROR: createAction('[App] REMOVE App Error ', props<{ errorMsg: string }>()),
}