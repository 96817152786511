<div
  class="px-6 py-4 rounded-lg border border-outline-variant flex justify-between h-[81px] items-center cursor-pointer"
  (click)="goToDetailPage()">
  <div class="flex flex-row gap-4">
    <div class="flex flex-col items-center justify-center">
      <img [src]="app.logo" class="w-[32px] h-[32px] text-[44px]" alt="App Logo" />
    </div>
    <div class="flex flex-col gap-1">
      <div class="font-generalsans text-base font-medium leading-6 text-left">
        {{ app.app_name }}
      </div>
      <div class="font-generalsans text-sm font-medium leading-6 text-left">
        Access given on {{ date }}
      </div>
    </div>
  </div>
  <div class="flex flex-col items-center justify-center">
    <mat-icon class="material-symbols-outlined text-text-primary">chevron_right</mat-icon>
  </div>
</div>