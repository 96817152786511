import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, EventEmitter, Directive, Input, Output, HostListener, forwardRef, ContentChildren, NgModule } from '@angular/core';
import { Observable, firstValueFrom, BehaviorSubject, from, of, merge } from 'rxjs';
import { share, filter, distinctUntilChanged, switchMap, shareReplay, tap, map } from 'rxjs/operators';
import Rive from '@rive-app/canvas-advanced';
import * as i1 from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
const nextFrame = rive => {
  return new Promise(res => {
    rive.requestAnimationFrame(res);
  });
};
// Observable that trigger on every frame
const animationFrame = rive => new Observable(subscriber => {
  let start = 0;
  let first = true;
  const run = time => {
    const delta = time - start;
    start = time;
    if (first) {
      subscriber.next(16);
      first = false;
    } else {
      subscriber.next(delta);
    }
    // Because of bug in Chrome first value might be too big and cause issues
    if (subscriber.closed) return;
    rive.requestAnimationFrame(run);
  };
  rive.requestAnimationFrame(run);
});
const RIVE_FOLDER = new InjectionToken('Folder with Rive files');
const RIVE_VERSION = new InjectionToken('Version used to load rive WASM');
const RIVE_WASM = new InjectionToken('Local path to rive WASM');
class RiveService {
  constructor(http, folder, wasmPath, version) {
    this.http = http;
    const riveVersion = version ?? '1.1.6';
    this.folder = folder ?? 'assets/rive';
    this.wasmPath = wasmPath ?? `https://unpkg.com/@rive-app/canvas-advanced@${riveVersion}/rive.wasm`;
  }
  async getRive() {
    if (!this.rive) {
      const locateFile = () => this.wasmPath;
      this.rive = await Rive({
        locateFile
      });
      this.frame = animationFrame(this.rive).pipe(share());
    }
    return this.rive;
  }
  getAsset(asset) {
    return firstValueFrom(this.http.get(asset, {
      responseType: 'arraybuffer'
    }));
  }
  /** Load a riv file */
  async load(file) {
    // Provide the file directly
    if (typeof file !== 'string') {
      const [rive, buffer] = await Promise.all([this.getRive(), file.arrayBuffer()]);
      return rive?.load(new Uint8Array(buffer));
    }
    const asset = `${this.folder}/${file}.riv`;
    const [rive, buffer] = await Promise.all([this.getRive(), this.getAsset(asset)]);
    if (!rive) throw new Error('Could not load rive');
    return rive.load(new Uint8Array(buffer));
  }
}
RiveService.ɵfac = function RiveService_Factory(t) {
  return new (t || RiveService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(RIVE_FOLDER, 8), i0.ɵɵinject(RIVE_WASM, 8), i0.ɵɵinject(RIVE_VERSION, 8));
};
RiveService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: RiveService,
  factory: RiveService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RiveService, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.HttpClient
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [RIVE_FOLDER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [RIVE_WASM]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [RIVE_VERSION]
      }]
    }];
  }, null);
})();
function toInt(value) {
  const v = typeof value === 'string' ? parseInt(value) : value;
  if (typeof v !== 'number') return;
  return v;
}
function toFloat(value) {
  const v = typeof value === 'string' ? parseFloat(value) : value;
  if (typeof v !== 'number') return;
  return v;
}
function toBool(value) {
  if (value === '' || value === true) return true;
  if (value === false) return false;
  return;
}
function getAnimations(artboard) {
  const animations = [];
  if (!artboard) return [];
  const max = artboard.animationCount();
  for (let i = 0; i < max; i++) {
    animations.push(artboard.animationByIndex(i));
  }
  return animations;
}
function getStateMachines(artboard) {
  const stateMachines = [];
  if (!artboard) return [];
  const max = artboard.stateMachineCount();
  for (let i = 0; i < max; i++) {
    stateMachines.push(artboard.stateMachineByIndex(i));
  }
  return stateMachines;
}
function getClientCoordinates(event) {
  if (["touchstart", "touchmove"].indexOf(event.type) > -1 && event.touches?.length) {
    event.preventDefault();
    return {
      clientX: event.touches[0].clientX,
      clientY: event.touches[0].clientY
    };
  } else if (event.type === "touchend" && event.changedTouches?.length) {
    return {
      clientX: event.changedTouches[0].clientX,
      clientY: event.changedTouches[0].clientY
    };
  } else {
    return {
      clientX: event.clientX,
      clientY: event.clientY
    };
  }
}
;
const exist$3 = v => v !== null && v !== undefined;
const onVisible = element => new Promise((res, rej) => {
  // SSR
  if (typeof window === 'undefined') {
    return res(false);
  }
  // Compatibility
  if (!('IntersectionObserver' in window)) {
    return res(true);
  }
  let isVisible = false;
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const visible = entry.intersectionRatio !== 0;
      if (visible !== isVisible) {
        res(isVisible);
        observer.disconnect();
      }
    });
  }, {
    threshold: [0]
  });
  // start observing element visibility
  observer.observe(element);
});
// Force event to run inside zones
function enterZone(zone) {
  return source => new Observable(observer => source.subscribe({
    next: x => zone.run(() => observer.next(x)),
    error: err => observer.error(err),
    complete: () => observer.complete()
  }));
}
class RiveCanvasDirective {
  constructor(service, element) {
    this.service = service;
    this.url = new BehaviorSubject(null);
    this.arboardName = new BehaviorSubject(null);
    this.boxes = {};
    // Keep track of current state machine for event listeners
    this.stateMachines = {};
    this.viewbox = '0 0 100% 100%';
    this.lazy = false;
    this.fit = 'contain';
    this.alignment = 'center';
    this.artboardChange = new EventEmitter();
    this.canvas = element.nativeElement;
    this.whenVisible = onVisible(element.nativeElement);
    this.loaded = this.url.pipe(filter(exist$3), distinctUntilChanged(), filter(() => typeof window !== 'undefined' && !!this.ctx),
    // Make sure it's not ssr
    switchMap(async url => {
      this.file = await this.service.load(url);
      this.rive = this.service.rive;
      if (!this.rive) throw new Error('Service could not load rive');
      // TODO: set offscreen renderer to true for webgl
      this.renderer = this.rive.makeRenderer(this.canvas);
    }), switchMap(_ => this.setArtboard()), shareReplay({
      bufferSize: 1,
      refCount: true
    }));
  }
  set riv(url) {
    this.url.next(url);
  }
  set name(name) {
    this.arboardName.next(name);
  }
  set width(w) {
    const width = toInt(w) ?? this.canvas.width;
    this.canvas.width = width;
  }
  get width() {
    return this.canvas.width;
  }
  set height(h) {
    const height = toInt(h) ?? this.canvas.height;
    this.canvas.height = height;
  }
  get height() {
    return this.canvas.height;
  }
  pointerMove(event) {
    const stateMachines = Object.values(this.stateMachines).filter(sm => 'pointerMove' in sm);
    if (!stateMachines.length) return;
    const vector = this.getTransform(event);
    if (!vector) return;
    for (const stateMachine of stateMachines) {
      stateMachine.pointerMove(vector.x, vector.y);
    }
  }
  pointerDown(event) {
    const stateMachines = Object.values(this.stateMachines).filter(sm => 'pointerDown' in sm);
    if (!stateMachines.length) return;
    const vector = this.getTransform(event);
    if (!vector) return;
    for (const stateMachine of stateMachines) {
      stateMachine.pointerDown(vector.x, vector.y);
    }
  }
  pointerUp(event) {
    const stateMachines = Object.values(this.stateMachines).filter(sm => 'pointerUp' in sm);
    if (!stateMachines.length) return;
    const vector = this.getTransform(event);
    if (!vector) return;
    for (const stateMachine of stateMachines) {
      stateMachine.pointerUp(vector.x, vector.y);
    }
  }
  ngOnInit() {
    this.onReady();
  }
  ngOnDestroy() {
    // Timeout to avoid late request to a deleted artboard
    setTimeout(() => {
      this.renderer?.delete();
      this.artboard?.delete();
      this.file?.delete();
    }, 100);
  }
  get ctx() {
    if (!this._ctx) {
      this._ctx = this.canvas.getContext('2d');
    }
    return this._ctx;
  }
  setArtboard() {
    return this.arboardName.pipe(tap(() => this.artboard?.delete()),
    // Remove previous artboard if any
    map(name => name ? this.file?.artboardByName(name) : this.file?.defaultArtboard()), tap(artboard => this.artboard = artboard), tap(() => this.artboardChange.emit(this.artboard)), map(() => true));
  }
  /**
   * Calculate the box of the canvas based on viewbox, width and height
   * It memorizes the values to avoid recalculation for each frame
   */
  get box() {
    const w = this.width;
    const h = this.height;
    const boxId = `${this.viewbox} ${w} ${h}`;
    if (!this.boxes[boxId]) {
      const bounds = this.viewbox.split(' ');
      if (bounds.length !== 4) throw new Error('View box should look like "0 0 100% 100%"');
      const [minX, minY, maxX, maxY] = bounds.map((v, i) => {
        const size = i % 2 === 0 ? w : h;
        const percentage = v.endsWith('%') ? parseInt(v.slice(0, -1), 10) / 100 : parseInt(v, 10) / size;
        return i < 2 ? -size * percentage : size / percentage;
      });
      this.boxes[boxId] = {
        minX,
        minY,
        maxX,
        maxY
      };
    }
    return this.boxes[boxId];
  }
  get isLazy() {
    return this.lazy === true || this.lazy === '';
  }
  get count() {
    return this.artboard?.animationCount();
  }
  onReady() {
    if (this.isLazy) {
      return from(this.whenVisible).pipe(filter(isVisible => isVisible), switchMap(() => this.loaded));
    }
    return this.loaded;
  }
  draw(instance, delta, mix) {
    if (!this.rive) throw new Error('Could not load rive before registrating instance');
    if (!this.artboard) throw new Error('Could not load artboard before registrating instance');
    if (!this.renderer) throw new Error('Could not load renderer before registrating instance');
    this.renderer.clear();
    // Move frame
    if (isLinearAnimation(instance)) {
      instance.advance(delta);
      instance.apply(mix ?? 1);
    } else {
      instance.advance(delta);
    }
    this.artboard.advance(delta);
    // Render frame on canvas
    this.renderer.save();
    // Align renderer if needed
    const fit = this.rive.Fit[this.fit];
    const alignment = this.rive.Alignment[this.alignment];
    const box = this.box;
    const bounds = this.artboard.bounds;
    this.renderer.align(fit, alignment, box, bounds);
    this.artboard.draw(this.renderer);
    this.renderer.restore();
    // TODO: If context is WebGL Flush
    // this.renderer.flush();
  }
  getTransform(event) {
    if (!this.rive) return;
    if (!this.artboard) return;
    const boundingRect = this.canvas.getBoundingClientRect();
    const {
      clientX,
      clientY
    } = getClientCoordinates(event);
    if (!clientX && !clientY) return;
    const canvasX = clientX - boundingRect.left;
    const canvasY = clientY - boundingRect.top;
    const forwardMatrix = this.rive.computeAlignment(this.rive.Fit[this.fit], this.rive.Alignment[this.alignment], {
      minX: 0,
      minY: 0,
      maxX: boundingRect.width,
      maxY: boundingRect.height
    }, this.artboard.bounds);
    const invertedMatrix = new this.rive.Mat2D();
    forwardMatrix.invert(invertedMatrix);
    const canvasCoordinatesVector = new this.rive.Vec2D(canvasX, canvasY);
    const transformedVector = this.rive.mapXY(invertedMatrix, canvasCoordinatesVector);
    const x = transformedVector.x();
    const y = transformedVector.y();
    transformedVector.delete();
    invertedMatrix.delete();
    canvasCoordinatesVector.delete();
    forwardMatrix.delete();
    return {
      x,
      y
    };
  }
}
RiveCanvasDirective.ɵfac = function RiveCanvasDirective_Factory(t) {
  return new (t || RiveCanvasDirective)(i0.ɵɵdirectiveInject(RiveService), i0.ɵɵdirectiveInject(i0.ElementRef));
};
RiveCanvasDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RiveCanvasDirective,
  selectors: [["canvas", "riv", ""]],
  hostBindings: function RiveCanvasDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("touchmove", function RiveCanvasDirective_touchmove_HostBindingHandler($event) {
        return ctx.pointerMove($event);
      })("mouseover", function RiveCanvasDirective_mouseover_HostBindingHandler($event) {
        return ctx.pointerMove($event);
      })("mouseout", function RiveCanvasDirective_mouseout_HostBindingHandler($event) {
        return ctx.pointerMove($event);
      })("mousemove", function RiveCanvasDirective_mousemove_HostBindingHandler($event) {
        return ctx.pointerMove($event);
      })("touchstart", function RiveCanvasDirective_touchstart_HostBindingHandler($event) {
        return ctx.pointerDown($event);
      })("mousedown", function RiveCanvasDirective_mousedown_HostBindingHandler($event) {
        return ctx.pointerDown($event);
      })("touchend", function RiveCanvasDirective_touchend_HostBindingHandler($event) {
        return ctx.pointerUp($event);
      })("mouseup", function RiveCanvasDirective_mouseup_HostBindingHandler($event) {
        return ctx.pointerUp($event);
      });
    }
  },
  inputs: {
    riv: "riv",
    name: [0, "artboard", "name"],
    viewbox: "viewbox",
    lazy: "lazy",
    fit: "fit",
    alignment: "alignment",
    width: "width",
    height: "height"
  },
  outputs: {
    artboardChange: "artboardChange"
  },
  exportAs: ["rivCanvas"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RiveCanvasDirective, [{
    type: Directive,
    args: [{
      selector: 'canvas[riv]',
      exportAs: 'rivCanvas'
    }]
  }], function () {
    return [{
      type: RiveService
    }, {
      type: i0.ElementRef
    }];
  }, {
    riv: [{
      type: Input
    }],
    name: [{
      type: Input,
      args: ['artboard']
    }],
    viewbox: [{
      type: Input
    }],
    lazy: [{
      type: Input
    }],
    fit: [{
      type: Input
    }],
    alignment: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    artboardChange: [{
      type: Output
    }],
    pointerMove: [{
      type: HostListener,
      args: ['touchmove', ['$event']]
    }, {
      type: HostListener,
      args: ['mouseover', ['$event']]
    }, {
      type: HostListener,
      args: ['mouseout', ['$event']]
    }, {
      type: HostListener,
      args: ['mousemove', ['$event']]
    }],
    pointerDown: [{
      type: HostListener,
      args: ['touchstart', ['$event']]
    }, {
      type: HostListener,
      args: ['mousedown', ['$event']]
    }],
    pointerUp: [{
      type: HostListener,
      args: ['touchend', ['$event']]
    }, {
      type: HostListener,
      args: ['mouseup', ['$event']]
    }]
  });
})();
function isLinearAnimation(instance) {
  return 'didLoop' in instance;
}
function getRivePlayerState(state = {}) {
  return {
    speed: 1,
    playing: false,
    mix: 1,
    autoreset: false,
    ...state
  };
}
function frameToSec(frame, fps) {
  return frame / fps;
}
function round(value) {
  return Math.round((value + Number.EPSILON) * 10000) / 10000;
}
function exist$2(v) {
  return v !== undefined && v !== null;
}
function getStart(animation) {
  if (!animation.workStart || animation.workStart === -1) return 0;
  return round(animation.workStart / animation.fps);
}
function getEnd(animation) {
  const end = !animation.workEnd || animation.workEnd === -1 ? animation.duration : animation.workEnd;
  return round(end / animation.fps);
}
class RivePlayer {
  constructor(zone, canvas, service) {
    this.zone = zone;
    this.canvas = canvas;
    this.service = service;
    this.distance = new BehaviorSubject(null);
    this.state = new BehaviorSubject(getRivePlayerState());
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.load = new EventEmitter();
    this.timeChange = new EventEmitter();
    /** @deprecated will be removed */
    this.playChange = new EventEmitter();
    /** @deprecated will be removed */
    this.speedChange = new EventEmitter();
  }
  /**
   * Name of the rive animation in the current Artboard
   * Either use name or index to select an animation
   */
  set name(name) {
    if (typeof name !== 'string') return;
    this.zone.runOutsideAngular(() => {
      this.register(name);
    });
  }
  /**
   * Index of the rive animation in the current Artboard
   * Either use index of name to select an animation
   */
  set index(value) {
    const index = typeof value === 'string' ? parseInt(value) : value;
    if (typeof index !== 'number') return;
    this.zone.runOutsideAngular(() => {
      this.register(index);
    });
  }
  /** The mix of this animation in the current arboard */
  set mix(value) {
    const mix = typeof value === 'string' ? parseFloat(value) : value;
    if (mix && mix >= 0 && mix <= 1) this.update({
      mix
    });
  }
  get mix() {
    return this.state.getValue().mix;
  }
  /** Multiplicator of the speed for the animation */
  set speed(value) {
    const speed = typeof value === 'string' ? parseFloat(value) : value;
    if (typeof speed === 'number') this.update({
      speed
    });
  }
  get speed() {
    return this.state.getValue().speed;
  }
  set play(playing) {
    if (playing === true || playing === '') {
      this.update({
        playing: true
      });
    } else if (playing === false) {
      this.update({
        playing: false
      });
    }
  }
  get play() {
    return this.state.getValue().playing;
  }
  set time(value) {
    const time = typeof value === 'string' ? parseFloat(value) : value;
    if (typeof time === 'number') this.distance.next(time);
  }
  /**
   * @deprecated This will be removed
   * Consider using StateMachine instead
   */
  set autoreset(autoreset) {
    if (autoreset === true || autoreset === '') {
      this.update({
        autoreset: true
      });
    } else if (autoreset === false) {
      this.update({
        autoreset: false
      });
    }
  }
  get autoreset() {
    return this.state.getValue().autoreset;
  }
  /**
   * @deprecated This will be removed
   * Consider using StateMachine instead
   */
  set mode(mode) {
    if (mode) this.update({
      mode
    });
  }
  get mode() {
    return this.state.getValue().mode;
  }
  ngOnDestroy() {
    this.sub?.unsubscribe();
    setTimeout(() => this.instance?.delete(), 100);
  }
  update(state) {
    const next = getRivePlayerState({
      ...this.state.getValue(),
      ...state
    });
    this.state.next(next);
  }
  initAnimation(name) {
    if (!this.service.rive) throw new Error('Could not load animation instance before rive');
    if (!this.canvas.artboard) throw new Error('Could not load animation instance before artboard');
    const ref = typeof name === 'string' ? this.canvas.artboard.animationByName(name) : this.canvas.artboard.animationByIndex(name);
    this.animation = ref;
    this.instance = new this.service.rive.LinearAnimationInstance(ref, this.canvas.artboard);
    this.startTime = getStart(this.instance);
    this.endTime = getEnd(this.instance);
    this.load.emit(this.instance);
  }
  getFrame(state) {
    if (state.playing && this.service.frame) {
      return this.service.frame.pipe(map(time => [state, time]));
    } else {
      return of(null);
    }
  }
  register(name) {
    this.sub?.unsubscribe(); // Stop subscribing to previous animation if any
    this.instance?.delete(); // Remove old instance if any
    // Update if time have changed from the input
    const onTimeChange = this.distance.pipe(filter(exist$2), distinctUntilChanged(), map(time => time - this.instance.time));
    // Update on frame change if playing
    const onFrameChange = this.state.pipe(switchMap(state => this.getFrame(state)), filter(exist$2), map(([state, time]) => this.moveFrame(state, time)), tap(delta => {
      this.zone.run(() => this.timeChange.emit(this.instance.time + delta));
    }));
    // Wait for canvas & animation to be loaded
    this.sub = this.canvas.onReady().pipe(map(() => this.initAnimation(name)), switchMap(() => merge(onTimeChange, onFrameChange))).subscribe(delta => this.applyChange(delta));
  }
  moveFrame(state, time) {
    if (!this.instance) throw new Error('Could not load animation instance before running it');
    if (!this.animation) throw new Error('Could not load animation before running it');
    const {
      speed,
      autoreset,
      mode
    } = state;
    // Default mode, don't apply any logic
    if (!mode) return time / 1000 * speed;
    let delta = time / 1000 * speed;
    // Round to avoid JS error on division
    const start = this.startTime ?? 0;
    const end = this.endTime ?? this.instance.duration / this.instance.fps;
    const currentTime = round(this.instance.time);
    // When player hit floor
    if (currentTime + delta < start) {
      if (mode === 'loop' && speed < 0 && end) {
        delta = end - currentTime; // end - currentTime
      } else if (mode === 'ping-pong') {
        delta = -delta;
        this.update({
          speed: -speed
        });
        this.zone.run(() => this.speedChange.emit(-speed));
      } else if (mode === 'one-shot') {
        this.update({
          playing: false
        });
        this.zone.run(() => this.playChange.emit(false));
        delta = start - currentTime;
      }
    }
    // Put before "hit last frame" else currentTime + delta > end
    if (mode === 'one-shot' && autoreset) {
      if (speed > 0 && currentTime === end) {
        delta = start - end;
      }
      if (speed < 0 && currentTime === start) {
        delta = end - start;
      }
    }
    // When player hit last frame
    if (currentTime + delta > end) {
      if (mode === 'loop' && speed > 0) {
        delta = start - currentTime;
      } else if (mode === 'ping-pong') {
        delta = -delta;
        this.update({
          speed: -speed
        });
        this.zone.run(() => this.speedChange.emit(-speed));
      } else if (mode === 'one-shot') {
        this.update({
          playing: false
        });
        this.zone.run(() => this.playChange.emit(false));
        delta = end - currentTime;
      }
    }
    return delta;
  }
  applyChange(delta) {
    // We need to use requestAnimationFrame when delta is changed by the time
    this.service.rive?.requestAnimationFrame(() => {
      if (!this.instance) throw new Error('Could not load animation instance before running it');
      this.canvas.draw(this.instance, delta, this.state.getValue().mix);
    });
  }
}
RivePlayer.ɵfac = function RivePlayer_Factory(t) {
  return new (t || RivePlayer)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(RiveCanvasDirective), i0.ɵɵdirectiveInject(RiveService));
};
RivePlayer.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RivePlayer,
  selectors: [["riv-player"], ["", "rivPlayer", ""]],
  inputs: {
    name: "name",
    index: "index",
    mix: "mix",
    speed: "speed",
    play: "play",
    time: "time",
    autoreset: "autoreset",
    mode: "mode"
  },
  outputs: {
    load: "load",
    timeChange: "timeChange",
    playChange: "playChange",
    speedChange: "speedChange"
  },
  exportAs: ["rivPlayer"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RivePlayer, [{
    type: Directive,
    args: [{
      selector: 'riv-player, [rivPlayer]',
      exportAs: 'rivPlayer'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: RiveCanvasDirective
    }, {
      type: RiveService
    }];
  }, {
    name: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    mix: [{
      type: Input
    }],
    speed: [{
      type: Input
    }],
    play: [{
      type: Input
    }],
    time: [{
      type: Input
    }],
    autoreset: [{
      type: Input
    }],
    mode: [{
      type: Input
    }],
    load: [{
      type: Output
    }],
    timeChange: [{
      type: Output
    }],
    playChange: [{
      type: Output
    }],
    speedChange: [{
      type: Output
    }]
  });
})();
function getRiveAnimationState(state = {}) {
  return {
    speed: 1,
    playing: false,
    mix: 1,
    ...state
  };
}
function exist$1(v) {
  return v !== undefined && v !== null;
}
function assertAnimation(animation, artboard, name) {
  if (animation) return;
  const artboardName = artboard.name ?? 'Default';
  const count = artboard.animationCount();
  if (typeof name === 'number') {
    throw new Error(`Provided index "${name}" for the animation of artboard "${artboardName}" is not available. Animation count is: ${count}`);
  } else {
    const names = [];
    for (let i = 0; i < count; i++) {
      names.push(artboard.animationByIndex(i).name);
    }
    throw new Error(`Provided name "${name}" for the animation of artboard "${artboardName}" is not available. Availables names are: ${JSON.stringify(names)}`);
  }
}
class RiveAnimationDirective {
  constructor(zone, canvas, service) {
    this.zone = zone;
    this.canvas = canvas;
    this.service = service;
    this.distance = new BehaviorSubject(null);
    this.state = new BehaviorSubject(getRiveAnimationState());
    /** Emit when the LinearAnimation has been instantiated */
    this.load = new EventEmitter();
  }
  /**
   * Name of the rive animation in the current Artboard
   * Either use name or index to select an animation
   */
  set name(name) {
    if (typeof name !== 'string') return;
    this.zone.runOutsideAngular(() => {
      this.register(name);
    });
  }
  /**
   * Index of the rive animation in the current Artboard
   * Either use index of name to select an animation
   */
  set index(value) {
    const index = typeof value === 'string' ? parseInt(value) : value;
    if (typeof index !== 'number') return;
    this.zone.runOutsideAngular(() => {
      this.register(index);
    });
  }
  /** The mix of this animation in the current arboard */
  set mix(value) {
    const mix = typeof value === 'string' ? parseFloat(value) : value;
    if (mix && mix >= 0 && mix <= 1) this.update({
      mix
    });
  }
  get mix() {
    return this.state.getValue().mix;
  }
  /** Multiplicator for the speed of the animation */
  set speed(value) {
    const speed = typeof value === 'string' ? parseFloat(value) : value;
    if (typeof speed === 'number') this.update({
      speed
    });
  }
  get speed() {
    return this.state.getValue().speed;
  }
  /** If true, this animation is playing */
  set play(playing) {
    if (playing === true || playing === '') {
      this.update({
        playing: true
      });
    } else if (playing === false) {
      this.update({
        playing: false
      });
    }
  }
  get play() {
    return this.state.getValue().playing;
  }
  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.instance?.delete();
    setTimeout(() => this.instance?.delete(), 100);
  }
  update(state) {
    const next = getRiveAnimationState({
      ...this.state.getValue(),
      ...state
    });
    this.state.next(next);
  }
  getFrame(state) {
    if (state.playing && this.service.frame) {
      return this.service.frame.pipe(map(time => [state, time]));
    } else {
      return of(null);
    }
  }
  initAnimation(name) {
    if (!this.canvas.rive) throw new Error('Could not load animation instance before rive');
    if (!this.canvas.artboard) throw new Error('Could not load animation instance before artboard');
    const ref = typeof name === 'string' ? this.canvas.artboard.animationByName(name) : this.canvas.artboard.animationByIndex(name);
    assertAnimation(ref, this.canvas.artboard, name);
    this.instance = new this.canvas.rive.LinearAnimationInstance(ref, this.canvas.artboard);
    this.load.emit(this.instance);
  }
  register(name) {
    // Stop subscribing to previous animation if any
    this.sub?.unsubscribe();
    // Update on frame change if playing
    const onFrameChange = this.state.pipe(switchMap(state => this.getFrame(state)), filter(exist$1), map(([state, time]) => time / 1000 * state.speed));
    // Wait for canvas & animation to be loaded
    this.sub = this.canvas.onReady().pipe(map(() => this.initAnimation(name)), switchMap(() => onFrameChange)).subscribe(delta => this.applyChange(delta));
  }
  applyChange(delta) {
    if (!this.instance) throw new Error('Could not load animation instance before running it');
    this.canvas.draw(this.instance, delta, this.state.getValue().mix);
  }
}
RiveAnimationDirective.ɵfac = function RiveAnimationDirective_Factory(t) {
  return new (t || RiveAnimationDirective)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(RiveCanvasDirective), i0.ɵɵdirectiveInject(RiveService));
};
RiveAnimationDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RiveAnimationDirective,
  selectors: [["riv-animation"], ["", "rivAnimation", ""]],
  inputs: {
    name: "name",
    index: "index",
    mix: "mix",
    speed: "speed",
    play: "play"
  },
  outputs: {
    load: "load"
  },
  exportAs: ["rivAnimation"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RiveAnimationDirective, [{
    type: Directive,
    args: [{
      selector: 'riv-animation, [rivAnimation]',
      exportAs: 'rivAnimation'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: RiveCanvasDirective
    }, {
      type: RiveService
    }];
  }, {
    name: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    mix: [{
      type: Input
    }],
    speed: [{
      type: Input
    }],
    play: [{
      type: Input
    }],
    load: [{
      type: Output
    }]
  });
})();
class RiveTransformComponent {
  constructor(zone, canvas) {
    this.zone = zone;
    this.canvas = canvas;
    this.state = {};
  }
  set name(name) {
    if (typeof name !== 'string') return;
    this.canvas.onReady().subscribe(() => {
      this.component = this.getComponent(name);
      if (!this.component) throw new Error(`Could not find component with name: "${name}"`);
      for (const key in this.state) {
        this.component[key] = this.state[key];
      }
    });
  }
  set scale(value) {
    this.set('scaleX', value);
    this.set('scaleY', value);
  }
  set scaleX(value) {
    this.set('scaleX', value);
  }
  set scaleY(value) {
    this.set('scaleX', value);
  }
  set rotation(value) {
    const v = typeof value === 'string' ? parseFloat(value) : value;
    if (v) {
      const rotation = Math.abs(v) > 2 * Math.PI ? v * (Math.PI / 180) : v;
      this.set('rotation', rotation);
    }
  }
  set(key, value) {
    this.zone.runOutsideAngular(() => {
      const v = typeof value === 'string' ? parseFloat(value) : value;
      if (typeof v === 'number') {
        if (this.component) this.component[key] = v;else this.state[key] = v;
      }
    });
  }
}
RiveTransformComponent.ɵfac = function RiveTransformComponent_Factory(t) {
  return new (t || RiveTransformComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(RiveCanvasDirective));
};
RiveTransformComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RiveTransformComponent,
  inputs: {
    name: "name",
    scale: "scale",
    scaleX: "scaleX",
    scaleY: "scaleY",
    rotation: "rotation"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RiveTransformComponent, [{
    type: Directive
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: RiveCanvasDirective
    }];
  }, {
    name: [{
      type: Input
    }],
    scale: [{
      type: Input
    }],
    scaleX: [{
      type: Input
    }],
    scaleY: [{
      type: Input
    }],
    rotation: [{
      type: Input
    }]
  });
})();
class RiveNode extends RiveTransformComponent {
  constructor(zone, canvas) {
    super(zone, canvas);
  }
  set x(value) {
    this.set('x', value);
  }
  get x() {
    return this.component?.x;
  }
  set y(value) {
    this.set('y', value);
  }
  get y() {
    return this.component?.y;
  }
  getComponent(name) {
    return this.canvas.artboard?.node(name);
  }
}
RiveNode.ɵfac = function RiveNode_Factory(t) {
  return new (t || RiveNode)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(RiveCanvasDirective));
};
RiveNode.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RiveNode,
  selectors: [["riv-node"], ["", "rivNode", ""]],
  inputs: {
    x: "x",
    y: "y"
  },
  exportAs: ["rivNode"],
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RiveNode, [{
    type: Directive,
    args: [{
      selector: 'riv-node, [rivNode]',
      exportAs: 'rivNode'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: RiveCanvasDirective
    }];
  }, {
    x: [{
      type: Input
    }],
    y: [{
      type: Input
    }]
  });
})();
class RiveBone extends RiveTransformComponent {
  constructor(zone, canvas) {
    super(zone, canvas);
  }
  set length(value) {
    this.set('length', value);
  }
  getComponent(name) {
    return this.canvas.artboard?.bone(name);
  }
}
RiveBone.ɵfac = function RiveBone_Factory(t) {
  return new (t || RiveBone)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(RiveCanvasDirective));
};
RiveBone.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RiveBone,
  selectors: [["riv-bone"], ["", "rivBone", ""]],
  inputs: {
    length: "length"
  },
  exportAs: ["rivBone"],
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RiveBone, [{
    type: Directive,
    args: [{
      selector: 'riv-bone, [rivBone]',
      exportAs: 'rivBone'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: RiveCanvasDirective
    }];
  }, {
    length: [{
      type: Input
    }]
  });
})();
class RiveRootBone extends RiveTransformComponent {
  constructor(zone, canvas) {
    super(zone, canvas);
  }
  set x(value) {
    this.set('x', value);
  }
  set y(value) {
    this.set('y', value);
  }
  set length(value) {
    this.set('length', value);
  }
  getComponent(name) {
    return this.canvas.artboard?.rootBone(name);
  }
}
RiveRootBone.ɵfac = function RiveRootBone_Factory(t) {
  return new (t || RiveRootBone)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(RiveCanvasDirective));
};
RiveRootBone.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RiveRootBone,
  selectors: [["riv-root-bone"], ["", "rivRootBone", ""]],
  inputs: {
    x: "x",
    y: "y",
    length: "length"
  },
  exportAs: ["rivRootBone"],
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RiveRootBone, [{
    type: Directive,
    args: [{
      selector: 'riv-root-bone, [rivRootBone]',
      exportAs: 'rivRootBone'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: RiveCanvasDirective
    }];
  }, {
    x: [{
      type: Input
    }],
    y: [{
      type: Input
    }],
    length: [{
      type: Input
    }]
  });
})();
function getInput(input) {
  if (input.type === 56 /* InputTypes.Number */) return input.asNumber();
  if (input.type === 59 /* InputTypes.Boolean */) return input.asBool();
  if (input.type === 58 /* InputTypes.Trigger */) return input.asTrigger();
  return input;
}
function assertStateMachine(animation, artboard, name) {
  if (animation) return;
  const artboardName = artboard.name ?? 'Default';
  const count = artboard.stateMachineCount();
  if (typeof name === 'number') {
    throw new Error(`Provided index "${name}" for the animation of artboard "${artboardName}" is not available. Animation count is: ${count}`);
  } else {
    const names = [];
    for (let i = 0; i < count; i++) {
      names.push(artboard.stateMachineByIndex(i).name);
    }
    throw new Error(`Provided name "${name}" for the animation of artboard "${artboardName}" is not available. Availables names are: ${JSON.stringify(names)}`);
  }
}
class RiveSMInput {
  constructor(stateMachine) {
    this.stateMachine = stateMachine;
    this.change = new EventEmitter();
    this.load = new EventEmitter();
  }
  set name(name) {
    if (!name) return;
    this._name = name;
    if (this.input) return;
    this.init(this.stateMachine.inputs[name]);
  }
  get name() {
    return this.input?.name ?? this._name;
  }
  set value(rawValue) {
    if (typeof rawValue === 'undefined' || rawValue === null) return;
    const value = typeof rawValue === 'string' ? parseFloat(rawValue) : rawValue;
    if (this.input) {
      this.input.value = value;
      this.change.emit(this.input);
    } else {
      this._value = value;
    }
  }
  get value() {
    return this.input?.value ?? this._value;
  }
  /** @internal: Used by the RiveStateMachine */
  init(input) {
    if (!input || input.name === this.input?.name) return;
    this.input = getInput(input);
    this.load.emit(input);
    if (typeof this._value !== 'undefined') {
      this.input.value = this._value;
      this.change.emit(this.input);
    }
    if (this.shouldFire) {
      this.shouldFire(input);
      delete this.shouldFire;
    }
  }
  fire() {
    const fire = input => {
      if (input.type === 58 /* InputTypes.Trigger */) {
        input.fire();
        this.change.emit(input);
      }
    };
    this.input ? fire(this.input) : this.shouldFire = fire;
  }
}
RiveSMInput.ɵfac = function RiveSMInput_Factory(t) {
  return new (t || RiveSMInput)(i0.ɵɵdirectiveInject(forwardRef(() => RiveStateMachine)));
};
RiveSMInput.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RiveSMInput,
  selectors: [["riv-input"], ["", "rivInput", ""]],
  inputs: {
    name: "name",
    value: "value"
  },
  outputs: {
    change: "change",
    load: "load"
  },
  exportAs: ["rivInput"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RiveSMInput, [{
    type: Directive,
    args: [{
      selector: 'riv-input, [rivInput]',
      exportAs: 'rivInput'
    }]
  }], function () {
    return [{
      type: RiveStateMachine,
      decorators: [{
        type: Inject,
        args: [forwardRef(() => RiveStateMachine)]
      }]
    }];
  }, {
    name: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    change: [{
      type: Output
    }],
    load: [{
      type: Output
    }]
  });
})();
function exist(v) {
  return v !== undefined && v !== null;
}
class RiveStateMachine {
  constructor(zone, canvas, service) {
    this.zone = zone;
    this.canvas = canvas;
    this.service = service;
    this.state = new BehaviorSubject({
      speed: 1,
      playing: false
    });
    this.inputs = {};
    this.load = new EventEmitter();
    this.stateChange = new EventEmitter();
  }
  set name(name) {
    if (typeof name !== 'string') return;
    this.zone.runOutsideAngular(() => {
      this.register(name);
    });
  }
  set index(value) {
    const index = typeof value === 'string' ? parseInt(value) : value;
    if (typeof index !== 'number') return;
    this.zone.runOutsideAngular(() => {
      this.register(index);
    });
  }
  set speed(value) {
    const speed = typeof value === 'string' ? parseFloat(value) : value;
    if (typeof speed === 'number') this.update({
      speed
    });
  }
  get speed() {
    return this.state.getValue().speed;
  }
  set play(playing) {
    if (playing === true || playing === '') {
      this.update({
        playing: true
      });
    } else if (playing === false) {
      this.update({
        playing: false
      });
    }
  }
  get play() {
    return this.state.getValue().playing;
  }
  ngOnDestroy() {
    const name = this.instance?.name;
    if (name) delete this.canvas.stateMachines[name];
    this.sub?.unsubscribe();
    setTimeout(() => this.instance?.delete(), 100);
  }
  update(state) {
    this.state.next({
      ...this.state.getValue(),
      ...state
    });
  }
  setInput(input) {
    this.inputs[input.name] = input;
    const riveInput = this.riveInputs?.find(item => item.name === input.name);
    if (riveInput) {
      riveInput.init(input);
    }
  }
  getFrame(state) {
    if (state.playing && this.service.frame) {
      return this.service.frame.pipe(map(time => [state, time]));
    } else {
      return of(null);
    }
  }
  initStateMachine(name) {
    if (!this.canvas.rive) throw new Error('Could not load state machine instance before rive');
    if (!this.canvas.artboard) throw new Error('Could not load state machine instance before artboard');
    const ref = typeof name === 'string' ? this.canvas.artboard.stateMachineByName(name) : this.canvas.artboard.stateMachineByIndex(name);
    assertStateMachine(ref, this.canvas.artboard, name);
    // Fetch the inputs from the runtime if we don't have them
    this.instance = new this.canvas.rive.StateMachineInstance(ref, this.canvas.artboard);
    this.canvas.stateMachines[this.instance.name] = this.instance;
    for (let i = 0; i < this.instance.inputCount(); i++) {
      this.setInput(this.instance.input(i));
    }
    this.load.emit(this.instance);
  }
  register(name) {
    // Stop subscribing to previous animation if any
    this.sub?.unsubscribe();
    // Update on frame change if playing
    const onFrameChange = this.state.pipe(switchMap(state => this.getFrame(state)), filter(exist), map(([state, time]) => time / 1000 * state.speed));
    // Wait for canvas & animation to be loaded
    this.sub = this.canvas.onReady().pipe(map(() => this.initStateMachine(name)), switchMap(() => onFrameChange)).subscribe(delta => this.applyChange(delta));
  }
  applyChange(delta) {
    if (!this.instance) throw new Error('Could not load state machin instance before running it');
    this.canvas.draw(this.instance, delta);
    // Check for any state machines that had a state change
    const changeCount = this.instance.stateChangedCount();
    if (changeCount) {
      const states = [];
      for (let i = 0; i < changeCount; i++) {
        states.push(this.instance.stateChangedNameByIndex(i));
      }
      this.stateChange.emit(states);
    }
  }
}
RiveStateMachine.ɵfac = function RiveStateMachine_Factory(t) {
  return new (t || RiveStateMachine)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(RiveCanvasDirective), i0.ɵɵdirectiveInject(RiveService));
};
RiveStateMachine.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RiveStateMachine,
  selectors: [["riv-state-machine"], ["", "rivStateMachine", ""]],
  contentQueries: function RiveStateMachine_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, RiveSMInput, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.riveInputs = _t);
    }
  },
  inputs: {
    name: "name",
    index: "index",
    speed: "speed",
    play: "play"
  },
  outputs: {
    load: "load",
    stateChange: "stateChange"
  },
  exportAs: ["rivStateMachine"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RiveStateMachine, [{
    type: Directive,
    args: [{
      selector: 'riv-state-machine, [rivStateMachine]',
      exportAs: 'rivStateMachine'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: RiveCanvasDirective
    }, {
      type: RiveService
    }];
  }, {
    riveInputs: [{
      type: ContentChildren,
      args: [RiveSMInput]
    }],
    load: [{
      type: Output
    }],
    stateChange: [{
      type: Output
    }],
    name: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    speed: [{
      type: Input
    }],
    play: [{
      type: Input
    }]
  });
})();
class RiveModule {}
RiveModule.ɵfac = function RiveModule_Factory(t) {
  return new (t || RiveModule)();
};
RiveModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RiveModule
});
RiveModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [RiveService],
  imports: [HttpClientModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RiveModule, [{
    type: NgModule,
    args: [{
      imports: [HttpClientModule],
      declarations: [RiveCanvasDirective, RiveAnimationDirective, RivePlayer, RiveNode, RiveBone, RiveRootBone, RiveSMInput, RiveStateMachine],
      exports: [RiveCanvasDirective, RiveAnimationDirective, RivePlayer, RiveNode, RiveBone, RiveRootBone, RiveSMInput, RiveStateMachine],
      providers: [RiveService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { RIVE_FOLDER, RIVE_VERSION, RIVE_WASM, RiveAnimationDirective, RiveBone, RiveCanvasDirective, RiveModule, RiveNode, RivePlayer, RiveRootBone, RiveSMInput, RiveService, RiveStateMachine, RiveTransformComponent, enterZone, frameToSec, round };
