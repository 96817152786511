import { createSelector } from "@ngrx/store";
import { OutgoingInvitationsSelector } from "../accountant-store.selectors";
import { OutgoingInvitationsModel } from "./outgoing-invitations-store.model";


// Selector to return the outgoing invitations array from the OutgoingInvitationsModel
export const getOutgoingInvitations = () => createSelector(
    OutgoingInvitationsSelector,
    (outgoingInvitationsStore: OutgoingInvitationsModel) => outgoingInvitationsStore?.outgoing_invitations
);

// Selector to return the last evaluated key value from the OutgoingInvitationsModel
export const getOutgoingInvitationsLastEvaluatedKey = () => createSelector(
    OutgoingInvitationsSelector,
    (outgoingInvitationsStore: OutgoingInvitationsModel) => outgoingInvitationsStore?.last_evaluated_key
);