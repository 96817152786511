import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { AccountantRow, InvitationRow, MyAccountantsActions, getOutgoingInvitations, getAccountants, OutgoingInvitationsActions } from 'ngx-com-quicko-myaccount';
import { InviteAccountantDialogComponent } from './invite-accountant-dialog/invite-accountant-dialog.component';
import { UpdateAccountantDialogComponent } from './update-accountant-dialog/update-accountant-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { AccountantRowComponent } from './accountant-row/accountant-row.component';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { OutgoingInvitationRowComponent } from './outgoing-invitation-row/outgoing-invitation-row.component';
import { MatButtonModule } from '@angular/material/button';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-my-accountants',
  standalone: true,
  imports: [AccountantRowComponent, MatCardModule, NgxSkeletonLoaderModule, OutgoingInvitationRowComponent, CommonModule, MatProgressSpinnerModule, MatIconModule, MatButtonModule],
  templateUrl: './my-accountants.component.html',
  styleUrls: ['./my-accountants.component.css']
})
export class MyAccountantsComponent implements OnInit {

  accountantRows$: Observable<Array<AccountantRow>> = this.store.select(getAccountants());
  outgoingInvitationRows$: Observable<Array<InvitationRow>> = this.store.select(getOutgoingInvitations());
  accountantRows: Array<AccountantRow> | null = null;
  outgoingInvitationRows: Array<InvitationRow> | null = null;
  destroy$: Subject<boolean> = new Subject<boolean>()
  loading = false;

  constructor(
    private dialog: MatDialog,
    private actions$: Actions,
    private store: Store) {
    this.accountantRows$.subscribe((accountantRows) => {
      if (accountantRows != undefined && accountantRows.length > 0) {
        this.accountantRows = [];
        this.accountantRows = accountantRows;
      }
    })

    this.outgoingInvitationRows$.subscribe((outgoingInvitationRows) => {
      if (outgoingInvitationRows != undefined && outgoingInvitationRows.length > 0) {
        this.outgoingInvitationRows = [];
        this.outgoingInvitationRows = outgoingInvitationRows;
      }
    })
  }

  ngOnInit() {


    if (this.accountantRows == null && this.outgoingInvitationRows == null) {
      this.loading = true
    }
    else {
      this.getDataFromStore();
    }
    this.getAccountants();
    this.getOutgoingInvitations();

    this.actions$.pipe(
      ofType(OutgoingInvitationsActions.POST_INVITATION_SUCCESS),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.getOutgoingInvitations();
    });

    this.actions$.pipe(
      ofType(MyAccountantsActions.GET_ACCOUNTANTS_ERROR),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.loading = false;
    });

    this.actions$.pipe(
      ofType(OutgoingInvitationsActions.GET_INVITATIONS_ERROR),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.loading = false;
    });
  }

  getAccountants() {
    this.store.dispatch(MyAccountantsActions.GET_ACCOUNTANTS({}));
    this.actions$.pipe(
      ofType(MyAccountantsActions.GET_ACCOUNTANTS_SUCCESS),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.accountantRows$.pipe(takeUntil(this.destroy$)).subscribe((accountantRows) => {
        this.accountantRows = accountantRows;
      })
    })
  }

  getOutgoingInvitations() {
    this.store.dispatch(OutgoingInvitationsActions.GET_INVITATIONS({}));
    this.actions$.pipe(
      ofType(OutgoingInvitationsActions.GET_INVITATIONS_SUCCESS),
      takeUntil(this.destroy$)
    ).subscribe((response) => {
      this.outgoingInvitationRows$.pipe(takeUntil(this.destroy$)).subscribe((outgoingInvitationRows) => {
        this.outgoingInvitationRows = outgoingInvitationRows;
        this.loading = false;
      })
    })
  }

  getDataFromStore() {
    this.accountantRows$.pipe(take(1)).subscribe((accountantRows) => {
      this.accountantRows = accountantRows;
    })

    this.outgoingInvitationRows$.pipe(take(1)).subscribe((outgoingInvitationRows) => {
      this.outgoingInvitationRows = outgoingInvitationRows;
    })

    this.loading = false;
  }


  editAccountant($event: AccountantRow) {
    const accountantRow: AccountantRow = $event;
    this.dialog.open(UpdateAccountantDialogComponent, { data: { accountantRow: accountantRow } });
  }

  editInvitation($event: InvitationRow) {
    const invitationRow: InvitationRow = $event;
    this.dialog.open(InviteAccountantDialogComponent, { data: { invitationRow: invitationRow } });
  }


  deleteInvitation($event: InvitationRow) {
    const invitationRow: InvitationRow = $event;
    console.log("delete invitation")
    console.log(invitationRow)
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {
      title: 'Delete Invitation Confirmation',
      delete_button_text: 'Yes, Delete',
      cancel_button_text: 'No, Cancel',
      description: 'Are you sure you want to delete the invitation sent to ' + invitationRow.getFirstName() + '?',
    }
    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((response) => {
      if (response?.delete && response.delete === true) {
        this.store.dispatch(OutgoingInvitationsActions.DELETE_INVITATION({ accountantId: invitationRow.getId() }))
      }
    })
    this.getOutgoingInvitations();
  }



  deleteAccountant($event: AccountantRow) {

    const accountantRow: AccountantRow = $event;
    console.log("delete accountant")
    console.log(accountantRow)
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {
      title: 'Revoke Access Confirmation',
      delete_button_text: 'Yes, Revoke',
      cancel_button_text: 'No, Cancel',
      description: "Are you sure you want to remove " + accountantRow.getFirstName() + " as an accountant?",
    }
    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((response) => {
      if (response?.delete && response.delete === true) {
        this.store.dispatch(MyAccountantsActions.DELETE_ACCOUNTANT({ accountantId: accountantRow.getId() }))
      }
    })
    this.getAccountants();
  }


  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }


}
