<section id="myaccount">

    <div class="flex flex-col">

        <div class="flex flex-col items-center justify-center gap-y-6">
            <ng-container *ngIf="hasName==true; else noName">
                <div class="blue-bg-50  rounded-full flex flex-row items-center justify-center w-[90px] h-[90px]">
                    <span class="mat-headline-4 blue-grey-400 m-0" style="font-weight: 600">{{
                        ((userDetails$ | async)?.first_name ?
                        (userDetails$ | async)?.first_name.trim()[0].toUpperCase() : '') + ((userDetails$ |
                        async)?.last_name ?
                        (userDetails$ | async)?.last_name.trim()[0].toUpperCase() : '') }}
                    </span>
                </div>
                <div class="flex flex-col gap-y-1 items-center justify-center">
                    <span class="mat-headline-6  m-0">Hi, {{(userDetails$ | async)?.first_name}}
                        {{(userDetails$|
                        async)?.last_name.trim()[0].toUpperCase()}}</span>
                    <span class="mat-body-1 m-0">{{(userDetails$ | async)?.username}}</span>
                </div>
            </ng-container>
            <ng-template #noName>
                <div
                    class="cursor-pointer blue-bg-50  rounded-full flex flex-row items-center justify-center w-[90px] h-[90px]">
                    <span class="mat-headline-4 blue-grey-400 my-auto" style="font-weight: 600">{{
                        ((userDetails$ | async)?.username ?
                        (userDetails$ | async)?.username.trim()[0].toUpperCase() : '') }}
                    </span>
                </div>
                <div class="flex flex-col gap-y-1 items-center justify-center">
                    <span class="mat-headline-6 text-text-primary m-0">Hi, {{((userDetails$ | async)?.username ?
                        (userDetails$ | async)?.username.split('@')[0] : '')}}</span>
                    <span class="mat-body-1 m-0">{{(userDetails$ | async)?.username}}</span>
                </div>
            </ng-template>
        </div>

        <h2 class="mat-subtitle-2 font-semibold text-text-primary mt-6">Personal Details</h2>

        <mat-card appearance="outlined"
            class="--sys-on-background flex flex-col mt-4 rounded max-w-[802px] mat-elevation-z10">
            <a class="flex flex-row px-6 py-8 items-center" routerLink="personal-details/edit">
                <p class="text-base font-medium text-text-secondary w-52">Name</p>
                <p class="text-base font-medium text-text-primary ml-10">
                    {{(userDetails$ | async)?.first_name}}
                    {{(userDetails$ | async)?.last_name}}
                </p>
                <mat-icon class="material-symbols-outlined primary ml-auto">chevron_right</mat-icon>
            </a>
            <mat-divider></mat-divider>
            <a class="flex flex-row px-6 py-8 items-center" routerLink="personal-details/edit">
                <p class="text-base font-medium text-text-secondary w-52">Gender</p>
                <p class="text-base font-medium text-text-primary ml-10">{{gender}}</p>
                <mat-icon class="material-symbols-outlined primary ml-auto">chevron_right</mat-icon>
            </a>
            <mat-divider></mat-divider>
            <a class="flex flex-row px-6 py-8 items-center" routerLink="personal-details/edit">
                <p class="text-base font-medium text-text-secondary w-52">DOB</p>
                <p class="text-base font-medium text-text-primary ml-10">{{dob}}</p>
                <mat-icon class="material-symbols-outlined primary ml-auto">chevron_right</mat-icon>
            </a>
        </mat-card>

        <h2 class="mat-subtitle-2 font-semibold text-text-primary mt-8">Communication Details</h2>

        <div class="flex flex-row mt-4 gap-6">
            <mat-card appearance="outlined" class="flex flex-col p-6 border border-stroke rounded">
                <mat-icon class="w-[32px] h-[32px]">alternate_email
                </mat-icon>
                <p class="text-lg font-semibold text-text-primary mt-4">Email Address</p>
                <p class="text-base font-medium text-text-secondary mt-1">Details about your registered email and
                    mobile
                    number.
                </p>
                <div *ngIf="(userDetails$ | async)?.username; else add">
                    <p class="text-base font-medium text-text-primary mt-4">
                        {{(userDetails$ | async)?.username}}
                    </p>
                    <button mat-stroked-button class="text-base font-medium border border-stroke rounded w-36 h-12 mt-8"
                        routerLink="email-address/edit">Update</button>
                </div>
                <ng-template #add>
                    <button mat-flat-button class="text-base font-medium rounded w-36 h-12 mt-8"
                        routerLink="email-address/edit">Add</button>
                </ng-template>

            </mat-card>

            <mat-card appearance="outlined" class="flex flex-col p-6 border border-stroke rounded">
                <mat-icon class="material-symbols-outlined w-[32px] h-[32px]">call</mat-icon>
                <p class="text-lg font-semibold text-text-primary mt-4">Phone Number</p>
                <p class="text-base font-medium text-text-secondary mt-1">Details about your registered email and
                    mobile
                    number.
                </p>
                <div *ngIf="(userDetails$ | async)?.mobile; else addMobile">
                    <p class="text-base font-medium text-text-primary mt-4">
                        +{{(userDetails$ | async)?.country_code}} {{(userDetails$ | async)?.mobile}}
                    </p>
                    <button mat-stroked-button class="text-base font-medium rounded w-36 h-12 mt-8"
                        routerLink="mobile-number/edit">Update</button>
                </div>
                <ng-template #addMobile>
                    <button mat-flat-button class="text-base mt-auto font-medium rounded w-36 h-12"
                        routerLink="mobile-number/add">Add</button>
                </ng-template>

            </mat-card>
        </div>


        <h2 class="mat-subtitle-2 font-semibold text-text-primary mt-8">Billing Details</h2>

        <mat-card appearance="outlined" class="flex flex-col mt-4 border border-stroke rounded max-w-[802px]">
            <a class="flex flex-row px-6 py-8 items-center" routerLink="billing-details/edit">
                <p class="text-base font-medium text-text-secondary w-[272px]">Business Name</p>

                <ng-container *ngIf="taxDetails?.legal_name; else emptyLegalName">
                    <p class="text-base font-medium text-text-primary w-[450px]">{{taxDetails?.legal_name}}</p>
                </ng-container>
                <ng-template #emptyLegalName>
                    <span class="mat-body-1 text-text-primary m-0"></span>
                </ng-template>

                <mat-icon class="material-symbols-outlined primary ml-auto">chevron_right</mat-icon>
            </a>
            <mat-divider></mat-divider>
            <a class="flex flex-row px-6 py-8 items-center" routerLink="billing-details/edit">
                <p class="text-base font-medium text-text-secondary w-[272px]">GSTIN</p>

                <ng-container *ngIf="taxDetails?.gstin; else emptyGstin">
                    <p class="text-base font-medium text-text-primary w-[450px]">{{taxDetails?.gstin}}</p>
                </ng-container>
                <ng-template #emptyGstin>
                    <span class="mat-body-1 text-text-primary m-0"></span>
                </ng-template>

                <mat-icon class="material-symbols-outlined primary ml-auto">chevron_right</mat-icon>
            </a>
            <mat-divider></mat-divider>
            <a class="flex flex-row px-6 py-8 items-center" routerLink="billing-details/edit">
                <p class="text-base font-medium text-text-secondary w-[272px]">Place of supply</p>
                <ng-container *ngIf="taxDetails?.place_of_supply; else emptyPlaceOfSupply">
                    <p class="text-base font-medium text-text-primary w-[450px]">{{taxDetails?.place_of_supply}}
                    </p>
                </ng-container>
                <ng-template #emptyPlaceOfSupply>
                    <span class="mat-body-1 text-text-primary m-0"></span>
                </ng-template>
                <mat-icon class="material-symbols-outlined primary ml-auto">chevron_right</mat-icon>
            </a>
            <mat-divider></mat-divider>
            <a class="flex flex-row px-6 py-8 items-center" routerLink="billing-details/edit">
                <p class="text-base font-medium text-text-secondary w-[272px]">Billing Address</p>
                <ng-container
                    *ngIf="taxDetails?.address?.street && taxDetails?.address?.area && taxDetails?.address?.city && taxDetails?.address?.state && taxDetails?.address?.postal_code && taxDetails?.address?.country; else emptyAddress">
                    <p class="text-base font-medium text-text-primary w-[450px]">
                        {{taxDetails?.address?.street}},
                        {{taxDetails?.address?.area}},
                        {{taxDetails?.address?.city}},
                        {{taxDetails?.address?.state}},
                        {{taxDetails?.address?.country}},
                        {{taxDetails?.address?.postal_code}}
                    </p>
                </ng-container>
                <ng-template #emptyAddress>
                    <span class="mat-body-1 text-text-primary m-0"></span>
                </ng-template>
                <mat-icon class="material-symbols-outlined primary ml-auto">chevron_right</mat-icon>
            </a>
        </mat-card>

    </div>

</section>