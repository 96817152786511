import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ConnectedAppsStore, ConnectedAppsActions, AppEffects, ConnectedAppsReducers, AppSelectors, AppsService, DateUtil } from 'ngx-com-quicko-myaccount';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppCardComponent } from '../app-card/app-card.component';
import { RouterModule } from '@angular/router';


@Component({
  selector: 'app-third-party-apps',
  standalone: true,
  templateUrl: './third-party-apps.component.html',
  imports: [CommonModule, NgxSkeletonLoaderModule, MatIconModule, AppCardComponent, RouterModule]
})
export class ThirdPartyAppsComponent implements OnInit {

  apps: any;
  isLoading: boolean = true;

  constructor(private store: Store,
    private appsService: AppsService,
    private appStore: ConnectedAppsStore,
    private appReducer: ConnectedAppsReducers,
    private appSelectors: AppSelectors,
    private appEffects: AppEffects,
    private actions$: Actions) { }

  ngOnInit() {
    this.actions$.pipe(ofType(ConnectedAppsActions.GET_APPS_SUCCESS)).subscribe(() => {
      this.isLoading = false;
    })
    this.appSelectors.apps$.subscribe(apps => {
      if (apps) {
        this.apps = apps;
        this.isLoading = false;
      }
    });

    this.actions$.pipe(ofType(ConnectedAppsActions.REMOVE_APP)).subscribe(() => {
      this.isLoading = true;
    });

    this.actions$.pipe(ofType(ConnectedAppsActions.REMOVE_APP_SUCCESS)).subscribe(() => {
      this.store.dispatch(ConnectedAppsActions.GET_APPS());
      this.isLoading = true;
    });

  }
}
