import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from 'ngx-cookie-service';
import { Observable, catchError, throwError } from 'rxjs';
import { ENV_CONFIG } from '../config/token';

@Injectable({ providedIn: 'root' })
export class AuthService {

  jwtHelper: JwtHelperService

  constructor(@Inject(ENV_CONFIG) private environment: any, private cookieService: CookieService) {
    this.jwtHelper = new JwtHelperService()
  }

  public getToken(): string {
    return this.cookieService.get('SESSIONID');
  }

  public setToken(token: string): void {
    if (this.environment.production) {
      this.cookieService.set('SESSIONID', token, 1, '/', '.quicko.com', true, 'Strict');
    }
    else {
      this.cookieService.set('SESSIONID', token, 1, '/', 'localhost', true, 'Strict');
    }
  }

  public removeToken(): void {
    if (this.environment.production) {
      this.cookieService.delete('SESSIONID', '/', '.quicko.com', true, 'Strict');
    }
    else {
      this.cookieService.delete('SESSIONID', '/', 'localhost', true, 'Strict');
    }
  }

  public getJwtTokenClaims(jwtToken: string) {
    return this.jwtHelper.decodeToken(jwtToken)
  }

  public getUsername(): string {
    const jwtToken = this.getToken();
    const subject = this.getJwtTokenClaims(jwtToken)['email'];
    return subject;
  }

  public getUserId(): string {
    const jwtToken = this.getToken();
    const userId = this.getJwtTokenClaims(jwtToken)['sub'];
    return userId;
  }

  public getAudience(): string {
    const jwtToken = this.getToken()
    const claims = this.getJwtTokenClaims(jwtToken)
    const audience = claims['aud']
    return audience
  }

  public isAuthenticated(): boolean {

    try {

      if (this.getToken() != null) {

        const token = this.getToken().split('.');

        if (token && token.length == 3 && this.jwtHelper.decodeToken(this.getToken()) && !this.jwtHelper.isTokenExpired(this.getToken())) {
          return true;
        }
      }

    } catch (error) {
      return false;
    }

    return false;
  }

  public getIssuer() {
    if (this.getToken() != null && this.getToken() != '') {
      const jwtToken = this.getToken();
      const claims = this.getJwtTokenClaims(jwtToken);
      const issuer = claims['iss'];
      return issuer;
    }
  }

  public getOAuthToken() {
    if (this.getToken() != null && this.getToken() != '') {
      const jwtToken = this.getToken();
      const claims = this.getJwtTokenClaims(jwtToken);
      const oAuthToken = claims['oauth_token'];
      return oAuthToken;
    }
  }

  public getOAuthProvider(oAuthToken: string) {
    if (this.getToken() != null && this.getToken() != '') {
      const claims = this.getJwtTokenClaims(oAuthToken);
      const issuer = claims['iss'];
      const oAuthProvider = issuer.split('.')[0];
      return oAuthProvider;
    }
  }

  public getOAuthUserId(oAuthToken: string) {
    if (this.getToken() != null && this.getToken() != '') {
      const claims = this.getJwtTokenClaims(oAuthToken);
      const OAuthUserId = claims['oauth_user_id'];
      return OAuthUserId
    }
  }

  public isQuickoToken() {
    const issuer = this.getIssuer();
    return issuer === 'accounts.quicko.com' || issuer === 'google.quicko.com';
  }


  public isInternalUser() {
    const email = this.getUsername();
    const pattern = new RegExp('.*quicko.com$');
    if (pattern.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  getTokenExpiry(token: string): Date | null {
    return this.jwtHelper.getTokenExpirationDate(token);
  }

  isTokenExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token);
  }

}

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  authentication_host: string;
  constructor(@Inject(ENV_CONFIG) private environment: any, public auth: AuthService) {

    this.authentication_host = this.environment.accounts_app_host;

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.environment.production) {
      if (this.auth.isAuthenticated()) {
        request = request.clone({
          setHeaders:
          {
            'authorization': this.auth.getToken(),
            // 'x-application': 'com.quicko.pro',
            // 'x-platform': 'web'
          }
        });
      }
      else {
        window.location.href = this.authentication_host + "/login" + "?redirect=" + window.btoa(window.location.href);
      }
    }
    else {
      if (this.auth.isAuthenticated()) {
        if (this.auth.getUsername() != null) {
          request = request.clone({
            setHeaders:
            {
              'authorization': this.auth.getToken(),
              // 'x-application': 'com.quicko.pro',
              // 'x-platform': 'web'
            }
          });
        }
      }
    }
    return next.handle(request);

  }
}

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  authentication_host: string;
  constructor(@Inject(ENV_CONFIG) private environment: any) {
    this.authentication_host = this.environment.accounts_app_host;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error)
        switch (error.status) {

          case 401:
            window.location.href = this.authentication_host + '/reauthenticate';
            break;
          case 403:
            if (error.message != null && error.message.startsWith('Invalid Token')) {
              window.location.href = this.authentication_host + '/reauthenticate';
            }
            break;
          default:
            break;
        }

        return throwError(() => error);

      })
    );
  }
}