import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User, UserSelectors, AuthService, EntitlementsService, SnackbarService } from 'ngx-com-quicko-myaccount';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { NgOtpInputModule } from 'ng-otp-input'
import { ButtonLoaderComponent } from '../../../common/components/button-loader/button-loader.component';
import { MatButtonModule } from '@angular/material/button';
@Component({
  selector: 'app-delete-account',
  standalone: true,
  providers: [RxFormBuilder],
  imports: [MatIconModule, MatButtonModule, NgOtpInputModule, ButtonLoaderComponent, CommonModule, MatDialogModule, MatSelectModule, MatInputModule, MatNativeDateModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, RiveModule, RxReactiveFormsModule],
  templateUrl: './delete-account.component.html',
  styleUrl: './delete-account.component.css'
})
export class DeleteAccountComponent implements OnInit {
  userDetails: Observable<User> = this.store.select(UserSelectors.USER);
  initialScreen: boolean = true;
  otp: string | undefined;
  options: FormControl;
  otherReason: FormControl;
  optionsList: any[];
  selectedOption: any;
  timeLeft = 30;
  interval: any;
  saveFormLoading: boolean = false;
  deleteForm: boolean = false;

  constructor(
    private snackbarService: SnackbarService,
    private entitlementsService: EntitlementsService,
    private store: Store,
    public dialogRef: MatDialogRef<DeleteAccountComponent>,
    private authService: AuthService
  ) {
    this.options = new FormControl('', [Validators.required]);
    this.otherReason = new FormControl('', [Validators.required]);

    this.optionsList = [
      { value: 'No longer using the service or product' },
      { value: 'Dissatisfaction with the service or product' },
      { value: 'Concerns about privacy or data security' },
      { value: 'Received too many emails or notifications' },
      { value: 'Difficulty using or navigating the service or product' },
      { value: 'Personal reasons or life changes' },
      { value: 'The service or product is no longer relevant to their needs' },
      { value: 'Too many ads or marketing materials' },
      { value: 'Other' },
    ];
  }

  ngOnInit() { }

  proceed() {
    if (this.options.valid && (this.selectedOption != 'Other' || (this.selectedOption == 'Other' && this.otherReason.valid))) {
      this.saveFormLoading = true;
      this.generateOtp();
    } else {
      this.otherReason.markAllAsTouched();
      this.options.markAllAsTouched();
    }
  }

  delete() {
    if (this.otp) {
      this.deleteForm = true;
      this.userDetails.subscribe((userDetails) => {
        this.entitlementsService
          .verifyDeleteUserOTP(userDetails.username!, this.otp!, this.selectedOption == 'Other' ? this.otherReason.value : this.selectedOption)
          .subscribe({
            next: () => {
              this.authService.removeToken();
              window.location.reload();
              this.deleteForm = false;
            },
            error: (err) => {
              this.snackbarService.openSnackBar(err.error.message, undefined);
              if (
                err.error.message.includes('Too many verification attempts')
              ) {
                this.authService.removeToken();
                window.location.reload();
              }
              this.deleteForm = false;
            },
          });
      });
    }
  }

  onOtpChange(otp: string) {
    if (otp.length === 6) {
      this.otp = otp;
    }
  }

  generateOtp() {
    this.userDetails.subscribe((userDetails) => {
      this.entitlementsService
        .getDeleteUserOTP(userDetails.username!)
        .subscribe({
          next: () => {
            this.initialScreen = false;
            this.saveFormLoading = false;
          },
          error: (err) => {
            if (err.error.message) {
              this.snackbarService.openSnackBar(err.error.message, undefined);
            };
            this.saveFormLoading = false;
          },
        });
    });
  }

  resendOTP() {
    if (this.options.valid) {
      this.generateOtp();
      this.interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(this.interval);
          this.timeLeft = 30;
        }
      }, 1000);
    } else {
      this.snackbarService.openSnackBar('Please Enter the OTP', undefined);
    }
  }
}
