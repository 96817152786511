import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User, UserSelectors } from 'ngx-com-quicko-myaccount';
import { AvatarModule } from 'ngx-avatars';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatListModule, AvatarModule, RouterModule, MatCardModule],
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  hasName: boolean = true;
  userDetails: Observable<User> = this.store.select(UserSelectors.USER);

  constructor(private store: Store) { }

  ngOnInit() {
    this.userDetails.subscribe(user => {
      if (user.first_name || user.last_name) {
        this.hasName = true;
      } else {
        this.hasName = false;
      }
    })
  }

}
