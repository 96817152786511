import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserActions, UserSelectors, SnackbarService, DateUtil, User } from 'ngx-com-quicko-myaccount';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RiveModule } from 'ng-rive';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ButtonLoaderComponent } from '../../../common/components/button-loader/button-loader.component';
import { FormFieldErrorComponent } from '../../../common/components/form-field-error/form-field-error.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-edit-personal-details',
  standalone: true,
  providers: [RxFormBuilder],
  imports: [CommonModule, MatButtonModule, MatDividerModule, FormFieldErrorComponent, ButtonLoaderComponent, RouterModule, MatIconModule, MatInputModule, MatNativeDateModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, RiveModule, RxReactiveFormsModule],
  templateUrl: './edit-personal-details.component.html',
  styleUrl: './edit-personal-details.component.css'
})
export class EditPersonalDetailsComponent implements OnInit {

  personalDetailsForm: FormGroup;
  genderList: any[];
  userDetails: Observable<User> = this.store.select(UserSelectors.USER);
  user: User;
  dob: string | undefined;
  maxDate: any;
  saveFormLoading: boolean = false;
  dateError: boolean;

  constructor(
    private store: Store,
    private formBuilder: RxFormBuilder,
    private router: Router,
    private actions$: Actions,
    private snackbarService: SnackbarService) {

    this.personalDetailsForm = this.formBuilder.formGroup(new User());
    this.genderList = [
      { value: 'M', viewValue: 'Male' },
      { value: 'F', viewValue: 'Female' }
    ];
    this.maxDate = new Date();

    this.userDetails.subscribe((user: User) => {
      this.user = user;
    })

  }

  ngOnInit() {
    this.setValues();
  }

  showError() {
    this.personalDetailsForm.controls['first_name'].markAsTouched();
    this.personalDetailsForm.controls['last_name'].markAsTouched();
  }

  validate() {
    if (this.user.date_of_birth != undefined) {

      if (!this.personalDetailsForm['controls']['date_of_birth'].valid) {
        this.dateError = true;
      }

      return (
        this.personalDetailsForm['controls']['first_name'].valid &&
        this.personalDetailsForm['controls']['last_name'].valid &&
        this.personalDetailsForm['controls']['date_of_birth'].valid
      );
    } else {
      return (
        this.personalDetailsForm['controls']['first_name'].valid &&
        this.personalDetailsForm['controls']['last_name'].valid
      );
    }

  }

  save() {
    this.saveFormLoading = true;

    const updatedUser: User = new User();
    updatedUser.first_name = this.personalDetailsForm.controls['first_name'].value;
    updatedUser.last_name = this.personalDetailsForm.controls['last_name'].value;
    updatedUser.gender = this.personalDetailsForm.controls['gender'].value;
    updatedUser.date_of_birth = DateUtil.toMillis(this.personalDetailsForm.controls['date_of_birth'].value);

    this.store.dispatch(UserActions.POST_USER({ user: updatedUser }));
    this.actions$.pipe(ofType(UserActions.POST_USER_SUCCESS)).subscribe({
      next: () => {
        this.snackbarService.openSnackBar('Personal details updated successfully', undefined);
        this.router.navigate(['../']);
        this.saveFormLoading = false;
      },
      error: () => {
        this.snackbarService.openSnackBar('Failed to update details', undefined);
        this.saveFormLoading = false;
      }
    })
  }

  setValues() {
    this.personalDetailsForm.controls['first_name'].setValue(this.user.first_name);
    this.personalDetailsForm.controls['last_name'].setValue(this.user.last_name);
    this.personalDetailsForm.controls['gender'].setValue(this.user.gender);
    this.personalDetailsForm.controls['date_of_birth'].setValue(DateUtil.fromMillis(this.user.date_of_birth!));
  }

}
