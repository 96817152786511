import { prop } from "@rxweb/reactive-form-validators";

export class BaseObject {

    @prop()
    id?: string

    @prop()
    description?: string

    @prop()
    created_at?: number

    @prop()
    updated_at?: number
}