import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { User } from '../beans/User';
import { AffiliateService } from './affiliate.service';
import { ENV_CONFIG } from '../config/token';

@Injectable({
	providedIn: 'root',
})
export class EntitlementsService {
	private endpoint: string;

	constructor(
		@Inject(ENV_CONFIG) private environment: any,
		private httpClient: HttpClient,
		private affiliateService: AffiliateService
	) {
		this.endpoint = this.environment.auth_services_endpoint + '/auth';
	}

	getUserDetail() {
		const url = this.endpoint + '/user/detail';
		return this.httpClient.get(url);
	}

	postUserDetail(user: User) {
		const url = this.endpoint + '/user/detail';
		return this.httpClient.post(url, user);
	}

	updatePassword(username: string, oldPassword: string, newPassword: string) {
		var user = {
			username: username,
			old_password: oldPassword,
			new_password: newPassword,
		};

		const url = this.endpoint + '/user/change-password';
		return this.httpClient.post(url, user);
	}

	getUserMetadata(email: string) {
		const url = this.endpoint + '/user/meta';
		var user = {
			'@entity': 'org.quicko.auth.user',
			username: email,
		};
		return this.httpClient.post(url, user);
	}

	login(email: string, password: string) {
		var user = {
			'@entity': 'org.quicko.auth.user',
			username: email,
			password: password,
		};

		const url = this.endpoint + '/user/login';
		return this.httpClient.post(url, user);
	}

	logout() {
		const url = this.endpoint + '/user/logout';
		return this.httpClient.post(url, null);
	}

	getOTPByEmail(email: string) {
		const url = this.endpoint + '/user/login/otp/generate';
		var user = {
			'@entity': 'org.quicko.auth.user',
			username: email,
		};
		return this.httpClient.post(url, user);
	}

	verifyEmailOTP(email: string, otp: string) {
		let params: HttpParams = new HttpParams().set('otp', otp);
		var user = {
			'@entity': 'org.quicko.auth.user',
			username: email,
		};
		// params = this.affiliateService.getAffiliateParams(params);
		const url = this.endpoint + '/user/login/otp/verify';
		return this.httpClient.post(url, user, {
			params: new HttpParams().set('otp', otp),
		});
	}

	getOTPByMobile(mobile: string, countryCode: string) {
		const url = this.endpoint + '/user/login/otp/generate';
		var user = {
			'@entity': 'org.quicko.auth.user',
			mobile: mobile,
			country_code: countryCode,
		};
		return this.httpClient.post(url, user);
	}

	verifyMobileOTP(mobile: string, countryCode: string, otp: string) {
		const url = this.endpoint + '/user/login/otp/verify';
		var user = {
			'@entity': 'org.quicko.auth.user',
			mobile: mobile,
			country_code: countryCode,
		};
		return this.httpClient.post(url, user, {
			params: new HttpParams().set('otp', otp),
		});
	}

	getOTPByNewEamil(newEmail: string) {
		const url = this.endpoint + '/user/email';
		var user = {
			'@entity': 'org.quicko.auth.user',
			username: newEmail
		};
		return this.httpClient.post(url, user);
	}

	verifyNewEmailOTP(newEmail: string, otp: string) {
		const url = this.endpoint + '/user/email/otp/verify';
		var user = {
			'@entity': 'org.quicko.auth.user',
			username: newEmail,
		};
		return this.httpClient.post(url, user, {
			params: new HttpParams().set('otp', otp),
		});
	}

	getOTPByNewMobile(email: string, mobile: string, countryCode: string) {
		const url = this.endpoint + '/user/mobile';
		var user = {
			'@entity': 'org.quicko.auth.user',
			username: email,
			mobile: mobile,
			country_code: countryCode,
		};
		return this.httpClient.post(url, user);
	}

	verifyNewMobileOTP(
		email: string,
		mobile: string,
		countryCode: string,
		otp: string
	) {
		const url = this.endpoint + '/user/mobile/otp/verify';
		var user = {
			'@entity': 'org.quicko.auth.user',
			username: email,
			mobile: mobile,
			country_code: countryCode,
		};
		return this.httpClient.post(url, user, {
			params: new HttpParams().set('otp', otp),
		});
	}

	signup(email: string, firstName: string, lastName: string) {
		var userDetails = {
			'@entity': 'org.quicko.auth.user.user_details',
			username: email,
			first_name: firstName,
			last_name: lastName,
		};

		const url = this.endpoint + '/user/signup';
		return this.httpClient.post(url, userDetails);
	}

	signupOTPVerify(email: string, otp: string) {
		let params: HttpParams = new HttpParams().set('otp', otp);
		var user = {
			'@entity': 'org.quicko.auth.user',
			username: email,
		};
		params = this.affiliateService.getAffiliateParams(params);

		const url = this.endpoint + '/user/signup/otp/verify';
		return this.httpClient.post(url, user, {
			params: params,
		});
	}

	getDeleteUserOTP(email: string) {
		const url = this.endpoint + '/user/delete';
		var user = {
			'@entity': 'org.quicko.auth.user',
			username: email,
		};
		return this.httpClient.post(url, user);
	}

	verifyDeleteUserOTP(email: string, otp: string, deletionReason: string) {
		const url = this.endpoint + '/user/delete/otp/verify';
		var user = {
			deletion_reason: deletionReason
		};
		return this.httpClient.post(url, user, {
			params: new HttpParams().set('otp', otp),
		});
	}

	postMember(email: string, entryPoint: string) {
		var member = {
			'@entity': 'org.quicko.auth.member_details',
			email: email,
			first_name: null,
			last_name: null,
			entry_point: entryPoint,
			mobile: null,
		};

		const url = this.endpoint + '/member';

		return this.httpClient.post(url, member);
	}
}
