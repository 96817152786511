import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { RiveModule } from 'ng-rive';
import { User, UserSelectors } from 'ngx-com-quicko-myaccount';
import { Observable } from 'rxjs';
import { WebFooterComponent } from '../../common/components/footer/footer.component';
import { WebHeaderComponent } from '../../common/components/header/header.component';
import { SideMenuComponent } from '../../common/components/side-menu/side-menu.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, MatDividerModule, MatSidenavModule, RiveModule, WebFooterComponent, WebHeaderComponent, SideMenuComponent],
  templateUrl: './root.component.html',
  styleUrl: './root.component.css'
})
export class RootComponent {

  userDetails: Observable<User>;
  user: User | undefined;

  constructor(private store: Store) {

    this.userDetails = this.store.select(UserSelectors.USER);
    this.userDetails.subscribe({
      next: (user: User) => {
        if (user.id != null) {
          this.user = user;
        }
      }
    })
  }

}
