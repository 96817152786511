<mat-divider />
<div class="">
  <div class="h-[77px] flex flex-row gap-x-4 items-center px-6 py-4">
    <div class="flex flex-row items-center gap-x-4">
      <div class="w-[32px]">

        <ng-container
          *ngIf="accountantRow?.getFirstName() == null && accountantRow?.getLastName() == null; else showNameAvatar">
          <ngx-avatars fgColor="#78909C" bgColor="#E0E8FF" size="32" name="{{(accountantRow?.getEmail() ? accountantRow?.getEmail().trim()[0].toUpperCase() :
          '')}}"></ngx-avatars>
        </ng-container>

        <ng-template #showNameAvatar>
          <ngx-avatars fgColor="#78909C" bgColor="#E0E8FF" size="32"
            name="{{accountantRow?.getFirstName() }} {{accountantRow?.getLastName() }}"></ngx-avatars>
        </ng-template>


      </div>
      <div class="flex flex-col w-[230px]">
        <span class="mat-body-1 text-text-primary  text-left overflow-hidden truncate mb-[0px]"
          style="font-weight: 600">

          <ng-container
            *ngIf="accountantRow?.getFirstName() == null && accountantRow?.getLastName() == null; else showName">
            {{accountantRow?.getEmail() ? accountantRow.getEmail().split('@')[0] : '' }}
          </ng-container>

          <ng-template #showName>
            {{accountantRow?.getFirstName() }} {{accountantRow?.getLastName() }}
          </ng-template>

        </span>
        <div class="mat-body-2 text-text-secondary overflow-hidden truncate">{{ accountantRow?.getEmail()}}</div>
      </div>
    </div>
    <div class="flex flex-row items-center justify-start mat-body-2 text-text-primary gap-x-1 w-[220px]">
      <span class="green-dot-background flex justify-center items-center my-auto">
        <span class="green-dot"></span>
      </span>
      <span>Active</span>
    </div>
    <div class="my-auto w-[200px]">
      <div *ngIf="accountantRow?.getLastAccessedAt() != 0">
        <div class="mat-body-1 text-text-secondary m-0">
          {{ accountantRow?.getLastAccessedAt()| date:"dd/MM/yyyy, hh:mm a"}}
        </div>
      </div>
    </div>
    <button class="w-[24px]">
      <!-- <mat-icon class="material-symbols-outlined h-[24px] w-[24px] m-[-6px] alert-error-900">delete</mat-icon> -->
      <mat-icon [mat-menu-trigger-for]="edit"
        class="mr-6 size-[24px] text-text-primary material-symbols-outlined cursor-pointer">
        more_vert
      </mat-icon>
      <mat-menu class="grey-border delete mat-elevation-z0 mt-2" #edit="matMenu" xPosition="before">
        <div (click)="editAccountant()" mat-menu-item
          class="cursor-pointer flex flex-row items-center justify-start gap-[12px] my-auto">
          <mat-icon style="height: 24px; width:24px;" class="material-symbols-outlined">
            edit
          </mat-icon>
          <p class="text-text-primary mat-body-2 mb-0">Edit</p>
        </div>
        <mat-divider />
        <div (click)="deleteAccountant()" mat-menu-item
          class="cursor-pointer flex flex-row items-center justify-start gap-[12px] my-auto">
          <mat-icon style="color: var(--sys-error); height: 24px; width:24px;" class="material-symbols-outlined">
            delete
          </mat-icon>
          <p class="error mat-body-2 mb-0">Revoke Access</p>
        </div>
      </mat-menu>
    </button>
  </div>
</div>