import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { SnackbarService } from 'ngx-com-quicko-myaccount';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Invitation, InvitationRow, OutgoingInvitationsActions, getOutgoingInvitations } from 'ngx-com-quicko-myaccount';
import { FormFieldErrorComponent } from '../../../../common/components/form-field-error/form-field-error.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-invite-accountant-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, FormFieldErrorComponent, RouterModule, MatIconModule, MatInputModule, MatNativeDateModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, RiveModule, RxReactiveFormsModule],
  templateUrl: './invite-accountant-dialog.component.html',
  styleUrls: ['./invite-accountant-dialog.component.css']
})
export class InviteAccountantDialogComponent implements OnInit {

  invitationForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  updateInvitation: boolean = false;
  savingForm: boolean = false;
  outgoingInvitationRows$: Observable<Array<InvitationRow>> = this.store.select(getOutgoingInvitations());

  millisecondsInDay = 24 * 60 * 60 * 1000;

  options = [{
    access: this.millisecondsInDay, expiry: '24 hours (1 day)'
  },
  {
    access: this.millisecondsInDay * 7, expiry: '7 days'
  },
  {
    access: this.millisecondsInDay * 14, expiry: '14 days'
  },
  {
    access: this.millisecondsInDay * 30, expiry: '30 days'
  },
  {
    access: -1, expiry: 'Never expires'
  }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { invitationRow: InvitationRow },
    public snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<InviteAccountantDialogComponent>,
    private formBuilder: RxFormBuilder,
    private store: Store,
    private actions$: Actions,
    private route: ActivatedRoute,
  ) {
    this.invitationForm = this.formBuilder.formGroup(new Invitation());
  }


  ngOnInit() {

    const invitation: Invitation = new Invitation();
    if (this.data) {
      this.updateInvitation = true;
      invitation.inviteeEmail = this.data.invitationRow.getEmail();
      invitation.inviteeFirstName = this.data.invitationRow.getFirstName();
      invitation.inviteeLastName = this.data.invitationRow.getLastName();
      invitation.invitationExpiryAt = this.data.invitationRow.getInvitationExpiry();
    }
    else {
      this.updateInvitation = false;
    }


    if (this.options.length > 0) {
      this.invitationForm.get('accessValidity')?.setValue(this.options[0]);
    }

    if (this.updateInvitation == true) {
      this.invitationForm.patchValue(invitation);
    }

  }


  showError() {
    this.invitationForm.controls['inviteeFirstName'].markAsTouched();
    this.invitationForm.controls['inviteeLastName'].markAsTouched();
    this.invitationForm.controls['inviteeEmail'].markAsTouched();
    this.invitationForm.controls['accessValidity'].markAsTouched();
  }

  validate() {
    console.log(this.invitationForm)
    return this.invitationForm.valid;
  }

  save(): void {
    this.savingForm = true;


    if (this.invitationForm.get('accessValidity')?.value.access == -1)
      this.invitationForm.get('accessValidity')?.setValue(null)
    else {
      const optionsValue = this.invitationForm.get('accessValidity')?.value!;
      this.invitationForm.get('accessValidity')?.setValue(optionsValue.access)
    }

    if (!this.updateInvitation) {
      console.log("post invitation")
      const invitation = new Invitation();
      invitation.accessValidity = this.invitationForm.value['accessValidity'];
      invitation.inviteeEmail = this.invitationForm.value['inviteeEmail'];
      invitation.inviteeFirstName = this.invitationForm.value['inviteeFirstName'];
      invitation.inviteeLastName = this.invitationForm.value['inviteeLastName'];

      this.store.dispatch(OutgoingInvitationsActions.POST_INVITATION({ invitation: invitation }))

      this.actions$.pipe(ofType(OutgoingInvitationsActions.POST_INVITATION_SUCCESS), takeUntil(this.destroy$)).subscribe((action) => {
        this.snackbarService.openSnackBar('Invitation sent successfully', undefined)
      }
      )

      this.actions$.pipe(ofType(OutgoingInvitationsActions.POST_INVITATION_ERROR)).subscribe(() => {
        this.snackbarService.openSnackBar('Failed', undefined)
      })
    } else {
      console.log("update invitation")
      const invitation = new Invitation();
      invitation.accessValidity = this.invitationForm.value['accessValidity'];
      invitation.inviteeEmail = this.invitationForm.value['inviteeEmail'];
      invitation.inviteeFirstName = this.invitationForm.value['inviteeFirstName'];
      invitation.inviteeLastName = this.invitationForm.value['inviteeLastName'];

      this.store.dispatch(OutgoingInvitationsActions.UPDATE_INVITATION({ invitation: invitation }))

      this.actions$.pipe(ofType(OutgoingInvitationsActions.UPDATE_INVITATION_SUCCESS), takeUntil(this.destroy$)).subscribe((action) => {
        this.snackbarService.openSnackBar('Invitation updated successfully', undefined)
      }
      )

      this.actions$.pipe(ofType(OutgoingInvitationsActions.UPDATE_INVITATION_ERROR)).subscribe(() => {
        this.snackbarService.openSnackBar('Failed', undefined)
      })

    }

    this.dialogRef.close();

  }

  close() {
    this.dialogRef.close();
  }

}
