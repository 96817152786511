import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SessionCookieService } from './session-cookie.service';
import { ENV_CONFIG } from '../config/token';

@Injectable({ providedIn: 'root' })
export class AffiliateService {
  constructor(
    @Inject(ENV_CONFIG) private environment: any,
    private cookieService: CookieService,
    private sessionCookieService: SessionCookieService
  ) { }

  public setCookie(affiliateId: string): void {
    if (!this.cookieService.get('AFFILIATE')) {
      if (this.environment.production) {
        this.cookieService.set('AFFILIATE', affiliateId, 30, '/', '.quicko.com', true, 'Lax');
      } else {
        this.cookieService.set('AFFILIATE', affiliateId, 30, '/', 'localhost', true, 'Lax');
      }
    }
  }

  public getCookie(): string {
    return this.cookieService.get('AFFILIATE');
  }

  public getAffiliateParams(params?: HttpParams) {
    if (params == null) {
      params = new HttpParams();
    }

    if (this.getCookie()) {
      params = params.append('affiliate_id', this.getCookie());
    }

    if (this.sessionCookieService.getCookie('UTM_SOURCE')) {
      params = params.append(
        'utm_source',
        this.sessionCookieService.getCookie('UTM_SOURCE')
      );
    }

    if (this.sessionCookieService.getCookie('UTM_MEDIUM')) {
      params = params.append(
        'utm_medium',
        this.sessionCookieService.getCookie('UTM_MEDIUM')
      );
    }

    if (this.sessionCookieService.getCookie('UTM_NAME')) {
      params = params.append(
        'utm_name',
        this.sessionCookieService.getCookie('UTM_NAME')
      );
    }

    if (this.sessionCookieService.getCookie('UTM_CONTENT')) {
      params = params.append(
        'utm_content',
        this.sessionCookieService.getCookie('UTM_CONTENT')
      );
    }

    if (this.sessionCookieService.getCookie('CATEGORY')) {
      params = params.append(
        'category',
        this.sessionCookieService.getCookie('CATEGORY')
      );
    }
    return params;
  }
}
