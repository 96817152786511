export type JSONValue =
  | string
  | number
  | boolean
  | Date
  | { [x: string]: JSONValue }
  | JSONObject
  | JSONArray
  | null
  | undefined;

export class JSONObject extends Object {
  constructor(object?: { [x: string]: JSONValue }) {
    super();
    if (object) {
      for (const [key, value] of Object.entries(object)) {
        if (value == null) {
          this.put(key, value);
        } else if (value.constructor == Object) {
          this.put(key, new JSONObject(value as { [x: string]: JSONValue }));
        } else if (value.constructor == Array) {
          this.put(key, new JSONArray(value));
        } else {
          this.put(key, value);
        }
      }
    }
  }
  put(key: string, value: JSONValue) {
    (this as any)[key] = value;
  }

  get(key: string): JSONObject {
    return (this as any)[key];
  }

  getString(key: string): string {
    if ((this as any)[key]) {
      return String((this as any)[key]);
    }
    return (this as any)[key];
  }

  getNumber(key: string): number {
    if ((this as any)[key]) {
      return Number((this as any)[key]);
    }
    return (this as any)[key];
  }

  getBoolean(key: string): boolean {
    if ((this as any)[key]) {
      return Boolean((this as any)[key]);
    }
    return (this as any)[key];
  }

  getArray(key: string) {
    return (this as any)[key] as JSONArray;
  }
}

export class JSONArray extends Array<JSONValue> {
  constructor(array?: Array<JSONValue>) {
    super();
    if (array) {
      for (let item of Object.values(array)) {
        item = JSON.parse(JSON.stringify(item));

        if (item == null) {
          this.push(item);
        } else if (item.constructor == Object) {
          this.push(new JSONObject(item as { [x: string]: JSONValue }));
        } else if (item.constructor == Array) {
          this.push(new JSONArray(item));
        } else {
          this.push(item);
        }
      }
    }
  }
}
