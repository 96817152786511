import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ENV_CONFIG } from "../config/token";

@Injectable({ providedIn: 'root' })
export class AppsService {
    private endpoint: string;
    constructor(@Inject(ENV_CONFIG) private environment: any, private httpClient: HttpClient) {
        this.endpoint = this.environment.connect_endpoint;
    }

    getApps(): Observable<any> {
        const url = this.endpoint + '/apps';
        return this.httpClient.get(url);
    }

    deleteApps(appKey: string): Observable<any> {
        const url = this.endpoint + `/apps/${appKey}/revoke`;
        return this.httpClient.post(url, {});
    }
}