<header class="w-[100vw] flex flex-col items-center">
  <div class="w-[1080px]">
    <div class="flex flex-row justify-between items-center container h-20">
      <div class="flex flex-row items-center justify-center">
        <ng-container *ngIf="theme=='light' else whiteLogo">
          <img src="https://cdn.quicko.com/assets/images/logo.svg" alt="" class="w-[130px] h-[24px]">
        </ng-container>
        <ng-template #whiteLogo>
          <img src="https://cdn.quicko.com/assets/images/logo_white.svg" alt="" class="w-[130px] h-[24px]">
        </ng-template>
      </div>

      <div class="flex flex-row gap-x-6">
        <div class="flex flex-row items-center justify-center">
          <app-apps-menu [userDetails]="(userDetails | async)"></app-apps-menu>
        </div>
        <div mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger"
          class="cursor-pointer flex items-center justify-center z-[1050] h-full">
          <ng-container *ngIf="hasName==true; else noName">
            <div class="blue-bg-50  rounded-full flex flex-row items-center justify-center w-[32px] h-[32px]">
              <span class="mat-body-2 blue-grey-400" style="font-weight: 600">{{
                ((userDetails | async)?.first_name ?
                (userDetails | async)?.first_name.trim()[0].toUpperCase() : '') + ((userDetails |
                async)?.last_name ?
                (userDetails | async)?.last_name.trim()[0].toUpperCase() : '') }}
              </span>
            </div>
          </ng-container>
          <ng-template #noName>
            <div
              class="cursor-pointer blue-bg-50  rounded-full flex flex-row items-center justify-center w-[32px] h-[32px]">
              <span class="mat-body-2 blue-grey-400 my-auto" style="font-weight: 600">{{
                ((userDetails | async)?.username ?
                (userDetails | async)?.username.trim()[0].toUpperCase() : '') }}
              </span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</header>
<mat-divider></mat-divider>

<mat-menu #userMenu="matMenu" direction="down" yPosition="below" xPosition="before" [hasBackdrop]=true
  class="w-[300px] mt-[26px]">
  <!-- profile -->
  <div class="flex flex-col pb-6 pt-4 px-4">
    <ng-container *ngIf="hasName==true; else noNameDropdown">
      <div class="blue-bg-50  rounded-full flex flex-row items-center justify-center w-[48px] h-[48px]">
        <span class="mat-body-2 blue-grey-400" style="font-size: 22px; font-weight: 600">{{
          ((userDetails | async)?.first_name ?
          (userDetails | async)?.first_name.trim()[0].toUpperCase() : '') + ((userDetails |
          async)?.last_name ?
          (userDetails | async)?.last_name.trim()[0].toUpperCase() : '') }}
        </span>
      </div>
      <div class="flex flex-row items-center justify-start w-[200px]">
        <p class="mat-body-1 font-semibold mt-2 text-nowrap overflow-hidden truncate on-surface">
          {{(userDetails | async)?.first_name}} {{(userDetails | async)?.last_name}}
        </p>
      </div>
    </ng-container>
    <ng-template #noNameDropdown>
      <div class="blue-bg-50  rounded-full flex flex-row items-center justify-center w-[48px] h-[48px] mb-2">
        <span class="mat-body-2 blue-grey-400 my-auto" style="font-size:22px; font-weight: 600">{{
          ((userDetails | async)?.username ?
          (userDetails | async)?.username.trim()[0].toUpperCase() : '') }}
        </span>
      </div>
      <div class="flex flex-row items-center justify-start w-[200px]">
        <p class="mat-body-1 font-semibold mt-2 text-nowrap overflow-hidden truncate on-surface">
          {{((userDetails | async)?.username ? (userDetails | async)?.username.split('@')[0] : '')}}
        </p>
      </div>
    </ng-template>

    <div class="mat-body-2 on-surface-variant">
      {{(userDetails | async)?.username}}</div>
  </div>

  <mat-divider></mat-divider>

  <div mat-menu-item class="flex flex-row py-[20px]" (click)="navigateToMyAccountAppProfile()">
    <mat-icon class="w-6 h-6 material-symbols-outlined font-light">account_circle</mat-icon>
    <span class="mat-body-1 text-text-primary">My Account</span>
  </div>

  <div mat-menu-item class="flex flex-row py-[20px]" (click)="navigateToMyAccountAppSettings()">
    <mat-icon class="w-6 h-6 material-symbols-outlined font-light">settings</mat-icon>
    <span class="mat-body-1 text-text-primary">Settings</span>
  </div>

  <div mat-menu-item class="flex flex-row py-[20px]" (click)="navigateToHelp()">
    <mat-icon class="w-6 h-6 material-symbols-outlined font-light">support</mat-icon>
    <span class="mat-body-1 text-text-primary">Help</span>
  </div>

  <mat-divider></mat-divider>

  <div mat-menu-item class="flex flex-row py-[20px]" (click)="logout()">
    <mat-icon class="w-6 h-6 material-symbols-outlined font-light">logout</mat-icon>
    <span class="mat-body-1 text-text-primary">Log Out</span>
  </div>

</mat-menu>