import { createAction, props } from "@ngrx/store";
import { InvitationRow } from "../../../beans";
import { v4 as uuidv4 } from "uuid";

export const IncomingInvitationsActions = {

    GET_INVITATIONS: createAction('[Incoming Invitations] GET Incoming Invitations ' + uuidv4(), props<{ lastEvaluatedKey?: string, limit?: number, sort?: string }>()),
    GET_INVITATIONS_SUCCESS: createAction('[Incoming Invitations] GET Incoming Invitations Success ' + uuidv4(), props<{ invitationRows: InvitationRow[], lastEvaluatedKey?: string }>()),
    GET_INVITATIONS_ERROR: createAction('[Incoming Invitations] GET Incoming Invitations Error ' + uuidv4(), props<{ err: any }>()),

    GET_INVITATIONS_COUNT: createAction('[Incoming Invitations] GET Incoming Invitations Count ' + uuidv4()),
    GET_INVITATIONS_COUNT_SUCCESS: createAction('[Incoming Invitations] GET Incoming Invitations Count Success ' + uuidv4(), props<{ invitationCount: number }>()),
    GET_INVITATIONS_COUNT_ERROR: createAction('[Incoming Invitations] GET Incoming Invitations Count Error ' + uuidv4(), props<{ err: any }>()),

    ACCEPT_INVITATION: createAction('[Incoming Invitations] Accept Invitation ' + uuidv4(), props<{ accountantId: string }>()),
    ACCEPT_INVITATION_SUCCESS: createAction('[Incoming Invitations] Accept Invitation Success ' + uuidv4(), props<{ accountantId: string }>()),
    ACCEPT_INVITATION_ERROR: createAction('[Incoming Invitations] Accept Invitation Error ' + uuidv4(), props<{ err: any }>()),

    REJECT_INVITATION: createAction('[Incoming Invitations] Reject Invitation ' + uuidv4(), props<{ accountantId: string }>()),
    REJECT_INVITATION_SUCCESS: createAction('[Incoming Invitations] Reject Invitation Success ' + uuidv4(), props<{ accountantId: string }>()),
    REJECT_INVITATION_ERROR: createAction('[Incoming Invitations] Reject Invitation Error ' + uuidv4(), props<{ err: any }>()),
}
