<div class="flex flex-col gap-y-4">
    <div class="flex flex-row gap-x-2 items-center">
        <mat-icon class="material-symbols-outlined m-0">lock</mat-icon>
        <span class="mat-subtitle-2 text-text-primary m-0 font-semibold">Security</span>
    </div>
    <mat-card appearance="outlined" class="flex flex-col rounded p-6">
        <p class="mat-body-1  text-text-primary">Password</p>
        <p class="mat-body-2 font-medium text-text-secondary mt-1">Set a permanent password to login to your
            account.
        </p>
        <button mat-stroked-button *ngIf="(userDetails | async)?.credential_status == 'active', else elseBlock"
            class="text-base font-medium rounded  w-36 h-12 mt-6" routerLink="security/password/edit">Update</button>
        <ng-template #elseBlock><button mat-stroked-button
                class="text-base text-nowrap font-medium rounded w-36 h-12 mt-6" (click)="navigateToSetPassword()">Set
                Password</button></ng-template>
    </mat-card>
</div>