import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, switchMap } from 'rxjs/operators';
import { SnackbarService, TaxDetailsService } from "../../../services";
import { TaxDetailsActions } from "./tax-details.actions";
import { instanceToPlain, plainToClass, plainToInstance } from "class-transformer";
import { TaxDetails } from "../../../beans";

@Injectable()
export class TaxDetailsEffects {

    constructor(
        private actions$: Actions,
        private taxDetailsService: TaxDetailsService,
        private snackbarService: SnackbarService,
        private store: Store
    ) { }

    getTaxDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TaxDetailsActions.GET_TAX_DETAILS),
            switchMap((() => this.taxDetailsService.getTaxDetails().pipe(
                map((response) => {
                    const taxDetails = plainToInstance(TaxDetails, response.data);
                    return TaxDetailsActions.GET_TAX_DETAILS_SUCCESS({ taxDetails: taxDetails })
                }),
                catchError((err, caught) => {
                    // this.snackbarService.openSnackBar('Failed to get billing details', undefined);
                    this.store.dispatch(
                        TaxDetailsActions.GET_TAX_DETAILS_ERROR({ err: err.message }),
                    )
                    throw err;
                })
            )))
        ));

    prefillTaxDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TaxDetailsActions.PREFILL_TAX_DETAILS),
            switchMap(((action) => this.taxDetailsService.prefillTaxDetails(action.gstin).pipe(
                map((response) => {
                    const taxDetails = plainToInstance(TaxDetails, response.data);
                    return TaxDetailsActions.PREFILL_TAX_DETAILS_SUCCESS({ taxDetails: taxDetails })
                }),
                catchError((err, caught) => {
                    // this.snackbarService.openSnackBar('Failed to get billing details', undefined);
                    this.store.dispatch(
                        TaxDetailsActions.PREFILL_TAX_DETAILS_ERROR({ err: err.message }),
                    )
                    throw err;
                })
            )))
        ));

    postTaxDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TaxDetailsActions.POST_TAX_DETAILS),
            switchMap((action) => this.taxDetailsService.postTaxDetails(instanceToPlain(action.taxDetails)).pipe(
                map((response) => {
                    const taxDetails = plainToInstance(TaxDetails, response.data);
                    return TaxDetailsActions.POST_TAX_DETAILS_SUCCESS({ taxDetails: taxDetails })
                }),
                catchError((err, caught) => {
                    this.snackbarService.openSnackBar('Failed to update tax details', undefined);
                    this.store.dispatch(
                        TaxDetailsActions.POST_TAX_DETAILS_ERROR({ err: err.message }),
                    )
                    throw err;
                })
            ))
        )
    );

    patchTaxDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TaxDetailsActions.PATCH_TAX_DETAILS),
            switchMap((action) => this.taxDetailsService.patchTaxDetails(instanceToPlain(action.taxDetails)).pipe(
                map((response) => {
                    const taxDetails = plainToInstance(TaxDetails, response.data);
                    return TaxDetailsActions.PATCH_TAX_DETAILS_SUCCESS({ taxDetails: taxDetails })
                }),
                catchError((err, caught) => {
                    this.snackbarService.openSnackBar('Failed to update tax details', undefined);
                    this.store.dispatch(
                        TaxDetailsActions.PATCH_TAX_DETAILS_ERROR({ err: err.message }),
                    )
                    throw err;
                })
            ))
        )
    );
}