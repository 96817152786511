import { routerReducer } from "@ngrx/router-store";
import { RouterState } from "./router/router.model";
import { AccountantStore } from "./accountant-store/accountant-store.model";
import { AccountantStoreReducer } from "./accountant-store/accountant-store.reducers";
import { UserStoreReducer } from "./user-store/user-store.reducers";
import { UserStore } from "./user-store/user-store.model";
import { ReferenceDataStore } from "./reference-data-store/reference-data-store.model";
import { referenceDataReducer } from "./reference-data-store/reference-data-store.reducers";

export interface AppModel {
    [RootKeys.router]: RouterState,
    [RootKeys.user_store]: UserStore,
    [RootKeys.accountant_store]: AccountantStore,
    [RootKeys.reference_data_store]: ReferenceDataStore
}

export enum RootKeys {
    router = 'router',
    user_store = 'user_store',
    accountant_store = 'accountant_store',
    reference_data_store = 'reference_data_store'
}

export const RootReducers = {
    [RootKeys.router]: routerReducer,
    [RootKeys.user_store]: UserStoreReducer,
    [RootKeys.accountant_store]: AccountantStoreReducer,
    [RootKeys.reference_data_store]: referenceDataReducer
}