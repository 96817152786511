import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountantRow } from 'ngx-com-quicko-myaccount';
import { AvatarModule } from 'ngx-avatars';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-accountant-row',
  standalone: true,
  imports: [AvatarModule, MatIconModule, CommonModule, MatMenuModule, MatDividerModule, MatButtonModule],
  templateUrl: './accountant-row.component.html',
  styleUrls: ['./accountant-row.component.css']
})
export class AccountantRowComponent implements OnInit {

  constructor() { }

  @Input() accountantRow: AccountantRow | undefined;
  @Output() edit: EventEmitter<AccountantRow> = new EventEmitter<AccountantRow>()
  @Output() delete: EventEmitter<AccountantRow> = new EventEmitter<AccountantRow>()
  ngOnInit() {
  }

  deleteAccountant() {
    // this.showRive = true
    this.delete.emit(this.accountantRow);
  }

  editAccountant() {
    this.edit.emit(this.accountantRow);
  }

}
