import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserActions, AuthService, EntitlementsService, SnackbarService } from 'ngx-com-quicko-myaccount';
import { VerifyDetailsComponent } from '../../verify-details/verify-details.component';
import { MatIconModule } from '@angular/material/icon';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RiveModule } from 'ng-rive';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ButtonLoaderComponent } from 'projects/web-myaccount/src/app/common/components/button-loader/button-loader.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-edit-email-address',
  standalone: true,
  providers: [RxFormBuilder],
  imports: [CommonModule, MatButtonModule, ButtonLoaderComponent, MatIconModule, RouterModule, MatInputModule, ReactiveFormsModule, RxReactiveFormsModule, MatFormFieldModule, RiveModule],
  templateUrl: './edit-email-address.component.html',
  styleUrl: './edit-email-address.component.css'
})
export class EditEmailAddressComponent implements OnInit {
  emailForm: FormGroup;
  isUser: boolean = false;
  saveFormLoading: boolean = false;

  constructor(
    private entitlementsService: EntitlementsService,
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private snackbarService: SnackbarService
  ) {
    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnInit() {
    this.emailForm.controls['email'].valueChanges.subscribe(() => {
      this.isUser = false;
    });
  }

  update() {
    if (this.emailForm.valid) {
      this.saveFormLoading = true;

      this.entitlementsService
        .getOTPByNewEamil(
          this.emailForm.controls['email'].value
        )
        .subscribe({
          next: () => {

            const dialogRef = this.dialog.open(VerifyDetailsComponent, {
              data: { email: this.emailForm.controls['email'].value },
              width: '695px',
            });
            dialogRef.afterClosed().subscribe((token) => {
              if (token) {
                this.snackbarService.openSnackBar('Email updated successfully', undefined);
                this.authService.removeToken();
                this.authService.setToken(token);
                this.store.dispatch(UserActions.GET_USER());
                this.router.navigate(['profile']);
              }
            });
            this.saveFormLoading = false;
          },
          error: (err) => {
            if (err.error.message.includes('User already exist')) {
              this.isUser = true;
            }
            else if (err.error.message.includes('Too many OTP generation attempts')) {
              this.snackbarService.openSnackBar('Too many OTP generation attempts', undefined);
            }
            this.saveFormLoading = false;
          },
        });

    } else {
      this.emailForm.controls['email'].markAsTouched();
    }
  }
}
