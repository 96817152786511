import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { User, UserSelectors, DateUtil, TaxDetails, TaxDetailsSelectors, TaxDetailsActions } from 'ngx-com-quicko-myaccount';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-myaccount',
  standalone: true,
  imports: [MatIconModule, CommonModule, RouterModule, MatCardModule, MatDividerModule, MatButtonModule],
  templateUrl: './myaccount.component.html',
  styleUrl: './myaccount.component.css'
})
export class MyaccountComponent {
  hasName: boolean = true;
  userDetails$: Observable<User>;
  taxDetails$: Observable<TaxDetails>;
  taxDetails: TaxDetails;
  gender: string | undefined;
  dob: string | undefined;
  destroy$ = new Subject<void>()

  constructor(private store: Store, private actions$: Actions) {
    this.userDetails$ = this.store.select(UserSelectors.USER);
    this.taxDetails$ = this.store.select(TaxDetailsSelectors.TAX_DETAILS);
  }

  ngOnInit() {

    this.userDetails$.subscribe(user => {
      if (user.first_name || user.last_name) {
        this.hasName = true;
      } else {
        this.hasName = false;
      }
    })

    this.taxDetails$.pipe(takeUntil(this.destroy$)).subscribe((taxDetails: TaxDetails) => {
      if (taxDetails == undefined) {
        this.store.dispatch(TaxDetailsActions.GET_TAX_DETAILS())
      } else {
        this.taxDetails = taxDetails;
      }
    })

    this.actions$.pipe(ofType(TaxDetailsActions.GET_TAX_DETAILS_SUCCESS), takeUntil(this.destroy$)).subscribe(() => {
      this.taxDetails$.pipe(take(1)).subscribe((taxDetails: TaxDetails) => {
        this.taxDetails = taxDetails;
      })
    })


    this.userDetails$.subscribe((user: User) => {
      if (user.gender) {
        this.gender = user.gender == 'M' ? 'Male' : 'Female'
      }
      if (user.date_of_birth) {
        this.dob = DateUtil.printDate(DateUtil.fromMillis(user.date_of_birth))
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
