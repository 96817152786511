import { createFeatureSelector } from '@ngrx/store';
import { AccountantStore } from './accountant-store/accountant-store.model';
import { RootKeys } from './app.model';
import { UserStore } from './user-store/user-store.model';
import { ReferenceDataStore } from './reference-data-store/reference-data-store.model';

export const RootSelectors = {
    USER_STORE: createFeatureSelector<UserStore>(RootKeys.user_store),
    ACCOUNTANT_STORE: createFeatureSelector<AccountantStore>(RootKeys.accountant_store),
    REFERENCE_DATA_STORE: createFeatureSelector<ReferenceDataStore>(RootKeys.reference_data_store),
};
