import { createReducer, on } from '@ngrx/store';
import { OutgoingInvitationsActions } from './outgoing-invitations-store.actions';
import { OutgoingInvitationsModel } from './outgoing-invitations-store.model';
import { InvitationRow } from '../../../beans';


const onGetOutgoingInvitations = on(

    OutgoingInvitationsActions.GET_INVITATIONS_SUCCESS,
    (state: OutgoingInvitationsModel, { invitationRows, lastEvaluatedKey }) => {
        return {
            ...state,
            outgoing_invitations: invitationRows,
            last_evaluted_key: lastEvaluatedKey
        } as OutgoingInvitationsModel
    },
);


const onDeleteAccountant = on(
    OutgoingInvitationsActions.DELETE_INVITATION_SUCCESS,
    (state: OutgoingInvitationsModel, { accountantId }) => {
        const existingInvitations = state.outgoing_invitations;
        const filteredInvitations = existingInvitations.filter(
            (invitationRow: InvitationRow) => invitationRow.getId() != accountantId)
        return {
            ...state,
            outgoing_invitations: filteredInvitations
        } as OutgoingInvitationsModel
    }
)


export const outgoingInvitationsReducer = createReducer(
    {
        outgoing_invitations: [],
        last_evaluated_key: undefined
    },
    onGetOutgoingInvitations,
    onDeleteAccountant,
);
