import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User, UserActions, UserSelectors, EntitlementsService, ReferenceDataService, SnackbarService } from 'ngx-com-quicko-myaccount';
import { VerifyDetailsComponent } from '../../verify-details/verify-details.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MatSelectModule } from '@angular/material/select';
import { RiveModule } from 'ng-rive';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ButtonLoaderComponent } from '../../../../common/components/button-loader/button-loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-edit-mobile-number',
  standalone: true,
  providers: [RxFormBuilder],
  imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule, RouterModule, MatFormFieldModule, ButtonLoaderComponent, RiveModule, MatInputModule, ReactiveFormsModule, MatIconModule, RxReactiveFormsModule, MatSelectModule, RiveModule],
  templateUrl: './edit-mobile-number.component.html',
  styleUrl: './edit-mobile-number.component.css'
})
export class EditMobileNumberComponent implements OnInit {
  mobileForm: FormGroup;
  countryList: any;
  selectedCountry: any;
  isUser: boolean = false;
  userDetails: Observable<User> = this.store.select(UserSelectors.USER);
  user: any;
  saveFormLoading: boolean = false;

  constructor(
    private store: Store,
    private entitlementsService: EntitlementsService,
    private router: Router,
    public dialog: MatDialog,
    private referenceDataService: ReferenceDataService,
    private snackbarService: SnackbarService
  ) {
    this.mobileForm = new FormGroup({
      mobile: new FormControl('', [Validators.required, Validators.pattern(/\d/)]),
    });
    this.referenceDataService.getCountries().subscribe((response: any) => {
      this.countryList = response['data'];
      this.selectedCountry = this.countryList.find(
        (c: any) => c.numeric_code === 91
      );
    });
  }

  ngOnInit() {
    this.userDetails.subscribe((userDetails) => {
      this.user = userDetails;
    });
    this.mobileForm.controls['mobile'].valueChanges.subscribe(() => {
      this.isUser = false;
    });
  }

  update() {
    if (this.mobileForm.valid && this.selectedCountry) {
      this.saveFormLoading = true;

      this.entitlementsService
        .getOTPByNewMobile(
          this.user.username!,
          this.mobileForm.controls['mobile'].value,
          this.selectedCountry.numeric_code
        )
        .subscribe({
          next: () => {
            const dialogRef = this.dialog.open(VerifyDetailsComponent, {
              data: {
                mobile: this.mobileForm.controls['mobile'].value,
                countryCode: this.selectedCountry.numeric_code,
              },
              width: '695px',
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.snackbarService.openSnackBar('Mobile number updated successfully', undefined);
                this.store.dispatch(UserActions.GET_USER());
                this.router.navigate(['profile']);
              }
            });
            this.saveFormLoading = false;
          },
          error: (err) => {
            if (err.error.message.includes('User already exist')) {
              this.isUser = true;
            }
            else if (err.error.message.includes('Too many OTP generation attempts')) {
              this.snackbarService.openSnackBar('Too many OTP generation attempts', undefined);
            }
            this.saveFormLoading = false;
          },
        });
    } else {
      this.mobileForm.controls['mobile'].markAsTouched();
    }
  }
}
