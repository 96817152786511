<a routerLink="../../">
  <mat-icon class="material-symbols-outlined text-text-primary w-8 h-8 text-[32px]">arrow_circle_left</mat-icon></a>
<div class="flex flex-col gap-4">
  <div class="flex flex-col gap-1">
    <div class="text-xl font-semibold leading-9 text-left">
      Third Party Apps & Services
    </div>
    <div class="text-base font-medium leading-6 text-left">
      Control which apps and services have access to your Quicko data
    </div>
  </div>
  <ng-container *ngIf="isLoading">
    <div *ngFor="let item of [0,1,2,3]" class="flex flex-col">
      <div
        class="px-6 py-2 rounded-lg border border-outline-variant flex justify-between h-[81px] items-center cursor-pointer">
        <div class="flex flex-row gap-4">
          <div class="flex flex-col items-center justify-center">
            <ngx-skeleton-loader
              [theme]="{ height: '40px', width:'40px', margin: '0px', 'background-color': 'var(--sys-surface-container-high)'}">
            </ngx-skeleton-loader>
          </div>
          <div class="flex flex-col gap-1">
            <div class="font-generalsans text-base font-medium leading-6 text-left">
              <ngx-skeleton-loader
                [theme]="{ height: '24', width:'110px', margin: '0px','background-color': 'var(--sys-surface-container-high)'}">
              </ngx-skeleton-loader>
            </div>
            <div class="font-generalsans text-sm font-medium leading-6 text-left">
              <ngx-skeleton-loader
                [theme]="{ height: '24', width:'200px', margin: '0px','background-color': 'var(--sys-surface-container-high)'}">
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <!-- <div class="flex flex-col items-center justify-center">
          <mat-icon class="material-symbols-outlined text-text-primary">chevron_right</mat-icon>
        </div> -->
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading && apps && apps.length>0">
    <div class="flex flex-col gap-4" *ngFor="let app of apps">
      <app-app-card [app]="app"></app-app-card>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoading && apps && apps.length==0">
    <div class="flex flex-col items-center justify-center mt-16 gap-4">
      <img src="../../../../../../assets/illustrations/il_no_apps.svg" class="w-[400px] h-[240px]">
      <div class="mat-body-1 text-text-secondary text-center mt-8">You do not have any connected third party apps</div>
    </div>
  </ng-container>
</div>