import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '../services';
import { RouterSelectors } from '../store/router/router.selectors';
import { ENV_CONFIG } from '../config/token';

export const loggedIn: CanActivateFn = async (): Promise<boolean> => {
    const authService = inject(AuthService);
    const environment: any = inject(ENV_CONFIG);
    const authentication_host = environment.accounts_app_host;
    console.log(authentication_host)

    if (!authService.isTokenExpired(authService.getToken())) {
        // console.log(this.authService.getTokenExpiry(this.authService.getToken()));  
        if (authService.isAuthenticated() == true)
            return true;

        return false;
    }
    else {
        authService.removeToken();
        // console.log("Route Guards : " , url);
        // window.location.reload();
        window.location.href = authentication_host + "login" + "?redirect=" + window.btoa(window.location.href);
        return false;
    }
}