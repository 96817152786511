<div class="flex flex-col p-6 w-[540px] h-[235px]">
  <div class="flex flex-row justify-between">
    <p class="mat-subtitle-2 fw-600">{{data.title}}</p>

    <mat-icon class="h-[28px] w-[28px] text-text-primary material-symbols-outlined cursor-pointer" (click)="onCancel()">
      cancel
    </mat-icon>
  </div>

  <p class="mat-body-1 fw-500 text-text-secondary mt-2">{{data.description}}</p>

  <div class="flex flex-row h-[48px] mt-auto">
    <button (click)="onConfirm()" mat-flat-button color="warn" class="white rounded mat-body-1 px-8 py-6 mr-4">
      {{data.delete_button_text}}
    </button>

    <button (click)="onCancel()" mat-stroked-button
      class="text-text-primary grey-border rounded border-stroke mat-body-1 px-8 py-6 mr-4">
      {{data.cancel_button_text}}
    </button>
  </div>
</div>