var Rive = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  return function (moduleArg = {}) {
    var l = moduleArg,
      aa,
      ba;
    l.ready = new Promise((b, a) => {
      aa = b;
      ba = a;
    });
    function da() {
      function b(h) {
        const g = d;
        c = a = 0;
        d = new Map();
        g.forEach(m => {
          try {
            m(h);
          } catch (k) {
            console.error(k);
          }
        });
        this.Qa();
        e && e.nb();
      }
      let a = 0,
        c = 0,
        d = new Map(),
        e = null,
        f = null;
      this.requestAnimationFrame = function (h) {
        a || (a = requestAnimationFrame(b.bind(this)));
        const g = ++c;
        d.set(g, h);
        return g;
      };
      this.cancelAnimationFrame = function (h) {
        d.delete(h);
        a && 0 == d.size && (cancelAnimationFrame(a), a = 0);
      };
      this.lb = function (h) {
        f && (document.body.remove(f), f = null);
        h || (f = document.createElement("div"), f.style.backgroundColor = "black", f.style.position = "fixed", f.style.right = 0, f.style.top = 0, f.style.color = "white", f.style.padding = "4px", f.innerHTML = "RIVE FPS", h = function (g) {
          f.innerHTML = "RIVE FPS " + g.toFixed(1);
        }, document.body.appendChild(f));
        e = new function () {
          let g = 0,
            m = 0;
          this.nb = function () {
            var k = performance.now();
            m ? (++g, k -= m, 1E3 < k && (h(1E3 * g / k), g = m = 0)) : (m = k, g = 0);
          };
        }();
      };
      this.ib = function () {
        f && (document.body.remove(f), f = null);
        e = null;
      };
      this.Qa = function () {};
    }
    function ea(b) {
      console.assert(!0);
      const a = new Map();
      let c = -Infinity;
      this.push = function (d) {
        d = d + ((1 << b) - 1) >> b;
        a.has(d) && clearTimeout(a.get(d));
        a.set(d, setTimeout(function () {
          a.delete(d);
          0 == a.length ? c = -Infinity : d == c && (c = Math.max(...a.keys()), console.assert(c < d));
        }, 1E3));
        c = Math.max(d, c);
        return c << b;
      };
    }
    const fa = new function () {
      function b() {
        if (!a) {
          var t = document.createElement("canvas"),
            v = {
              alpha: 1,
              depth: 0,
              stencil: 0,
              antialias: 0,
              premultipliedAlpha: 1,
              preserveDrawingBuffer: 0,
              preferLowPowerToHighPerformance: 0,
              failIfMajorPerformanceCaveat: 0,
              enableExtensionsByDefault: 1,
              explicitSwapControl: 1,
              renderViaOffscreenBackBuffer: 1
            };
          let q = t.getContext("webgl2", v);
          if (q) c = 2;else if (q = t.getContext("webgl", v)) c = 1;else return console.log("No WebGL support. Image mesh will not be drawn."), !1;
          d = Math.min(q.getParameter(q.MAX_RENDERBUFFER_SIZE), q.getParameter(q.MAX_TEXTURE_SIZE));
          function F(I, w, y) {
            w = q.createShader(w);
            q.shaderSource(w, y);
            q.compileShader(w);
            y = q.getShaderInfoLog(w);
            if (0 < y.length) throw y;
            q.attachShader(I, w);
          }
          t = q.createProgram();
          F(t, q.VERTEX_SHADER, "attribute vec2 vertex;\n                attribute vec2 uv;\n                uniform vec4 mat;\n                uniform vec2 translate;\n                varying vec2 st;\n                void main() {\n                    st = uv;\n                    gl_Position = vec4(mat2(mat) * vertex + translate, 0, 1);\n                }");
          F(t, q.FRAGMENT_SHADER, "precision highp float;\n                uniform sampler2D image;\n                varying vec2 st;\n                void main() {\n                    gl_FragColor = texture2D(image, st);\n                }");
          q.bindAttribLocation(t, 0, "vertex");
          q.bindAttribLocation(t, 1, "uv");
          q.linkProgram(t);
          v = q.getProgramInfoLog(t);
          if (0 < v.trim().length) throw v;
          e = q.getUniformLocation(t, "mat");
          f = q.getUniformLocation(t, "translate");
          q.useProgram(t);
          q.bindBuffer(q.ARRAY_BUFFER, q.createBuffer());
          q.enableVertexAttribArray(0);
          q.enableVertexAttribArray(1);
          q.bindBuffer(q.ELEMENT_ARRAY_BUFFER, q.createBuffer());
          q.uniform1i(q.getUniformLocation(t, "image"), 0);
          q.pixelStorei(q.UNPACK_PREMULTIPLY_ALPHA_WEBGL, !0);
          a = q;
        }
        return !0;
      }
      let a = null,
        c = 0,
        d = 0,
        e = null,
        f = null,
        h = 0,
        g = 0;
      this.wb = function () {
        b();
        return d;
      };
      this.gb = function (t) {
        if (!b()) return null;
        const v = a.createTexture();
        a.bindTexture(a.TEXTURE_2D, v);
        a.texImage2D(a.TEXTURE_2D, 0, a.RGBA, a.RGBA, a.UNSIGNED_BYTE, t);
        a.texParameteri(a.TEXTURE_2D, a.TEXTURE_WRAP_S, a.CLAMP_TO_EDGE);
        a.texParameteri(a.TEXTURE_2D, a.TEXTURE_WRAP_T, a.CLAMP_TO_EDGE);
        a.texParameteri(a.TEXTURE_2D, a.TEXTURE_MAG_FILTER, a.LINEAR);
        2 == c ? (a.texParameteri(a.TEXTURE_2D, a.TEXTURE_MIN_FILTER, a.LINEAR_MIPMAP_LINEAR), a.generateMipmap(a.TEXTURE_2D)) : a.texParameteri(a.TEXTURE_2D, a.TEXTURE_MIN_FILTER, a.LINEAR);
        return v;
      };
      const m = new ea(8),
        k = new ea(8),
        p = new ea(10),
        r = new ea(10);
      this.kb = function (t, v, q, F, I) {
        if (b()) {
          var w = m.push(t),
            y = k.push(v);
          if (a.canvas.width != w || a.canvas.height != y) a.canvas.width = w, a.canvas.height = y;
          a.viewport(0, y - v, t, v);
          a.disable(a.SCISSOR_TEST);
          a.clearColor(0, 0, 0, 0);
          a.clear(a.COLOR_BUFFER_BIT);
          a.enable(a.SCISSOR_TEST);
          q.sort((z, ca) => ca.Ua - z.Ua);
          w = p.push(F);
          h != w && (a.bufferData(a.ARRAY_BUFFER, 8 * w, a.DYNAMIC_DRAW), h = w);
          w = 0;
          for (var J of q) a.bufferSubData(a.ARRAY_BUFFER, w, J.Da), w += 4 * J.Da.length;
          console.assert(w == 4 * F);
          for (var P of q) a.bufferSubData(a.ARRAY_BUFFER, w, P.Xa), w += 4 * P.Xa.length;
          console.assert(w == 8 * F);
          w = r.push(I);
          g != w && (a.bufferData(a.ELEMENT_ARRAY_BUFFER, 2 * w, a.DYNAMIC_DRAW), g = w);
          J = 0;
          for (var W of q) a.bufferSubData(a.ELEMENT_ARRAY_BUFFER, J, W.indices), J += 2 * W.indices.length;
          console.assert(J == 2 * I);
          W = 0;
          P = !0;
          w = J = 0;
          for (const z of q) {
            z.image.za != W && (a.bindTexture(a.TEXTURE_2D, z.image.cb || null), W = z.image.za);
            z.zb ? (a.scissor(z.Ia, y - z.Ja - z.Pa, z.Gb, z.Pa), P = !0) : P && (a.scissor(0, y - v, t, v), P = !1);
            q = 2 / t;
            const ca = -2 / v;
            a.uniform4f(e, z.oa[0] * q * z.va, z.oa[1] * ca * z.wa, z.oa[2] * q * z.va, z.oa[3] * ca * z.wa);
            a.uniform2f(f, z.oa[4] * q * z.va + q * (z.Ia - z.xb * z.va) - 1, z.oa[5] * ca * z.wa + ca * (z.Ja - z.yb * z.wa) + 1);
            a.vertexAttribPointer(0, 2, a.FLOAT, !1, 0, w);
            a.vertexAttribPointer(1, 2, a.FLOAT, !1, 0, w + 4 * F);
            a.drawElements(a.TRIANGLES, z.indices.length, a.UNSIGNED_SHORT, J);
            w += 4 * z.Da.length;
            J += 2 * z.indices.length;
          }
          console.assert(w == 4 * F);
          console.assert(J == 2 * I);
        }
      };
      this.canvas = function () {
        return b() && a.canvas;
      };
    }();
    l.onRuntimeInitialized = function () {
      function b(n) {
        switch (n) {
          case k.srcOver:
            return "source-over";
          case k.screen:
            return "screen";
          case k.overlay:
            return "overlay";
          case k.darken:
            return "darken";
          case k.lighten:
            return "lighten";
          case k.colorDodge:
            return "color-dodge";
          case k.colorBurn:
            return "color-burn";
          case k.hardLight:
            return "hard-light";
          case k.softLight:
            return "soft-light";
          case k.difference:
            return "difference";
          case k.exclusion:
            return "exclusion";
          case k.multiply:
            return "multiply";
          case k.hue:
            return "hue";
          case k.saturation:
            return "saturation";
          case k.color:
            return "color";
          case k.luminosity:
            return "luminosity";
        }
      }
      function a(n) {
        return "rgba(" + ((16711680 & n) >>> 16) + "," + ((65280 & n) >>> 8) + "," + ((255 & n) >>> 0) + "," + ((4278190080 & n) >>> 24) / 255 + ")";
      }
      function c() {
        0 < J.length && (fa.kb(y.drawWidth(), y.drawHeight(), J, P, W), J = [], W = P = 0, y.reset(512, 512));
        for (const n of w) {
          for (const u of n.da) u();
          n.da = [];
        }
        w.clear();
      }
      var d = l.RenderPaintStyle;
      const e = l.RenderPath,
        f = l.RenderPaint,
        h = l.Renderer,
        g = l.StrokeCap,
        m = l.StrokeJoin,
        k = l.BlendMode,
        p = d.fill,
        r = d.stroke,
        t = l.FillRule.evenOdd;
      let v = 1;
      var q = l.RenderImage.extend("CanvasRenderImage", {
          __construct: function () {
            this.__parent.__construct.call(this);
            this.za = v;
            v = v + 1 & 2147483647 || 1;
          },
          decode: function (n) {
            let u = Wa;
            u.total++;
            var D = this,
              B = new Image();
            B.src = URL.createObjectURL(new Blob([n], {
              type: "image/png"
            }));
            B.onload = function () {
              D.ab = B;
              D.cb = fa.gb(B);
              D.size(B.width, B.height);
              u.loaded++;
              if (u.loaded === u.total) {
                const E = u.ready;
                E && (E(), u.ready = null);
              }
            };
          }
        }),
        F = e.extend("CanvasRenderPath", {
          __construct: function () {
            this.__parent.__construct.call(this);
            this.ia = new Path2D();
          },
          rewind: function () {
            this.ia = new Path2D();
          },
          addPath: function (n, u, D, B, E, G, A) {
            var C = this.ia,
              R = C.addPath;
            n = n.ia;
            const K = new DOMMatrix();
            K.a = u;
            K.b = D;
            K.c = B;
            K.d = E;
            K.e = G;
            K.f = A;
            R.call(C, n, K);
          },
          fillRule: function (n) {
            this.Ga = n;
          },
          moveTo: function (n, u) {
            this.ia.moveTo(n, u);
          },
          lineTo: function (n, u) {
            this.ia.lineTo(n, u);
          },
          cubicTo: function (n, u, D, B, E, G) {
            this.ia.bezierCurveTo(n, u, D, B, E, G);
          },
          close: function () {
            this.ia.closePath();
          }
        }),
        I = f.extend("CanvasRenderPaint", {
          color: function (n) {
            this.Ha = a(n);
          },
          thickness: function (n) {
            this.eb = n;
          },
          join: function (n) {
            switch (n) {
              case m.miter:
                this.ya = "miter";
                break;
              case m.round:
                this.ya = "round";
                break;
              case m.bevel:
                this.ya = "bevel";
            }
          },
          cap: function (n) {
            switch (n) {
              case g.butt:
                this.xa = "butt";
                break;
              case g.round:
                this.xa = "round";
                break;
              case g.square:
                this.xa = "square";
            }
          },
          style: function (n) {
            this.bb = n;
          },
          blendMode: function (n) {
            this.$a = b(n);
          },
          clearGradient: function () {
            this.qa = null;
          },
          linearGradient: function (n, u, D, B) {
            this.qa = {
              Va: n,
              Wa: u,
              La: D,
              Ma: B,
              Ca: []
            };
          },
          radialGradient: function (n, u, D, B) {
            this.qa = {
              Va: n,
              Wa: u,
              La: D,
              Ma: B,
              Ca: [],
              ub: !0
            };
          },
          addStop: function (n, u) {
            this.qa.Ca.push({
              color: n,
              stop: u
            });
          },
          completeGradient: function () {},
          draw: function (n, u, D) {
            let B = this.bb;
            var E = this.Ha,
              G = this.qa;
            n.globalCompositeOperation = this.$a;
            if (null != G) {
              E = G.Va;
              var A = G.Wa;
              const R = G.La;
              var C = G.Ma;
              const K = G.Ca;
              G.ub ? (G = R - E, C -= A, E = n.createRadialGradient(E, A, 0, E, A, Math.sqrt(G * G + C * C))) : E = n.createLinearGradient(E, A, R, C);
              for (let T = 0, M = K.length; T < M; T++) A = K[T], E.addColorStop(A.stop, a(A.color));
              this.Ha = E;
              this.qa = null;
            }
            switch (B) {
              case r:
                n.strokeStyle = E;
                n.lineWidth = this.eb;
                n.lineCap = this.xa;
                n.lineJoin = this.ya;
                n.stroke(u);
                break;
              case p:
                n.fillStyle = E, n.fill(u, D);
            }
          }
        });
      const w = new Set();
      let y = null,
        J = [],
        P = 0,
        W = 0;
      var z = l.CanvasRenderer = h.extend("Renderer", {
        __construct: function (n) {
          this.__parent.__construct.call(this);
          this.ha = [1, 0, 0, 1, 0, 0];
          this.ba = n.getContext("2d");
          this.Fa = n;
          this.da = [];
        },
        save: function () {
          this.ha.push(...this.ha.slice(this.ha.length - 6));
          this.da.push(this.ba.save.bind(this.ba));
        },
        restore: function () {
          const n = this.ha.length - 6;
          if (6 > n) throw "restore() called without matching save().";
          this.ha.splice(n);
          this.da.push(this.ba.restore.bind(this.ba));
        },
        transform: function (n, u, D, B, E, G) {
          const A = this.ha,
            C = A.length - 6;
          A.splice(C, 6, A[C] * n + A[C + 2] * u, A[C + 1] * n + A[C + 3] * u, A[C] * D + A[C + 2] * B, A[C + 1] * D + A[C + 3] * B, A[C] * E + A[C + 2] * G + A[C + 4], A[C + 1] * E + A[C + 3] * G + A[C + 5]);
          this.da.push(this.ba.transform.bind(this.ba, n, u, D, B, E, G));
        },
        rotate: function (n) {
          const u = Math.sin(n);
          n = Math.cos(n);
          this.transform(n, u, -u, n, 0, 0);
        },
        _drawPath: function (n, u) {
          this.da.push(u.draw.bind(u, this.ba, n.ia, n.Ga === t ? "evenodd" : "nonzero"));
        },
        drawImage: function (n, ...u) {
          var D = this.ba;
          this.da.push(function () {
            D.drawImage(n, ...u);
          });
        },
        _drawRiveImage: function (n, u, D) {
          var B = n.ab;
          if (B) {
            var E = this.ba,
              G = b(u);
            this.da.push(function () {
              E.globalCompositeOperation = G;
              E.globalAlpha = D;
              E.drawImage(B, 0, 0);
              E.globalAlpha = 1;
            });
          }
        },
        _getMatrix: function (n) {
          const u = this.ha,
            D = u.length - 6;
          for (let B = 0; 6 > B; ++B) n[B] = u[D + B];
        },
        _drawImageMesh: function (n, u, D, B, E, G, A, C, R, K) {
          var T = this.ba.canvas.width,
            M = this.ba.canvas.height;
          const ob = R - A,
            pb = K - C;
          A = Math.max(A, 0);
          C = Math.max(C, 0);
          R = Math.min(R, T);
          K = Math.min(K, M);
          const ta = R - A,
            ua = K - C;
          console.assert(ta <= Math.min(ob, T));
          console.assert(ua <= Math.min(pb, M));
          if (!(0 >= ta || 0 >= ua)) {
            R = ta < ob || ua < pb;
            T = K = 1;
            var ha = Math.ceil(ta * K),
              ia = Math.ceil(ua * T);
            M = fa.wb();
            ha > M && (K *= M / ha, ha = M);
            ia > M && (T *= M / ia, ia = M);
            y || (y = new l.DynamicRectanizer(M), y.reset(512, 512));
            M = y.addRect(ha, ia);
            0 > M && (c(), w.add(this), M = y.addRect(ha, ia), console.assert(0 <= M));
            var qb = M & 65535,
              rb = M >> 16;
            J.push({
              oa: this.ha.slice(this.ha.length - 6),
              image: n,
              Ia: qb,
              Ja: rb,
              xb: A,
              yb: C,
              Gb: ha,
              Pa: ia,
              va: K,
              wa: T,
              Da: new Float32Array(B),
              Xa: new Float32Array(E),
              indices: new Uint16Array(G),
              zb: R,
              Ua: n.za << 1 | (R ? 1 : 0)
            });
            P += B.length;
            W += G.length;
            var ma = this.ba,
              bc = b(u);
            this.da.push(function () {
              ma.save();
              ma.resetTransform();
              ma.globalCompositeOperation = bc;
              ma.globalAlpha = D;
              ma.drawImage(fa.canvas(), qb, rb, ha, ia, A, C, ta, ua);
              ma.restore();
            });
          }
        },
        _clipPath: function (n) {
          this.da.push(this.ba.clip.bind(this.ba, n.ia, n.Ga === t ? "evenodd" : "nonzero"));
        },
        clear: function () {
          w.add(this);
          this.da.push(this.ba.clearRect.bind(this.ba, 0, 0, this.Fa.width, this.Fa.height));
        },
        flush: function () {},
        translate: function (n, u) {
          this.transform(1, 0, 0, 1, n, u);
        }
      });
      l.makeRenderer = function (n) {
        return new z(n);
      };
      l.renderFactory = {
        makeRenderPaint: function () {
          return new I();
        },
        makeRenderPath: function () {
          return new F();
        },
        makeRenderImage: function () {
          return new q();
        }
      };
      let ca = l.load,
        Wa = null;
      l.load = function (n) {
        return new Promise(function (u) {
          let D = null;
          Wa = {
            total: 0,
            loaded: 0,
            ready: function () {
              u(D);
            }
          };
          D = ca(n);
          0 == Wa.total && u(D);
        });
      };
      d = new da();
      l.requestAnimationFrame = d.requestAnimationFrame.bind(d);
      l.cancelAnimationFrame = d.cancelAnimationFrame.bind(d);
      l.enableFPSCounter = d.lb.bind(d);
      l.disableFPSCounter = d.ib;
      d.Qa = c;
      l.cleanup = function () {
        y && y.delete();
      };
    };
    var ja = Object.assign({}, l),
      ka = "./this.program",
      la = "object" == typeof window,
      na = "function" == typeof importScripts,
      x = "",
      oa,
      pa;
    if (la || na) na ? x = self.location.href : "undefined" != typeof document && document.currentScript && (x = document.currentScript.src), _scriptDir && (x = _scriptDir), 0 !== x.indexOf("blob:") ? x = x.substr(0, x.replace(/[?#].*/, "").lastIndexOf("/") + 1) : x = "", na && (pa = b => {
      var a = new XMLHttpRequest();
      a.open("GET", b, !1);
      a.responseType = "arraybuffer";
      a.send(null);
      return new Uint8Array(a.response);
    }), oa = (b, a, c) => {
      var d = new XMLHttpRequest();
      d.open("GET", b, !0);
      d.responseType = "arraybuffer";
      d.onload = () => {
        200 == d.status || 0 == d.status && d.response ? a(d.response) : c();
      };
      d.onerror = c;
      d.send(null);
    };
    var qa = l.print || console.log.bind(console),
      ra = l.printErr || console.error.bind(console);
    Object.assign(l, ja);
    ja = null;
    l.thisProgram && (ka = l.thisProgram);
    var sa;
    l.wasmBinary && (sa = l.wasmBinary);
    var noExitRuntime = l.noExitRuntime || !0;
    "object" != typeof WebAssembly && va("no native wasm support detected");
    var wa,
      xa = !1,
      ya,
      H,
      za,
      Aa,
      Ba,
      L,
      Ca,
      Da;
    function Ea() {
      var b = wa.buffer;
      l.HEAP8 = ya = new Int8Array(b);
      l.HEAP16 = za = new Int16Array(b);
      l.HEAP32 = Ba = new Int32Array(b);
      l.HEAPU8 = H = new Uint8Array(b);
      l.HEAPU16 = Aa = new Uint16Array(b);
      l.HEAPU32 = L = new Uint32Array(b);
      l.HEAPF32 = Ca = new Float32Array(b);
      l.HEAPF64 = Da = new Float64Array(b);
    }
    var Fa,
      Ga = [],
      Ha = [],
      Ia = [];
    function Ja() {
      var b = l.preRun.shift();
      Ga.unshift(b);
    }
    var Ka = 0,
      La = null,
      Ma = null;
    function va(b) {
      if (l.onAbort) l.onAbort(b);
      b = "Aborted(" + b + ")";
      ra(b);
      xa = !0;
      b = new WebAssembly.RuntimeError(b + ". Build with -sASSERTIONS for more info.");
      ba(b);
      throw b;
    }
    function Na(b) {
      return b.startsWith("data:application/octet-stream;base64,");
    }
    var Oa;
    Oa = "canvas_advanced.wasm";
    if (!Na(Oa)) {
      var Pa = Oa;
      Oa = l.locateFile ? l.locateFile(Pa, x) : x + Pa;
    }
    function Qa(b) {
      if (b == Oa && sa) return new Uint8Array(sa);
      if (pa) return pa(b);
      throw "both async and sync fetching of the wasm failed";
    }
    function Ra(b) {
      if (!sa && (la || na)) {
        if ("function" == typeof fetch && !b.startsWith("file://")) return fetch(b, {
          credentials: "same-origin"
        }).then(a => {
          if (!a.ok) throw "failed to load wasm binary file at '" + b + "'";
          return a.arrayBuffer();
        }).catch(() => Qa(b));
        if (oa) return new Promise((a, c) => {
          oa(b, d => a(new Uint8Array(d)), c);
        });
      }
      return Promise.resolve().then(() => Qa(b));
    }
    function Sa(b, a, c) {
      return Ra(b).then(d => WebAssembly.instantiate(d, a)).then(d => d).then(c, d => {
        ra("failed to asynchronously prepare wasm: " + d);
        va(d);
      });
    }
    function Ta(b, a) {
      var c = Oa;
      return sa || "function" != typeof WebAssembly.instantiateStreaming || Na(c) || c.startsWith("file://") || "function" != typeof fetch ? Sa(c, b, a) : fetch(c, {
        credentials: "same-origin"
      }).then(d => WebAssembly.instantiateStreaming(d, b).then(a, function (e) {
        ra("wasm streaming compile failed: " + e);
        ra("falling back to ArrayBuffer instantiation");
        return Sa(c, b, a);
      }));
    }
    var Ua = b => {
      for (; 0 < b.length;) b.shift()(l);
    };
    function Va(b) {
      if (void 0 === b) return "_unknown";
      b = b.replace(/[^a-zA-Z0-9_]/g, "$");
      var a = b.charCodeAt(0);
      return 48 <= a && 57 >= a ? `_${b}` : b;
    }
    function Xa(b, a) {
      b = Va(b);
      return {
        [b]: function () {
          return a.apply(this, arguments);
        }
      }[b];
    }
    function Ya() {
      this.ja = [void 0];
      this.Oa = [];
    }
    var N = new Ya(),
      Za = void 0;
    function O(b) {
      throw new Za(b);
    }
    var $a = b => {
        b || O("Cannot use deleted val. handle = " + b);
        return N.get(b).value;
      },
      Q = b => {
        switch (b) {
          case void 0:
            return 1;
          case null:
            return 2;
          case !0:
            return 3;
          case !1:
            return 4;
          default:
            return N.Ya({
              Ta: 1,
              value: b
            });
        }
      };
    function ab(b) {
      var a = Error,
        c = Xa(b, function (d) {
          this.name = b;
          this.message = d;
          d = Error(d).stack;
          void 0 !== d && (this.stack = this.toString() + "\n" + d.replace(/^Error(:[^\n]*)?\n/, ""));
        });
      c.prototype = Object.create(a.prototype);
      c.prototype.constructor = c;
      c.prototype.toString = function () {
        return void 0 === this.message ? this.name : `${this.name}: ${this.message}`;
      };
      return c;
    }
    var bb = void 0,
      cb = void 0;
    function S(b) {
      for (var a = ""; H[b];) a += cb[H[b++]];
      return a;
    }
    var db = [];
    function eb() {
      for (; db.length;) {
        var b = db.pop();
        b.W.na = !1;
        b["delete"]();
      }
    }
    var fb = void 0,
      U = {};
    function gb(b, a) {
      for (void 0 === a && O("ptr should not be undefined"); b.$;) a = b.ra(a), b = b.$;
      return a;
    }
    var hb = {};
    function ib(b) {
      b = jb(b);
      var a = S(b);
      V(b);
      return a;
    }
    function kb(b, a) {
      var c = hb[b];
      void 0 === c && O(a + " has unknown type " + ib(b));
      return c;
    }
    function lb() {}
    var mb = !1;
    function nb(b) {
      --b.count.value;
      0 === b.count.value && (b.ca ? b.fa.ka(b.ca) : b.Z.X.ka(b.Y));
    }
    function sb(b, a, c) {
      if (a === c) return b;
      if (void 0 === c.$) return null;
      b = sb(b, a, c.$);
      return null === b ? null : c.jb(b);
    }
    var tb = {};
    function ub(b, a) {
      a = gb(b, a);
      return U[a];
    }
    var vb = void 0;
    function wb(b) {
      throw new vb(b);
    }
    function xb(b, a) {
      a.Z && a.Y || wb("makeClassHandle requires ptr and ptrType");
      !!a.fa !== !!a.ca && wb("Both smartPtrType and smartPtr must be specified");
      a.count = {
        value: 1
      };
      return yb(Object.create(b, {
        W: {
          value: a
        }
      }));
    }
    function yb(b) {
      if ("undefined" === typeof FinalizationRegistry) return yb = a => a, b;
      mb = new FinalizationRegistry(a => {
        nb(a.W);
      });
      yb = a => {
        var c = a.W;
        c.ca && mb.register(a, {
          W: c
        }, a);
        return a;
      };
      lb = a => {
        mb.unregister(a);
      };
      return yb(b);
    }
    var zb = {};
    function Ab(b) {
      for (; b.length;) {
        var a = b.pop();
        b.pop()(a);
      }
    }
    function Bb(b) {
      return this.fromWireType(Ba[b >> 2]);
    }
    var Cb = {},
      Db = {};
    function X(b, a, c) {
      function d(g) {
        g = c(g);
        g.length !== b.length && wb("Mismatched type converter count");
        for (var m = 0; m < b.length; ++m) Y(b[m], g[m]);
      }
      b.forEach(function (g) {
        Db[g] = a;
      });
      var e = Array(a.length),
        f = [],
        h = 0;
      a.forEach((g, m) => {
        hb.hasOwnProperty(g) ? e[m] = hb[g] : (f.push(g), Cb.hasOwnProperty(g) || (Cb[g] = []), Cb[g].push(() => {
          e[m] = hb[g];
          ++h;
          h === f.length && d(e);
        }));
      });
      0 === f.length && d(e);
    }
    function Eb(b) {
      switch (b) {
        case 1:
          return 0;
        case 2:
          return 1;
        case 4:
          return 2;
        case 8:
          return 3;
        default:
          throw new TypeError(`Unknown type size: ${b}`);
      }
    }
    function Fb(b, a, c = {}) {
      var d = a.name;
      b || O(`type "${d}" must have a positive integer typeid pointer`);
      if (hb.hasOwnProperty(b)) {
        if (c.tb) return;
        O(`Cannot register type '${d}' twice`);
      }
      hb[b] = a;
      delete Db[b];
      Cb.hasOwnProperty(b) && (a = Cb[b], delete Cb[b], a.forEach(e => e()));
    }
    function Y(b, a, c = {}) {
      if (!("argPackAdvance" in a)) throw new TypeError("registerType registeredInstance requires argPackAdvance");
      Fb(b, a, c);
    }
    function Gb(b) {
      O(b.W.Z.X.name + " instance already deleted");
    }
    function Hb() {}
    function Ib(b, a, c) {
      if (void 0 === b[a].aa) {
        var d = b[a];
        b[a] = function () {
          b[a].aa.hasOwnProperty(arguments.length) || O(`Function '${c}' called with an invalid number of arguments (${arguments.length}) - expects one of (${b[a].aa})!`);
          return b[a].aa[arguments.length].apply(this, arguments);
        };
        b[a].aa = [];
        b[a].aa[d.ma] = d;
      }
    }
    function Jb(b, a, c) {
      l.hasOwnProperty(b) ? ((void 0 === c || void 0 !== l[b].aa && void 0 !== l[b].aa[c]) && O(`Cannot register public name '${b}' twice`), Ib(l, b, b), l.hasOwnProperty(c) && O(`Cannot register multiple overloads of a function with the same number of arguments (${c})!`), l[b].aa[c] = a) : (l[b] = a, void 0 !== c && (l[b].Hb = c));
    }
    function Kb(b, a, c, d, e, f, h, g) {
      this.name = b;
      this.constructor = a;
      this.ga = c;
      this.ka = d;
      this.$ = e;
      this.ob = f;
      this.ra = h;
      this.jb = g;
      this.Ra = [];
    }
    function Lb(b, a, c) {
      for (; a !== c;) a.ra || O(`Expected null or instance of ${c.name}, got an instance of ${a.name}`), b = a.ra(b), a = a.$;
      return b;
    }
    function Mb(b, a) {
      if (null === a) return this.Aa && O(`null is not a valid ${this.name}`), 0;
      a.W || O(`Cannot pass "${Nb(a)}" as a ${this.name}`);
      a.W.Y || O(`Cannot pass deleted object as a pointer of type ${this.name}`);
      return Lb(a.W.Y, a.W.Z.X, this.X);
    }
    function Ob(b, a) {
      if (null === a) {
        this.Aa && O(`null is not a valid ${this.name}`);
        if (this.ua) {
          var c = this.Ba();
          null !== b && b.push(this.ka, c);
          return c;
        }
        return 0;
      }
      a.W || O(`Cannot pass "${Nb(a)}" as a ${this.name}`);
      a.W.Y || O(`Cannot pass deleted object as a pointer of type ${this.name}`);
      !this.ta && a.W.Z.ta && O(`Cannot convert argument of type ${a.W.fa ? a.W.fa.name : a.W.Z.name} to parameter type ${this.name}`);
      c = Lb(a.W.Y, a.W.Z.X, this.X);
      if (this.ua) switch (void 0 === a.W.ca && O("Passing raw pointer to smart pointer is illegal"), this.Fb) {
        case 0:
          a.W.fa === this ? c = a.W.ca : O(`Cannot convert argument of type ${a.W.fa ? a.W.fa.name : a.W.Z.name} to parameter type ${this.name}`);
          break;
        case 1:
          c = a.W.ca;
          break;
        case 2:
          if (a.W.fa === this) c = a.W.ca;else {
            var d = a.clone();
            c = this.Bb(c, Q(function () {
              d["delete"]();
            }));
            null !== b && b.push(this.ka, c);
          }
          break;
        default:
          O("Unsupporting sharing policy");
      }
      return c;
    }
    function Pb(b, a) {
      if (null === a) return this.Aa && O(`null is not a valid ${this.name}`), 0;
      a.W || O(`Cannot pass "${Nb(a)}" as a ${this.name}`);
      a.W.Y || O(`Cannot pass deleted object as a pointer of type ${this.name}`);
      a.W.Z.ta && O(`Cannot convert argument of type ${a.W.Z.name} to parameter type ${this.name}`);
      return Lb(a.W.Y, a.W.Z.X, this.X);
    }
    function Qb(b, a, c, d) {
      this.name = b;
      this.X = a;
      this.Aa = c;
      this.ta = d;
      this.ua = !1;
      this.ka = this.Bb = this.Ba = this.Sa = this.Fb = this.Ab = void 0;
      void 0 !== a.$ ? this.toWireType = Ob : (this.toWireType = d ? Mb : Pb, this.ea = null);
    }
    function Rb(b, a, c) {
      l.hasOwnProperty(b) || wb("Replacing nonexistant public symbol");
      void 0 !== l[b].aa && void 0 !== c ? l[b].aa[c] = a : (l[b] = a, l[b].ma = c);
    }
    var Sb = (b, a) => {
      var c = [];
      return function () {
        c.length = 0;
        Object.assign(c, arguments);
        if (b.includes("j")) {
          var d = l["dynCall_" + b];
          d = c && c.length ? d.apply(null, [a].concat(c)) : d.call(null, a);
        } else d = Fa.get(a).apply(null, c);
        return d;
      };
    };
    function Z(b, a) {
      b = S(b);
      var c = b.includes("j") ? Sb(b, a) : Fa.get(a);
      "function" != typeof c && O(`unknown function pointer with signature ${b}: ${a}`);
      return c;
    }
    var Tb = void 0;
    function Ub(b, a) {
      function c(f) {
        e[f] || hb[f] || (Db[f] ? Db[f].forEach(c) : (d.push(f), e[f] = !0));
      }
      var d = [],
        e = {};
      a.forEach(c);
      throw new Tb(`${b}: ` + d.map(ib).join([", "]));
    }
    function Vb(b, a, c, d, e) {
      var f = a.length;
      2 > f && O("argTypes array size mismatch! Must at least get return value and 'this' types!");
      var h = null !== a[1] && null !== c,
        g = !1;
      for (c = 1; c < a.length; ++c) if (null !== a[c] && void 0 === a[c].ea) {
        g = !0;
        break;
      }
      var m = "void" !== a[0].name,
        k = f - 2,
        p = Array(k),
        r = [],
        t = [];
      return function () {
        arguments.length !== k && O(`function ${b} called with ${arguments.length} arguments, expected ${k} args!`);
        t.length = 0;
        r.length = h ? 2 : 1;
        r[0] = e;
        if (h) {
          var v = a[1].toWireType(t, this);
          r[1] = v;
        }
        for (var q = 0; q < k; ++q) p[q] = a[q + 2].toWireType(t, arguments[q]), r.push(p[q]);
        q = d.apply(null, r);
        if (g) Ab(t);else for (var F = h ? 1 : 2; F < a.length; F++) {
          var I = 1 === F ? v : p[F - 2];
          null !== a[F].ea && a[F].ea(I);
        }
        v = m ? a[0].fromWireType(q) : void 0;
        return v;
      };
    }
    function Wb(b, a) {
      for (var c = [], d = 0; d < b; d++) c.push(L[a + 4 * d >> 2]);
      return c;
    }
    function Xb(b, a, c) {
      b instanceof Object || O(`${c} with invalid "this": ${b}`);
      b instanceof a.X.constructor || O(`${c} incompatible with "this" of type ${b.constructor.name}`);
      b.W.Y || O(`cannot call emscripten binding method ${c} on deleted object`);
      return Lb(b.W.Y, b.W.Z.X, a.X);
    }
    function Yb(b) {
      b >= N.Ea && 0 === --N.get(b).Ta && N.Za(b);
    }
    function Zb(b, a, c) {
      switch (a) {
        case 0:
          return function (d) {
            return this.fromWireType((c ? ya : H)[d]);
          };
        case 1:
          return function (d) {
            return this.fromWireType((c ? za : Aa)[d >> 1]);
          };
        case 2:
          return function (d) {
            return this.fromWireType((c ? Ba : L)[d >> 2]);
          };
        default:
          throw new TypeError("Unknown integer type: " + b);
      }
    }
    function Nb(b) {
      if (null === b) return "null";
      var a = typeof b;
      return "object" === a || "array" === a || "function" === a ? b.toString() : "" + b;
    }
    function $b(b, a) {
      switch (a) {
        case 2:
          return function (c) {
            return this.fromWireType(Ca[c >> 2]);
          };
        case 3:
          return function (c) {
            return this.fromWireType(Da[c >> 3]);
          };
        default:
          throw new TypeError("Unknown float type: " + b);
      }
    }
    function ac(b, a, c) {
      switch (a) {
        case 0:
          return c ? function (d) {
            return ya[d];
          } : function (d) {
            return H[d];
          };
        case 1:
          return c ? function (d) {
            return za[d >> 1];
          } : function (d) {
            return Aa[d >> 1];
          };
        case 2:
          return c ? function (d) {
            return Ba[d >> 2];
          } : function (d) {
            return L[d >> 2];
          };
        default:
          throw new TypeError("Unknown integer type: " + b);
      }
    }
    var cc = "undefined" != typeof TextDecoder ? new TextDecoder("utf8") : void 0,
      dc = (b, a, c) => {
        var d = a + c;
        for (c = a; b[c] && !(c >= d);) ++c;
        if (16 < c - a && b.buffer && cc) return cc.decode(b.subarray(a, c));
        for (d = ""; a < c;) {
          var e = b[a++];
          if (e & 128) {
            var f = b[a++] & 63;
            if (192 == (e & 224)) d += String.fromCharCode((e & 31) << 6 | f);else {
              var h = b[a++] & 63;
              e = 224 == (e & 240) ? (e & 15) << 12 | f << 6 | h : (e & 7) << 18 | f << 12 | h << 6 | b[a++] & 63;
              65536 > e ? d += String.fromCharCode(e) : (e -= 65536, d += String.fromCharCode(55296 | e >> 10, 56320 | e & 1023));
            }
          } else d += String.fromCharCode(e);
        }
        return d;
      },
      ec = "undefined" != typeof TextDecoder ? new TextDecoder("utf-16le") : void 0,
      fc = (b, a) => {
        var c = b >> 1;
        for (var d = c + a / 2; !(c >= d) && Aa[c];) ++c;
        c <<= 1;
        if (32 < c - b && ec) return ec.decode(H.subarray(b, c));
        c = "";
        for (d = 0; !(d >= a / 2); ++d) {
          var e = za[b + 2 * d >> 1];
          if (0 == e) break;
          c += String.fromCharCode(e);
        }
        return c;
      },
      gc = (b, a, c) => {
        void 0 === c && (c = 2147483647);
        if (2 > c) return 0;
        c -= 2;
        var d = a;
        c = c < 2 * b.length ? c / 2 : b.length;
        for (var e = 0; e < c; ++e) za[a >> 1] = b.charCodeAt(e), a += 2;
        za[a >> 1] = 0;
        return a - d;
      },
      hc = b => 2 * b.length,
      ic = (b, a) => {
        for (var c = 0, d = ""; !(c >= a / 4);) {
          var e = Ba[b + 4 * c >> 2];
          if (0 == e) break;
          ++c;
          65536 <= e ? (e -= 65536, d += String.fromCharCode(55296 | e >> 10, 56320 | e & 1023)) : d += String.fromCharCode(e);
        }
        return d;
      },
      jc = (b, a, c) => {
        void 0 === c && (c = 2147483647);
        if (4 > c) return 0;
        var d = a;
        c = d + c - 4;
        for (var e = 0; e < b.length; ++e) {
          var f = b.charCodeAt(e);
          if (55296 <= f && 57343 >= f) {
            var h = b.charCodeAt(++e);
            f = 65536 + ((f & 1023) << 10) | h & 1023;
          }
          Ba[a >> 2] = f;
          a += 4;
          if (a + 4 > c) break;
        }
        Ba[a >> 2] = 0;
        return a - d;
      },
      kc = b => {
        for (var a = 0, c = 0; c < b.length; ++c) {
          var d = b.charCodeAt(c);
          55296 <= d && 57343 >= d && ++c;
          a += 4;
        }
        return a;
      },
      lc = {};
    function mc(b) {
      var a = lc[b];
      return void 0 === a ? S(b) : a;
    }
    var nc = [];
    function oc(b) {
      var a = nc.length;
      nc.push(b);
      return a;
    }
    function pc(b, a) {
      for (var c = Array(b), d = 0; d < b; ++d) c[d] = kb(L[a + 4 * d >> 2], "parameter " + d);
      return c;
    }
    var qc = [],
      rc = {},
      tc = () => {
        if (!sc) {
          var b = {
              USER: "web_user",
              LOGNAME: "web_user",
              PATH: "/",
              PWD: "/",
              HOME: "/home/web_user",
              LANG: ("object" == typeof navigator && navigator.languages && navigator.languages[0] || "C").replace("-", "_") + ".UTF-8",
              _: ka || "./this.program"
            },
            a;
          for (a in rc) void 0 === rc[a] ? delete b[a] : b[a] = rc[a];
          var c = [];
          for (a in b) c.push(`${a}=${b[a]}`);
          sc = c;
        }
        return sc;
      },
      sc,
      uc = [null, [], []];
    Object.assign(Ya.prototype, {
      get(b) {
        return this.ja[b];
      },
      has(b) {
        return void 0 !== this.ja[b];
      },
      Ya(b) {
        var a = this.Oa.pop() || this.ja.length;
        this.ja[a] = b;
        return a;
      },
      Za(b) {
        this.ja[b] = void 0;
        this.Oa.push(b);
      }
    });
    Za = l.BindingError = class extends Error {
      constructor(b) {
        super(b);
        this.name = "BindingError";
      }
    };
    N.ja.push({
      value: void 0
    }, {
      value: null
    }, {
      value: !0
    }, {
      value: !1
    });
    N.Ea = N.ja.length;
    l.count_emval_handles = function () {
      for (var b = 0, a = N.Ea; a < N.ja.length; ++a) void 0 !== N.ja[a] && ++b;
      return b;
    };
    bb = l.PureVirtualError = ab("PureVirtualError");
    for (var vc = Array(256), wc = 0; 256 > wc; ++wc) vc[wc] = String.fromCharCode(wc);
    cb = vc;
    l.getInheritedInstanceCount = function () {
      return Object.keys(U).length;
    };
    l.getLiveInheritedInstances = function () {
      var b = [],
        a;
      for (a in U) U.hasOwnProperty(a) && b.push(U[a]);
      return b;
    };
    l.flushPendingDeletes = eb;
    l.setDelayFunction = function (b) {
      fb = b;
      db.length && fb && fb(eb);
    };
    vb = l.InternalError = class extends Error {
      constructor(b) {
        super(b);
        this.name = "InternalError";
      }
    };
    Hb.prototype.isAliasOf = function (b) {
      if (!(this instanceof Hb && b instanceof Hb)) return !1;
      var a = this.W.Z.X,
        c = this.W.Y,
        d = b.W.Z.X;
      for (b = b.W.Y; a.$;) c = a.ra(c), a = a.$;
      for (; d.$;) b = d.ra(b), d = d.$;
      return a === d && c === b;
    };
    Hb.prototype.clone = function () {
      this.W.Y || Gb(this);
      if (this.W.pa) return this.W.count.value += 1, this;
      var b = yb,
        a = Object,
        c = a.create,
        d = Object.getPrototypeOf(this),
        e = this.W;
      b = b(c.call(a, d, {
        W: {
          value: {
            count: e.count,
            na: e.na,
            pa: e.pa,
            Y: e.Y,
            Z: e.Z,
            ca: e.ca,
            fa: e.fa
          }
        }
      }));
      b.W.count.value += 1;
      b.W.na = !1;
      return b;
    };
    Hb.prototype["delete"] = function () {
      this.W.Y || Gb(this);
      this.W.na && !this.W.pa && O("Object already scheduled for deletion");
      lb(this);
      nb(this.W);
      this.W.pa || (this.W.ca = void 0, this.W.Y = void 0);
    };
    Hb.prototype.isDeleted = function () {
      return !this.W.Y;
    };
    Hb.prototype.deleteLater = function () {
      this.W.Y || Gb(this);
      this.W.na && !this.W.pa && O("Object already scheduled for deletion");
      db.push(this);
      1 === db.length && fb && fb(eb);
      this.W.na = !0;
      return this;
    };
    Qb.prototype.pb = function (b) {
      this.Sa && (b = this.Sa(b));
      return b;
    };
    Qb.prototype.Ka = function (b) {
      this.ka && this.ka(b);
    };
    Qb.prototype.argPackAdvance = 8;
    Qb.prototype.readValueFromPointer = Bb;
    Qb.prototype.deleteObject = function (b) {
      if (null !== b) b["delete"]();
    };
    Qb.prototype.fromWireType = function (b) {
      function a() {
        return this.ua ? xb(this.X.ga, {
          Z: this.Ab,
          Y: c,
          fa: this,
          ca: b
        }) : xb(this.X.ga, {
          Z: this,
          Y: b
        });
      }
      var c = this.pb(b);
      if (!c) return this.Ka(b), null;
      var d = ub(this.X, c);
      if (void 0 !== d) {
        if (0 === d.W.count.value) return d.W.Y = c, d.W.ca = b, d.clone();
        d = d.clone();
        this.Ka(b);
        return d;
      }
      d = this.X.ob(c);
      d = tb[d];
      if (!d) return a.call(this);
      d = this.ta ? d.fb : d.pointerType;
      var e = sb(c, this.X, d.X);
      return null === e ? a.call(this) : this.ua ? xb(d.X.ga, {
        Z: d,
        Y: e,
        fa: this,
        ca: b
      }) : xb(d.X.ga, {
        Z: d,
        Y: e
      });
    };
    Tb = l.UnboundTypeError = ab("UnboundTypeError");
    var yc = {
      I: function (b, a, c) {
        b = S(b);
        a = kb(a, "wrapper");
        c = $a(c);
        var d = [].slice,
          e = a.X,
          f = e.ga,
          h = e.$.ga,
          g = e.$.constructor;
        b = Xa(b, function () {
          e.$.Ra.forEach(function (k) {
            if (this[k] === h[k]) throw new bb(`Pure virtual function ${k} must be implemented in JavaScript`);
          }.bind(this));
          Object.defineProperty(this, "__parent", {
            value: f
          });
          this.__construct.apply(this, d.call(arguments));
        });
        f.__construct = function () {
          this === f && O("Pass correct 'this' to __construct");
          var k = g.implement.apply(void 0, [this].concat(d.call(arguments)));
          lb(k);
          var p = k.W;
          k.notifyOnDestruction();
          p.pa = !0;
          Object.defineProperties(this, {
            W: {
              value: p
            }
          });
          yb(this);
          k = p.Y;
          k = gb(e, k);
          U.hasOwnProperty(k) ? O(`Tried to register registered instance: ${k}`) : U[k] = this;
        };
        f.__destruct = function () {
          this === f && O("Pass correct 'this' to __destruct");
          lb(this);
          var k = this.W.Y;
          k = gb(e, k);
          U.hasOwnProperty(k) ? delete U[k] : O(`Tried to unregister unregistered instance: ${k}`);
        };
        b.prototype = Object.create(f);
        for (var m in c) b.prototype[m] = c[m];
        return Q(b);
      },
      L: function (b) {
        var a = zb[b];
        delete zb[b];
        var c = a.Ba,
          d = a.ka,
          e = a.Na,
          f = e.map(h => h.sb).concat(e.map(h => h.Db));
        X([b], f, h => {
          var g = {};
          e.forEach((m, k) => {
            var p = h[k],
              r = m.qb,
              t = m.rb,
              v = h[k + e.length],
              q = m.Cb,
              F = m.Eb;
            g[m.mb] = {
              read: I => p.fromWireType(r(t, I)),
              write: (I, w) => {
                var y = [];
                q(F, I, v.toWireType(y, w));
                Ab(y);
              }
            };
          });
          return [{
            name: a.name,
            fromWireType: function (m) {
              var k = {},
                p;
              for (p in g) k[p] = g[p].read(m);
              d(m);
              return k;
            },
            toWireType: function (m, k) {
              for (var p in g) if (!(p in k)) throw new TypeError(`Missing field: "${p}"`);
              var r = c();
              for (p in g) g[p].write(r, k[p]);
              null !== m && m.push(d, r);
              return r;
            },
            argPackAdvance: 8,
            readValueFromPointer: Bb,
            ea: d
          }];
        });
      },
      z: function () {},
      G: function (b, a, c, d, e) {
        var f = Eb(c);
        a = S(a);
        Y(b, {
          name: a,
          fromWireType: function (h) {
            return !!h;
          },
          toWireType: function (h, g) {
            return g ? d : e;
          },
          argPackAdvance: 8,
          readValueFromPointer: function (h) {
            if (1 === c) var g = ya;else if (2 === c) g = za;else if (4 === c) g = Ba;else throw new TypeError("Unknown boolean type size: " + a);
            return this.fromWireType(g[h >> f]);
          },
          ea: null
        });
      },
      e: function (b, a, c, d, e, f, h, g, m, k, p, r, t) {
        p = S(p);
        f = Z(e, f);
        g && (g = Z(h, g));
        k && (k = Z(m, k));
        t = Z(r, t);
        var v = Va(p);
        Jb(v, function () {
          Ub(`Cannot construct ${p} due to unbound types`, [d]);
        });
        X([b, a, c], d ? [d] : [], function (q) {
          q = q[0];
          if (d) {
            var F = q.X;
            var I = F.ga;
          } else I = Hb.prototype;
          q = Xa(v, function () {
            if (Object.getPrototypeOf(this) !== w) throw new Za("Use 'new' to construct " + p);
            if (void 0 === y.la) throw new Za(p + " has no accessible constructor");
            var P = y.la[arguments.length];
            if (void 0 === P) throw new Za(`Tried to invoke ctor of ${p} with invalid number of parameters (${arguments.length}) - expected (${Object.keys(y.la).toString()}) parameters instead!`);
            return P.apply(this, arguments);
          });
          var w = Object.create(I, {
            constructor: {
              value: q
            }
          });
          q.prototype = w;
          var y = new Kb(p, q, w, t, F, f, g, k);
          y.$ && (void 0 === y.$.sa && (y.$.sa = []), y.$.sa.push(y));
          F = new Qb(p, y, !0, !1);
          I = new Qb(p + "*", y, !1, !1);
          var J = new Qb(p + " const*", y, !1, !0);
          tb[b] = {
            pointerType: I,
            fb: J
          };
          Rb(v, q);
          return [F, I, J];
        });
      },
      k: function (b, a, c, d, e, f, h) {
        var g = Wb(c, d);
        a = S(a);
        f = Z(e, f);
        X([], [b], function (m) {
          function k() {
            Ub(`Cannot call ${p} due to unbound types`, g);
          }
          m = m[0];
          var p = `${m.name}.${a}`;
          a.startsWith("@@") && (a = Symbol[a.substring(2)]);
          var r = m.X.constructor;
          void 0 === r[a] ? (k.ma = c - 1, r[a] = k) : (Ib(r, a, p), r[a].aa[c - 1] = k);
          X([], g, function (t) {
            t = Vb(p, [t[0], null].concat(t.slice(1)), null, f, h);
            void 0 === r[a].aa ? (t.ma = c - 1, r[a] = t) : r[a].aa[c - 1] = t;
            if (m.X.sa) for (const v of m.X.sa) v.constructor.hasOwnProperty(a) || (v.constructor[a] = t);
            return [];
          });
          return [];
        });
      },
      s: function (b, a, c, d, e, f, h, g) {
        a = S(a);
        f = Z(e, f);
        X([], [b], function (m) {
          m = m[0];
          var k = `${m.name}.${a}`,
            p = {
              get() {
                Ub(`Cannot access ${k} due to unbound types`, [c]);
              },
              enumerable: !0,
              configurable: !0
            };
          p.set = g ? () => {
            Ub(`Cannot access ${k} due to unbound types`, [c]);
          } : () => {
            O(`${k} is a read-only property`);
          };
          Object.defineProperty(m.X.constructor, a, p);
          X([], [c], function (r) {
            r = r[0];
            var t = {
              get() {
                return r.fromWireType(f(d));
              },
              enumerable: !0
            };
            g && (g = Z(h, g), t.set = v => {
              var q = [];
              g(d, r.toWireType(q, v));
              Ab(q);
            });
            Object.defineProperty(m.X.constructor, a, t);
            return [];
          });
          return [];
        });
      },
      o: function (b, a, c, d, e, f) {
        var h = Wb(a, c);
        e = Z(d, e);
        X([], [b], function (g) {
          g = g[0];
          var m = `constructor ${g.name}`;
          void 0 === g.X.la && (g.X.la = []);
          if (void 0 !== g.X.la[a - 1]) throw new Za(`Cannot register multiple constructors with identical number of parameters (${a - 1}) for class '${g.name}'! Overload resolution is currently only performed using the parameter count, not actual type info!`);
          g.X.la[a - 1] = () => {
            Ub(`Cannot construct ${g.name} due to unbound types`, h);
          };
          X([], h, function (k) {
            k.splice(1, 0, null);
            g.X.la[a - 1] = Vb(m, k, null, e, f);
            return [];
          });
          return [];
        });
      },
      a: function (b, a, c, d, e, f, h, g) {
        var m = Wb(c, d);
        a = S(a);
        f = Z(e, f);
        X([], [b], function (k) {
          function p() {
            Ub(`Cannot call ${r} due to unbound types`, m);
          }
          k = k[0];
          var r = `${k.name}.${a}`;
          a.startsWith("@@") && (a = Symbol[a.substring(2)]);
          g && k.X.Ra.push(a);
          var t = k.X.ga,
            v = t[a];
          void 0 === v || void 0 === v.aa && v.className !== k.name && v.ma === c - 2 ? (p.ma = c - 2, p.className = k.name, t[a] = p) : (Ib(t, a, r), t[a].aa[c - 2] = p);
          X([], m, function (q) {
            q = Vb(r, q, k, f, h);
            void 0 === t[a].aa ? (q.ma = c - 2, t[a] = q) : t[a].aa[c - 2] = q;
            return [];
          });
          return [];
        });
      },
      d: function (b, a, c, d, e, f, h, g, m, k) {
        a = S(a);
        e = Z(d, e);
        X([], [b], function (p) {
          p = p[0];
          var r = `${p.name}.${a}`,
            t = {
              get() {
                Ub(`Cannot access ${r} due to unbound types`, [c, h]);
              },
              enumerable: !0,
              configurable: !0
            };
          t.set = m ? () => {
            Ub(`Cannot access ${r} due to unbound types`, [c, h]);
          } : () => {
            O(r + " is a read-only property");
          };
          Object.defineProperty(p.X.ga, a, t);
          X([], m ? [c, h] : [c], function (v) {
            var q = v[0],
              F = {
                get() {
                  var w = Xb(this, p, r + " getter");
                  return q.fromWireType(e(f, w));
                },
                enumerable: !0
              };
            if (m) {
              m = Z(g, m);
              var I = v[1];
              F.set = function (w) {
                var y = Xb(this, p, r + " setter"),
                  J = [];
                m(k, y, I.toWireType(J, w));
                Ab(J);
              };
            }
            Object.defineProperty(p.X.ga, a, F);
            return [];
          });
          return [];
        });
      },
      F: function (b, a) {
        a = S(a);
        Y(b, {
          name: a,
          fromWireType: function (c) {
            var d = $a(c);
            Yb(c);
            return d;
          },
          toWireType: function (c, d) {
            return Q(d);
          },
          argPackAdvance: 8,
          readValueFromPointer: Bb,
          ea: null
        });
      },
      l: function (b, a, c, d) {
        function e() {}
        c = Eb(c);
        a = S(a);
        e.values = {};
        Y(b, {
          name: a,
          constructor: e,
          fromWireType: function (f) {
            return this.constructor.values[f];
          },
          toWireType: function (f, h) {
            return h.value;
          },
          argPackAdvance: 8,
          readValueFromPointer: Zb(a, c, d),
          ea: null
        });
        Jb(a, e);
      },
      c: function (b, a, c) {
        var d = kb(b, "enum");
        a = S(a);
        b = d.constructor;
        d = Object.create(d.constructor.prototype, {
          value: {
            value: c
          },
          constructor: {
            value: Xa(`${d.name}_${a}`, function () {})
          }
        });
        b.values[c] = d;
        b[a] = d;
      },
      u: function (b, a, c) {
        c = Eb(c);
        a = S(a);
        Y(b, {
          name: a,
          fromWireType: function (d) {
            return d;
          },
          toWireType: function (d, e) {
            return e;
          },
          argPackAdvance: 8,
          readValueFromPointer: $b(a, c),
          ea: null
        });
      },
      q: function (b, a, c, d, e, f) {
        var h = Wb(a, c);
        b = S(b);
        e = Z(d, e);
        Jb(b, function () {
          Ub(`Cannot call ${b} due to unbound types`, h);
        }, a - 1);
        X([], h, function (g) {
          Rb(b, Vb(b, [g[0], null].concat(g.slice(1)), null, e, f), a - 1);
          return [];
        });
      },
      j: function (b, a, c, d, e) {
        a = S(a);
        -1 === e && (e = 4294967295);
        e = Eb(c);
        var f = g => g;
        if (0 === d) {
          var h = 32 - 8 * c;
          f = g => g << h >>> h;
        }
        c = a.includes("unsigned") ? function (g, m) {
          return m >>> 0;
        } : function (g, m) {
          return m;
        };
        Y(b, {
          name: a,
          fromWireType: f,
          toWireType: c,
          argPackAdvance: 8,
          readValueFromPointer: ac(a, e, 0 !== d),
          ea: null
        });
      },
      g: function (b, a, c) {
        function d(f) {
          f >>= 2;
          var h = L;
          return new e(h.buffer, h[f + 1], h[f]);
        }
        var e = [Int8Array, Uint8Array, Int16Array, Uint16Array, Int32Array, Uint32Array, Float32Array, Float64Array][a];
        c = S(c);
        Y(b, {
          name: c,
          fromWireType: d,
          argPackAdvance: 8,
          readValueFromPointer: d
        }, {
          tb: !0
        });
      },
      v: function (b, a) {
        a = S(a);
        var c = "std::string" === a;
        Y(b, {
          name: a,
          fromWireType: function (d) {
            var e = L[d >> 2],
              f = d + 4;
            if (c) for (var h = f, g = 0; g <= e; ++g) {
              var m = f + g;
              if (g == e || 0 == H[m]) {
                h = h ? dc(H, h, m - h) : "";
                if (void 0 === k) var k = h;else k += String.fromCharCode(0), k += h;
                h = m + 1;
              }
            } else {
              k = Array(e);
              for (g = 0; g < e; ++g) k[g] = String.fromCharCode(H[f + g]);
              k = k.join("");
            }
            V(d);
            return k;
          },
          toWireType: function (d, e) {
            e instanceof ArrayBuffer && (e = new Uint8Array(e));
            var f,
              h = "string" == typeof e;
            h || e instanceof Uint8Array || e instanceof Uint8ClampedArray || e instanceof Int8Array || O("Cannot pass non-string to std::string");
            var g;
            if (c && h) for (f = g = 0; f < e.length; ++f) {
              var m = e.charCodeAt(f);
              127 >= m ? g++ : 2047 >= m ? g += 2 : 55296 <= m && 57343 >= m ? (g += 4, ++f) : g += 3;
            } else g = e.length;
            f = g;
            g = xc(4 + f + 1);
            m = g + 4;
            L[g >> 2] = f;
            if (c && h) {
              if (h = m, m = f + 1, f = H, 0 < m) {
                m = h + m - 1;
                for (var k = 0; k < e.length; ++k) {
                  var p = e.charCodeAt(k);
                  if (55296 <= p && 57343 >= p) {
                    var r = e.charCodeAt(++k);
                    p = 65536 + ((p & 1023) << 10) | r & 1023;
                  }
                  if (127 >= p) {
                    if (h >= m) break;
                    f[h++] = p;
                  } else {
                    if (2047 >= p) {
                      if (h + 1 >= m) break;
                      f[h++] = 192 | p >> 6;
                    } else {
                      if (65535 >= p) {
                        if (h + 2 >= m) break;
                        f[h++] = 224 | p >> 12;
                      } else {
                        if (h + 3 >= m) break;
                        f[h++] = 240 | p >> 18;
                        f[h++] = 128 | p >> 12 & 63;
                      }
                      f[h++] = 128 | p >> 6 & 63;
                    }
                    f[h++] = 128 | p & 63;
                  }
                }
                f[h] = 0;
              }
            } else if (h) for (h = 0; h < f; ++h) k = e.charCodeAt(h), 255 < k && (V(m), O("String has UTF-16 code units that do not fit in 8 bits")), H[m + h] = k;else for (h = 0; h < f; ++h) H[m + h] = e[h];
            null !== d && d.push(V, g);
            return g;
          },
          argPackAdvance: 8,
          readValueFromPointer: Bb,
          ea: function (d) {
            V(d);
          }
        });
      },
      t: function (b, a, c) {
        c = S(c);
        if (2 === a) {
          var d = fc;
          var e = gc;
          var f = hc;
          var h = () => Aa;
          var g = 1;
        } else 4 === a && (d = ic, e = jc, f = kc, h = () => L, g = 2);
        Y(b, {
          name: c,
          fromWireType: function (m) {
            for (var k = L[m >> 2], p = h(), r, t = m + 4, v = 0; v <= k; ++v) {
              var q = m + 4 + v * a;
              if (v == k || 0 == p[q >> g]) t = d(t, q - t), void 0 === r ? r = t : (r += String.fromCharCode(0), r += t), t = q + a;
            }
            V(m);
            return r;
          },
          toWireType: function (m, k) {
            "string" != typeof k && O(`Cannot pass non-string to C++ string type ${c}`);
            var p = f(k),
              r = xc(4 + p + a);
            L[r >> 2] = p >> g;
            e(k, r + 4, p + a);
            null !== m && m.push(V, r);
            return r;
          },
          argPackAdvance: 8,
          readValueFromPointer: Bb,
          ea: function (m) {
            V(m);
          }
        });
      },
      N: function (b, a, c, d, e, f) {
        zb[b] = {
          name: S(a),
          Ba: Z(c, d),
          ka: Z(e, f),
          Na: []
        };
      },
      M: function (b, a, c, d, e, f, h, g, m, k) {
        zb[b].Na.push({
          mb: S(a),
          sb: c,
          qb: Z(d, e),
          rb: f,
          Db: h,
          Cb: Z(g, m),
          Eb: k
        });
      },
      H: function (b, a) {
        a = S(a);
        Y(b, {
          vb: !0,
          name: a,
          argPackAdvance: 0,
          fromWireType: function () {},
          toWireType: function () {}
        });
      },
      r: function (b, a, c) {
        b = $a(b);
        a = kb(a, "emval::as");
        var d = [],
          e = Q(d);
        L[c >> 2] = e;
        return a.toWireType(d, b);
      },
      w: function (b, a, c, d, e) {
        b = nc[b];
        a = $a(a);
        c = mc(c);
        var f = [];
        L[d >> 2] = Q(f);
        return b(a, c, f, e);
      },
      i: function (b, a, c, d) {
        b = nc[b];
        a = $a(a);
        c = mc(c);
        b(a, c, null, d);
      },
      f: Yb,
      h: function (b, a) {
        var c = pc(b, a),
          d = c[0];
        a = d.name + "_$" + c.slice(1).map(function (h) {
          return h.name;
        }).join("_") + "$";
        var e = qc[a];
        if (void 0 !== e) return e;
        var f = Array(b - 1);
        e = oc((h, g, m, k) => {
          for (var p = 0, r = 0; r < b - 1; ++r) f[r] = c[r + 1].readValueFromPointer(k + p), p += c[r + 1].argPackAdvance;
          h = h[g].apply(h, f);
          for (r = 0; r < b - 1; ++r) c[r + 1].hb && c[r + 1].hb(f[r]);
          if (!d.vb) return d.toWireType(m, h);
        });
        return qc[a] = e;
      },
      p: function (b) {
        b = mc(b);
        return Q(l[b]);
      },
      K: function (b, a) {
        b = $a(b);
        a = $a(a);
        return Q(b[a]);
      },
      m: function (b) {
        4 < b && (N.get(b).Ta += 1);
      },
      J: function (b) {
        return Q(mc(b));
      },
      n: function (b) {
        var a = $a(b);
        Ab(a);
        Yb(b);
      },
      x: function (b, a) {
        b = kb(b, "_emval_take_value");
        b = b.readValueFromPointer(a);
        return Q(b);
      },
      b: () => {
        va("");
      },
      A: b => {
        var a = H.length;
        b >>>= 0;
        if (2147483648 < b) return !1;
        for (var c = 1; 4 >= c; c *= 2) {
          var d = a * (1 + .2 / c);
          d = Math.min(d, b + 100663296);
          var e = Math;
          d = Math.max(b, d);
          a: {
            e = e.min.call(e, 2147483648, d + (65536 - d % 65536) % 65536) - wa.buffer.byteLength + 65535 >>> 16;
            try {
              wa.grow(e);
              Ea();
              var f = 1;
              break a;
            } catch (h) {}
            f = void 0;
          }
          if (f) return !0;
        }
        return !1;
      },
      B: (b, a) => {
        var c = 0;
        tc().forEach(function (d, e) {
          var f = a + c;
          e = L[b + 4 * e >> 2] = f;
          for (f = 0; f < d.length; ++f) ya[e++ >> 0] = d.charCodeAt(f);
          ya[e >> 0] = 0;
          c += d.length + 1;
        });
        return 0;
      },
      C: (b, a) => {
        var c = tc();
        L[b >> 2] = c.length;
        var d = 0;
        c.forEach(function (e) {
          d += e.length + 1;
        });
        L[a >> 2] = d;
        return 0;
      },
      D: () => 52,
      y: function () {
        return 70;
      },
      E: (b, a, c, d) => {
        for (var e = 0, f = 0; f < c; f++) {
          var h = L[a >> 2],
            g = L[a + 4 >> 2];
          a += 8;
          for (var m = 0; m < g; m++) {
            var k = H[h + m],
              p = uc[b];
            0 === k || 10 === k ? ((1 === b ? qa : ra)(dc(p, 0)), p.length = 0) : p.push(k);
          }
          e += g;
        }
        L[d >> 2] = e;
        return 0;
      }
    };
    (function () {
      function b(c) {
        c = c.exports;
        l.asm = c;
        wa = l.asm.O;
        Ea();
        Fa = l.asm.U;
        Ha.unshift(l.asm.P);
        Ka--;
        l.monitorRunDependencies && l.monitorRunDependencies(Ka);
        if (0 == Ka && (null !== La && (clearInterval(La), La = null), Ma)) {
          var d = Ma;
          Ma = null;
          d();
        }
        return c;
      }
      var a = {
        a: yc
      };
      Ka++;
      l.monitorRunDependencies && l.monitorRunDependencies(Ka);
      if (l.instantiateWasm) try {
        return l.instantiateWasm(a, b);
      } catch (c) {
        ra("Module.instantiateWasm callback failed with error: " + c), ba(c);
      }
      Ta(a, function (c) {
        b(c.instance);
      }).catch(ba);
      return {};
    })();
    function V() {
      return (V = l.asm.Q).apply(null, arguments);
    }
    function xc() {
      return (xc = l.asm.R).apply(null, arguments);
    }
    function jb() {
      return (jb = l.asm.S).apply(null, arguments);
    }
    l.__embind_initialize_bindings = function () {
      return (l.__embind_initialize_bindings = l.asm.T).apply(null, arguments);
    };
    l.dynCall_jiji = function () {
      return (l.dynCall_jiji = l.asm.V).apply(null, arguments);
    };
    var zc;
    Ma = function Ac() {
      zc || Bc();
      zc || (Ma = Ac);
    };
    function Bc() {
      function b() {
        if (!zc && (zc = !0, l.calledRun = !0, !xa)) {
          Ua(Ha);
          aa(l);
          if (l.onRuntimeInitialized) l.onRuntimeInitialized();
          if (l.postRun) for ("function" == typeof l.postRun && (l.postRun = [l.postRun]); l.postRun.length;) {
            var a = l.postRun.shift();
            Ia.unshift(a);
          }
          Ua(Ia);
        }
      }
      if (!(0 < Ka)) {
        if (l.preRun) for ("function" == typeof l.preRun && (l.preRun = [l.preRun]); l.preRun.length;) Ja();
        Ua(Ga);
        0 < Ka || (l.setStatus ? (l.setStatus("Running..."), setTimeout(function () {
          setTimeout(function () {
            l.setStatus("");
          }, 1);
          b();
        }, 1)) : b());
      }
    }
    if (l.preInit) for ("function" == typeof l.preInit && (l.preInit = [l.preInit]); 0 < l.preInit.length;) l.preInit.pop()();
    Bc();
    return moduleArg.ready;
  };
})();
export default Rive;