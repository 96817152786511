<section id="edit-password">

  <div class="flex flex-col w-[642px]">

    <a routerLink="../../../">
      <mat-icon class="material-symbols-outlined text-text-primary w-8 h-8 text-[32px]">arrow_circle_left</mat-icon></a>
    <h1 class="text-xl font-semibold text-text-primary mt-1">Update Password</h1>
    <h3 class="text-lg font-medium text-text-secondary mt-1">Secure Your Account with a New Password
    </h3>

    <form class="flex flex-col gap-y-8 mt-6" [formGroup]="passwordForm" (keyup.enter)="update()">
      <mat-form-field appearance="outline" class="h-14 w-full">
        <mat-label>Old Password</mat-label>
        <input matInput formControlName="oldPassword" [type]="showOldPassword ? 'text' : 'password'" autocomplete="off">
        <mat-hint *ngIf="incorrectPassword" class="error text-xs">
          Incorrect Password
        </mat-hint>
        <mat-hint
          *ngIf="passwordForm.controls['oldPassword'].errors && (passwordForm.controls['oldPassword'].dirty || passwordForm.controls['oldPassword'].touched) && passwordForm.controls['oldPassword'].errors['required']"
          class="error text-xs">
          Required
        </mat-hint>
        <mat-icon matSuffix class="material-symbols-outlined font-light" (click)="showOldPassword=true"
          *ngIf="!showOldPassword">visibility</mat-icon>
        <mat-icon matSuffix class="material-symbols-outlined font-light" (click)="showOldPassword=false"
          *ngIf="showOldPassword">visibility_off</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="h-14 w-full">
        <mat-label>New Password</mat-label>
        <input matInput formControlName="newPassword" [type]="showPassword ? 'text' : 'password'" autocomplete="off">
        <mat-hint
          *ngIf="passwordForm.controls['newPassword'].errors && (passwordForm.controls['newPassword'].dirty || passwordForm.controls['newPassword'].touched) && passwordForm.controls['newPassword'].errors['required']"
          class="error text-xs">
          Required
        </mat-hint>
        <mat-icon matSuffix class="material-symbols-outlined font-light" (click)="showPassword=true"
          *ngIf="!showPassword">visibility</mat-icon>
        <mat-icon matSuffix class="material-symbols-outlined font-light" (click)="showPassword=false"
          *ngIf="showPassword">visibility_off</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="h-14 w-full">
        <mat-label>Confirm Password</mat-label>
        <input matInput formControlName="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'"
          onCopy="return false" onCut="return false" onDrag="return false" onpaste="return false" ondrop="return false"
          autocomplete="off">
        <mat-hint
          *ngIf="!validations['samePassword'].bool && passwordForm.controls['confirmPassword'].touched && !passwordForm.controls['confirmPassword'].errors"
          class="text-xs error">
          {{validations['samePassword'].message}}
        </mat-hint>
        <mat-hint
          *ngIf="passwordForm.controls['confirmPassword'].errors && (passwordForm.controls['confirmPassword'].dirty || passwordForm.controls['confirmPassword'].touched) && passwordForm.controls['confirmPassword'].errors['required']"
          class="error text-xs">
          Required
        </mat-hint>
        <mat-icon matSuffix class="material-symbols-outlined font-light" (click)="showConfirmPassword=true"
          *ngIf="!showConfirmPassword">visibility</mat-icon>
        <mat-icon matSuffix class="material-symbols-outlined font-light" (click)="showConfirmPassword=false"
          *ngIf="showConfirmPassword">visibility_off</mat-icon>
      </mat-form-field>
    </form>

    <div class="flex flex-col mt-8">
      <ng-container *ngFor="let key of objectKeys(validations); first as isFirst">
        <div class="flex flex-row items-center font-medium text-sm text-text-primary mt-3" *ngIf="!isFirst">
          <mat-icon class="material-symbols-outlined h-5 w-5 text-[20px]"
            [ngClass]="validations[key].bool ? 'primary' : 'on-surface-variant'">check_circle</mat-icon>
          <p class="ml-2">{{validations[key].message}}</p>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="!saveFormLoading; else loading">
      <button mat-flat-button class="text-base font-medium rounded w-36 h-12 mt-10" [disabled]="!checkValidations()"
        [ngClass]="checkValidations() ? '' : 'bg-blue-grey-300'" (click)="update()">Update</button>
    </ng-container>

  </div>
  <ng-template #loading>
    <button-loader [width]="'144'" class="mt-10"></button-loader>
    <!-- <button class="text-base font-medium text-white rounded bg-blue-900 w-36 h-12 flex justify-center mt-10">
      <canvas riv="loading_bars_white" width="90" height="45">
        <riv-animation name="shrink" play></riv-animation>
      </canvas>
    </button> -->
  </ng-template>

</section>