import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User, UserSelectors, AppsService, ConnectedAppsActions } from 'ngx-com-quicko-myaccount';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ButtonLoaderComponent } from 'projects/web-myaccount/src/app/common/components/button-loader/button-loader.component';
import { NgIf } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-delete-app',
  standalone: true,
  imports: [MatIconModule, MatDialogModule, MatButtonModule, ButtonLoaderComponent, NgIf],
  templateUrl: './delete-app.component.html',
  styleUrls: ['./delete-app.component.css']
})
export class DeleteAppComponent implements OnInit {

  userDetails: Observable<User> = this.store.select(UserSelectors.USER);
  selectedOption: any;
  saveFormLoading: boolean = false;
  deleteForm: boolean = false;
  appKey: string

  constructor(
    private actions$: Actions,
    private store: Store,
    public dialogRef: MatDialogRef<DeleteAppComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { appKey: string }
  ) {
    this.appKey = data.appKey;
  }

  ngOnInit() { }

  proceed() {
    this.saveFormLoading = true;
    this.store.dispatch(ConnectedAppsActions.REMOVE_APP({ appKey: this.appKey }));
    this.actions$.pipe(ofType(ConnectedAppsActions.REMOVE_APP_SUCCESS)).subscribe(() => {
      this.router.navigate([`/settings/apps`]);
      this.dialogRef.close();
      this.saveFormLoading = false;
    });

  }

}
