import { prop } from "@rxweb/reactive-form-validators";


export class PaginatedList<T> {

    @prop()
    "@entity"?: string = 'org.quicko.paginated_list';

    items?: Array<T>;

    last_evaluated_key?: string;

    getItems(): Array<T> | undefined {
        return this.items;
    }

    setItems(value: Array<T>): void {
        this.items = value;
    }

    getLastEvaluatedKey(): string | undefined {
        return this.last_evaluated_key;
    }
    setLastEvaluatedKey(value?: string): void {
        this.last_evaluated_key = value;
    }
}

