import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, switchMap } from 'rxjs/operators';
import { EntitlementsService } from "../../../services/entitlements.service";
import { UserActions } from "./user.actions";
import { SnackbarService } from "../../../services";

@Injectable()
export class UserEffects {

    constructor(
        private actions$: Actions,
        private entitlementsService: EntitlementsService,
        private snackbarService: SnackbarService,
        private store: Store
    ) { }

    getUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.GET_USER),
            switchMap((() => this.entitlementsService.getUserDetail().pipe(
                map(response => UserActions.GET_USER_SUCCESS({ user: response })),
                catchError((err, caught) => {
                    this.snackbarService.openSnackBar('Failed to get user', undefined);
                    this.store.dispatch(
                        UserActions.GET_USER_ERROR({ err: err.message }),
                    )
                    throw err;
                })
            )))
        ));

    postUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.POST_USER),
            switchMap((action) => this.entitlementsService.postUserDetail(action.user).pipe(
                map((response) => UserActions.POST_USER_SUCCESS({ user: response })),
                catchError((err, caught) => {
                    this.snackbarService.openSnackBar('Failed to update user', undefined);
                    this.store.dispatch(
                        UserActions.POST_USER_ERROR({ err: err.message }),
                    )
                    throw err;
                })
            ))
        )
    );
}