import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { App, DateUtil } from 'ngx-com-quicko-myaccount';

@Component({
  selector: 'app-app-card',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.css']
})
export class AppCardComponent implements OnInit {
  date: string | undefined

  constructor(private router: Router) { }

  @Input() app?: any;

  ngOnInit() {
    this.date = DateUtil.printDate(DateUtil.fromMillis(this.app.created_at))
  }

  goToDetailPage() {
    this.router.navigate([`/settings/apps/${this.app?.app_key}`]);
  }

}
