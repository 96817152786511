import { toDate, format, formatInTimeZone, fromZonedTime, toZonedTime } from 'date-fns-tz';
import { parseISO, parse } from 'date-fns';
import { IllegalArgumentException } from '../exceptions/IllegalArgumentException';

export class DateUtil {


    static DATE_PATTERN = "dd/MM/yyyy";

    static DATE_NUMBER_PATTERN = "ddMMyyyy";
    static DATETIME_NUMBER_PATTERN = "ddMMyyyyhhmmss";

    static DATETIME_PATTERN = "dd/MM/yyyy hh:mm:ss a";

    static TIMEZONE = "Asia/Kolkata";

    static nowInIndia() {
        const date = new Date();
        const timezonedDate = toDate(date, { timeZone: this.TIMEZONE })
        return timezonedDate;
    }

    static printDateNumberFormat(date: Date) {
        return format(date, this.DATE_NUMBER_PATTERN, { timeZone: this.TIMEZONE });

    }
    static printDateTimeNumberFormat(date: Date) {
        return format(date, this.DATETIME_NUMBER_PATTERN, { timeZone: this.TIMEZONE });
    }
    static printDateTime(date: Date) {
        return format(date, this.DATETIME_PATTERN, { timeZone: this.TIMEZONE });
    }

    // expecting UTC
    static printDateFromUTC(date: Date) {
        return format(toZonedTime(date, this.TIMEZONE), this.DATE_PATTERN, { timeZone: this.TIMEZONE });
    }

    // expecting UTC
    static printDateTimeFromUTC(date: Date) {
        return format(toZonedTime(date, this.TIMEZONE), this.DATETIME_PATTERN, { timeZone: this.TIMEZONE });
    }

    static readDate(date: string, dateFormat?: string) {
        try {

            if (!dateFormat) {
                dateFormat = this.DATE_PATTERN
            }

            const dateObject = parse(date, dateFormat, new Date());

            const dateToUTC = fromZonedTime(dateObject, this.TIMEZONE);

            if (dateToUTC instanceof Date && !isNaN(dateObject.valueOf())) {
                return dateToUTC;
            }
            else {
                throw new IllegalArgumentException("Invalid date format");
            }
        }
        catch (error) {
            throw new IllegalArgumentException("Invalid date format");
        }
    }

    static printDate(date: Date, dateFormat?: string) {
        try {

            if (!dateFormat) {
                dateFormat = this.DATE_PATTERN
            }

            return formatInTimeZone(date, this.TIMEZONE, dateFormat);
        }
        catch (error) {
            throw new IllegalArgumentException("Invalid date format");
        }
    }

    static printFromMillis(milliseconds: number, dateFormat: string) {
        return format(toZonedTime(new Date(milliseconds), this.TIMEZONE), dateFormat, { timeZone: this.TIMEZONE });
    }

    static readDateTime(date: string) {
        return parse(date, this.DATETIME_PATTERN, this.nowInIndia());
    }

    static print(date: Date) {
        return format(toZonedTime(date, this.TIMEZONE), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { timeZone: this.TIMEZONE });
    }

    static fromMillis(milliseconds: number) {
        const date = new Date(milliseconds);
        const timezonedDate = toDate(date, { timeZone: this.TIMEZONE })
        return timezonedDate;
    }

    static toMillis(date: Date) {
        return date.getTime();
    }

    static readISODate(date: string) {
        return parseISO(date);
    }
}