export abstract class SheetBaseObject {

    name!: string;

    metadata!: any;

    entity!: string;

    constructor() {
        this.setEntity('base_object')
    }

    getName() {
        return this.name;
    }

    setName(name: string) {
        this.name = name;
    }

    getMetadata(): any {
        return this.metadata;
    }

    setMetadata(metadata: any) {
        this.metadata = metadata;
    }

    getEntity() {
        return this.entity;
    }

    setEntity(entity: string) {
        this.entity = entity;
    }

}