import {
  compare,
  maxLength,
  minLength,
  notEmpty,
  pattern,
  prop,
} from '@rxweb/reactive-form-validators';
import { BaseObject } from './BaseObject';

export class BankAccount extends BaseObject {
  @prop()
  '@entity'?: string = 'org.quicko.bank_account';

  @prop()
  @notEmpty({
    conditionalExpression: (x: BankAccount) => !x.is_foreign_account,
    message: 'Bank Account Type cannot be empty',
  })
  account_type?: string;

  @prop()
  @notEmpty({
    conditionalExpression: (x: BankAccount) => !x.is_foreign_account,
    message: 'IFSC Code cannot be empty',
  })
  @pattern({
    expression: { ifsc_pattern: /^([A-Za-z]{4})([0]{1})([a-zA-Z0-9]{6})$/ },
    message: 'Enter a valid IFSC',
  })
  ifsc?: string;

  @prop()
  @notEmpty({ message: 'Account Number cannot be empty' })
  @pattern({
    conditionalExpression: (x: BankAccount) => !x.is_foreign_account,
    expression: { account_number_pattern: /^[0-9]*$/ },
    message: 'Invalid Account Number',
  })
  @minLength({
    conditionalExpression: (x: BankAccount) => !x.is_foreign_account,
    value: 9,
    message: 'Minimum length should be 9 digits',
  })
  @maxLength({
    conditionalExpression: (x: BankAccount) => !x.is_foreign_account,
    value: 20,
    message: 'Maximum length exceeded',
  })
  @minLength({
    conditionalExpression: (x: BankAccount) => x.is_foreign_account,
    value: 1,
    message: 'Account Number cannot be empty',
  })
  @maxLength({
    conditionalExpression: (x: BankAccount) => x.is_foreign_account,
    value: 40,
    message: 'Maximum length exceeded',
  })
  account_number?: string;

  @prop()
  @notEmpty({ message: 'Name of Bank cannot be empty' })
  @maxLength({ value: 125, message: 'Maximum length exceeded' })
  bank_name?: string;


  @prop()
  @notEmpty({ conditionalExpression: (x: BankAccount) => x.is_foreign_account, message: 'Swift Code cannot be empty' })
  @minLength({
    conditionalExpression: (x: BankAccount) => x.is_foreign_account,
    value: 1,
    message: 'Swift Code cannot be empty',
  })
  @maxLength({
    conditionalExpression: (x: BankAccount) => x.is_foreign_account,
    value: 30,
    message: 'Maximum length exceeded',
  })
  swift_code?: string;

  @notEmpty({ conditionalExpression: ((x: BankAccount) => x.is_foreign_account), message: 'Country cannot be empty' })
  country?: string;

  @prop()
  is_foreign_account?: boolean;

  @prop()
  is_refund_account?: boolean;

  @prop()
  @compare({
    fieldName: 'account_number',
    message: "Account Number doesn't match",
  })
  confirm_account_number?: string;
}




// @prop()
// @compare({
//   conditionalExpression: ((x: BankAccount) => !x.is_foreign_account),
//   fieldName: 'account_number',
//   message: "Account Number doesn't match",
// })
// confirm_account_number?: string;
