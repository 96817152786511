import { maxLength, notEmpty, pattern, prop, propObject, required } from "@rxweb/reactive-form-validators";
import { BaseObject } from "./org-quicko-core/beans/BaseObject";
import { Expose } from "class-transformer";
import 'reflect-metadata'
import { Address } from "./org-quicko-core/beans/Address";
import { MinLength, minLength } from "class-validator";

@Reflect.metadata("@entity", "com.quicko.myaccount.tax_details")
export class TaxDetails extends BaseObject {

    @Expose({ name: "@entity" })
    @prop()
    entity = 'com.quicko.myaccount.tax_details';

    @Expose({ name: 'legal_name' })
    @prop()
    @notEmpty({ message: 'Business name is required' })
    @maxLength({ value: 100, message: 'Maximum length exceeded' })
    legal_name?: string;
    @pattern({
        expression: {
            gstin_pattern:
                /^[ ]*[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[A-Za-z0-9]{1}[Zz]{1}[A-Za-z0-9]{1}[ ]*$/,
        },
        message: 'Enter a valid GSTIN',
    })
    @Expose({ name: 'gstin' })
    @prop()
    @notEmpty({ message: 'GSTIN is required' })
    gstin?: string;

    @propObject(Address, { autoCreate: true })
    address?: Address;

    @Expose({ name: 'place_of_supply' })
    @prop()
    @notEmpty({
        conditionalExpression: (x: TaxDetails) =>
            x.gstin != undefined,
        message: 'Place of supply is required',
    })
    place_of_supply?: string;
}



