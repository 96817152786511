import { BaseObject } from "../org-quicko-core/beans/BaseObject";
import { Expose } from "class-transformer";
import { InviterRole } from "../../types/invitation/InviterRole";
import "reflect-metadata";
import { email, maxLength, notEmpty, prop, required } from "@rxweb/reactive-form-validators";
import { Equals } from "class-validator";


@Reflect.metadata("@entity", "com.quicko.accountant.invitation")
export class Invitation extends BaseObject {
    @Expose({ name: "@entity" })
    @Equals('com.quicko.accountant.invitation')
    @prop()
    entity = "com.quicko.accountant.invitation";

    @Expose({ name: "inviter_id" })
    @prop()
    inviterId?: string;

    @Expose({ name: 'invitee_email' })
    @email({ message: 'Invalid email' })
    @notEmpty({ message: 'Email cannot be empty' })
    @prop()
    inviteeEmail?: string;

    @Expose({ name: "invitee_first_name" })
    @notEmpty({ message: 'First name cannot be empty' })
    @maxLength({ value: 125, message: 'Maximum length exceeded' })
    @prop()
    inviteeFirstName?: string;

    @Expose({ name: "invitee_last_name" })
    @notEmpty({ message: 'Last name cannot be empty' })
    @maxLength({ value: 125, message: 'Maximum length exceeded' })
    @prop()
    inviteeLastName?: string;

    @Expose({ name: "inviter_role" })
    @prop()
    inviterRole?: InviterRole;

    @Expose({ name: "access_validity" })
    @prop()
    accessValidity?: number;

    @Expose({ name: "invitation_expiry_at" })
    @prop()
    invitationExpiryAt?: number;
}
